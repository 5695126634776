import React, { createContext, useContext, useState } from "react";

// Create a context for the pipeline ID
const PipelineContext = createContext<
  | {
      pipelineId: number | undefined;
      setPipelineId: (id: number | undefined) => void;
    }
  | undefined
>(undefined);

// Context provider component
export const PipelineProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [pipelineId, setPipelineId] = useState<number | undefined>(0);

  return (
    <PipelineContext.Provider value={{ pipelineId, setPipelineId }}>
      {children}
    </PipelineContext.Provider>
  );
};

export const usePipelineId = () => {
  const context = useContext(PipelineContext);
  if (!context) {
    throw new Error("usePipelineId must be used within a PipelineProvider");
  }
  return context;
};
