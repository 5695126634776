import React, { lazy, Suspense, useMemo } from "react";

import { useGetIdentity, useList, useNavigation } from "@refinedev/core";

import { ProjectOutlined, RightCircleOutlined } from "@ant-design/icons";
import { PieConfig } from "@ant-design/plots";
import { Button, Card } from "antd";
import { Task, TaskStage, User } from "interfaces/graphql";

import { Text } from "@/components";

const Pie = lazy(() => import("@ant-design/plots/es/components/pie"));

export const DashboardTasksChart: React.FC<{}> = () => {
  const { list } = useNavigation();
  const { data } = useGetIdentity<User>();

  const {
    data: stagesData,
    isError: stagesError,
    error: stagesErrorObj,
  } = useList<TaskStage>({
    resource: "taskStages",
    pagination: { pageSize: 6 },
    meta: { fields: ["id", "title"] },
    filters: [
      {
        field: "createdBy",
        operator: "eq",
        value: data?.id,
      },
    ],
  });

  const {
    data: tasksData,
    isError: tasksError,
    error: tasksErrorObj,
  } = useList<{ id: number; stageId: number }>({
    resource: "tasks",
    pagination: { pageSize: 1000 },
    meta: { fields: ["id", "stageId"] },
    filters: [
      {
        field: "createdBy",
        operator: "eq",
        value: data?.id,
      },
    ],
  });

  if (stagesError || tasksError) {
    console.error(
      "Error fetching data",
      stagesError ? stagesErrorObj : tasksErrorObj
    );
    return null;
  }

  const chartData = useMemo(() => {
    if (!stagesData?.data?.length || !tasksData?.data?.length) {
      return [];
    }

    const taskCounts = stagesData.data.map((stage) => {
      const tasksForStage = tasksData.data.filter((task) => {
        return String(task.stageId) === String(stage.id);
      });

      return {
        title: stage.title,
        value: tasksForStage.length,
      };
    });

    return taskCounts.filter((stage) => stage.value > 0);
  }, [stagesData?.data, tasksData?.data]);

  const COLORS = [
    "#BAE0FF",
    "#69B1FF",
    "#1677FF",
    "#0958D9",
    "#10239E",
    "#061178",
    "#030852",
    "#03052E",
    "#000B0A",
    "#000000",
  ];

  const config: PieConfig = {
    width: 168,
    height: 168,
    data: chartData,
    angleField: "value",
    colorField: "title",
    color: COLORS,
    legend: false,
    radius: 1,
    innerRadius: 0.6,
    label: false,
    syncViewPadding: true,
    statistic: {
      title: false,
      content: false,
    },
  };

  const NoTasks: React.FC = () => (
    <span className="text-center flex items-center justify-center content-center">
      Nenhuma tarefa criada
    </span>
  );

  return (
    <Card
      style={{ height: "100%" }}
      headStyle={{ padding: "8px 16px" }}
      bodyStyle={{
        padding: "32px",
      }}
      title={
        <div
          style={
            chartData.length > 0
              ? {
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }
              : {}
          }
        >
          <ProjectOutlined />
          <Text size="sm" style={{ marginLeft: ".5rem" }}>
            Tasks
          </Text>
        </div>
      }
      extra={
        <Button onClick={() => list("tasks")} icon={<RightCircleOutlined />}>
          Ver todas as tarefas
        </Button>
      }
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Suspense>
          <Pie {...config} />
        </Suspense>
      </div>
      <div
        style={
          chartData.length > 0
            ? {
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                marginTop: "48px",
              }
            : {}
        }
      >
        {chartData.length > 0 ? (
          chartData?.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                width: "50%",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  width: 8,
                  height: 8,
                  backgroundColor: COLORS[index],
                  marginRight: ".5rem",
                }}
              />
              <Text
                size="md"
                style={{
                  textTransform: "capitalize",
                  whiteSpace: "nowrap",
                }}
              >
                {item.title.toLowerCase()}
              </Text>
            </div>
          ))
        ) : (
          <NoTasks />
        )}
      </div>
    </Card>
  );
};
