import { supabase } from "@/providers/supabase";
import { createContext, useState, useEffect, ReactNode } from "react";

interface AuthContextType {
  isFirstLogin: boolean;
  setIsFirstLogin: (value: boolean) => void;
  session: any;
}

export const AuthContext = createContext<AuthContextType>({
  isFirstLogin: false,
  setIsFirstLogin: () => {},
  session: null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    const sess = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data);
    };

    sess();
  }, []);

  const checkFirstLogin = async (user: any) => {
    if (!user) return;

    const createdAt = new Date(user.created_at).getTime();
    const lastSignInAt = new Date(user.last_sign_in_at).getTime();
    //5min
    const threshold = 5 * 60 * 1000;

    setIsFirstLogin(lastSignInAt - createdAt < threshold);
  };

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        const { data: user, error } = await supabase.auth.getUser();

        if (error) {
          console.error("Error fetching user:", error);
          return;
        }
        if (user) {
          checkFirstLogin(user);
        }
      } catch (err) {
        console.error("Error fetching user:", err);
      }
    };

    checkCurrentUser();

    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (session && session.user) {
          await checkFirstLogin(session.user);
        }
      }
    );

    return () => {
      listener.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ isFirstLogin, setIsFirstLogin, session }}>
      {children}
    </AuthContext.Provider>
  );
};
