import { DeleteButton, useForm } from "@refinedev/antd";
import {
  HttpError,
  useGetIdentity,
  useInvalidate,
  useParsed,
} from "@refinedev/core";

import { Button, Form, Input, Space, Typography } from "antd";
import dayjs from "dayjs";

import { CustomAvatar, Text } from "@/components";
import { DealComment, User } from "@/interfaces";
import { useComments } from "../hooks/useComments";

const CommentListItem = ({
  item,
  handleDataChange,
}: {
  item: DealComment;
  handleDataChange: () => void;
}) => {
  const invalidate = useInvalidate();
  const { formProps, setId, id, saveButtonProps } = useForm<
    DealComment,
    HttpError,
    DealComment
  >({
    resource: "dealComments",
    action: "edit",
    queryOptions: {
      enabled: false,
    },
    onMutationSuccess: () => {
      setId(undefined);
      invalidate({
        invalidates: ["all"],
        resource: "dealComments",
      });
    },
    successNotification: () => ({
      key: "deal-update-comment",
      message: "Comentário atualizado com sucesso",
      description: "Sucesso",
      type: "success",
    }),
  });
  const { data: me } = useGetIdentity<User>();

  const isMe = me?.id === (item.createdBy as any); //fix this futurely...

  return (
    <div style={{ display: "flex", gap: "12px" }}>
      <CustomAvatar
        style={{ flexShrink: 0 }}
        alt={item.createdBy?.name}
        src={item.createdBy?.avatarUrl}
        name={item.createdBy?.name}
      >
        {item.createdBy?.name?.charAt(0)}
      </CustomAvatar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ fontWeight: 500 }}>{item.createdBy?.name}</Text>
          <Text size="xs" style={{ color: "#000000a6" }}>
            {/* {dayjs(item.createdAt).format("MMMM D, YYYY - h:ma")} */}
            {dayjs(item.createdAt).subtract(3, "hours").fromNow()}
          </Text>
        </div>

        {id ? (
          <Form {...formProps} initialValues={{ comment: item.comment }}>
            <Form.Item
              name="comment"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                  message: "Deixe um comentário",
                },
              ]}
            >
              <Input.TextArea autoFocus style={{ backgroundColor: "#fff" }} />
            </Form.Item>
          </Form>
        ) : (
          <Typography.Paragraph
            style={{
              background: "#fff",
              borderRadius: "6px",
              padding: "8px",
              marginBottom: 0,
            }}
            ellipsis={{ rows: 3, expandable: true }}
          >
            {item.comment}
          </Typography.Paragraph>
        )}

        {isMe && !id && (
          <Space size={16}>
            <Typography.Link
              style={{ color: "inherit", fontSize: "12px" }}
              onClick={() => setId(item.id)}
            >
              Editar
            </Typography.Link>
            <DeleteButton
              recordItemId={item.id}
              resource="dealComments"
              size="small"
              type="link"
              icon={null}
              onSuccess={() => {
                invalidate({
                  invalidates: ["list"],
                  resource: "dealComments",
                });
                handleDataChange();
              }}
              successNotification={() => ({
                key: "task-delete-comment",
                message: "Comentário deletado cm sucesso",
                description: "Sucesso",
                type: "success",
              })}
              style={{
                padding: 0,
                fontSize: "12px",
                color: "inherit",
              }}
            />
          </Space>
        )}

        {id && (
          <Space>
            <Button size="small" onClick={() => setId(undefined)}>
              Cancelar
            </Button>
            <Button size="small" type="primary" {...saveButtonProps}>
              Salvar
            </Button>
          </Space>
        )}
      </div>
    </div>
  );
};

export const DealCommentList = () => {
  const { id: dealId } = useParsed();

  const { data, loading, error } = useComments(dealId as any);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {(error as any).message}</div>;
  }

  return (
    <div className="max-h-[190px] overflow-auto space-y-4 w-full">
      {data?.map((item: any) => (
        <CommentListItem
          key={item.id}
          handleDataChange={() => {}}
          item={item}
        />
      ))}
    </div>
  );
};
