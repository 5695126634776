import { Button } from "antd";

interface IntegrationCardButtonsProps {
  instructions: string;
  site: string
}

export function IntegrationCardButtons({ instructions, site }: IntegrationCardButtonsProps){
  return (
    <>
      <Button disabled>Integração em Desenvolvimento</Button>
      {/* <a
        href={instructions}
        target="_blank"
        rel="noreferrer"
        className="text-center w-[80%] block"
      >
        <Button className="w-full" type="default">Ver instruções</Button>
      </a>
      <a 
        href={site}
        target="_blank"
        rel="noreferrer"
        className="text-center w-[80%] block"
      >
        <Button className="w-full" type="text">Visitar site</Button>
      </a> */}
    </>
  )
}