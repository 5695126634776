import React, { Suspense, useMemo, useState } from "react";

import { useGetIdentity, useList, useNavigation } from "@refinedev/core";

import { DollarOutlined } from "@ant-design/icons";
import { GaugeConfig } from "@ant-design/plots";
import { Button, Card, Skeleton, Space } from "antd";
import { DealStage, User } from "interfaces/graphql";

import { Text } from "@/components";
import { currencyNumber } from "@/utilities";
import { AddGoalForm } from "./add-goal-form";
import { formatForDisplay } from "@/routes/scrumboard/sales/lib/format-display";

const Gauge = React.lazy(() => import("@ant-design/plots/es/components/gauge"));

export const DashboardTotalRevenueChart: React.FC<{}> = () => {
  const { data } = useGetIdentity<User>();
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const { data: expectedRevenueDeals, isLoading: expectedRevenueIsLoading } =
    useList({
      resource: "goal",
      filters: [
        {
          field: "enterprise",
          operator: "eq",
          value: data?.userData?.enterprise,
        },
      ],
      meta: {
        fields: ["value"],
      },
    });

  const { data: realizedRevenueDeals, isLoading: realizedRevenueIsLoading } =
    useList({
      resource: "deals",
      filters: [
        {
          field: "enterprise",
          operator: "eq",
          value: data?.userData?.enterprise,
        },
      ],
      meta: {
        fields: ["value"],
      },
    });

  const totalRealizationRevenue = useMemo(
    () =>
      realizedRevenueDeals?.data.reduce(
        (sum, deal) => (deal.stageId === 1 ? sum + (deal.value || 0) : sum),
        0
      ),
    [realizedRevenueDeals]
  );

  const totalExpectedRevenue = useMemo(
    () =>
      expectedRevenueDeals?.data.reduce(
        (sum, deal) => sum + (deal.value || 0),
        0
      ),
    [expectedRevenueDeals]
  );

  const chartPercentage = useMemo(
    () =>
      totalExpectedRevenue! > 0
        ? totalRealizationRevenue! / totalExpectedRevenue!
        : 0,
    [totalRealizationRevenue, totalExpectedRevenue]
  );

  const config: GaugeConfig = useMemo(() =>({
    animation:
      expectedRevenueIsLoading || realizedRevenueIsLoading ? false : true,
    supportCSSTransform: true,
    // antd expects a percentage value between 0 and 1
    percent: chartPercentage,
    range: {
      color: "l(0) 0:#D9F7BE 1:#52C41A",
    },
    axis: {
      tickLine: {
        style: {
          stroke: "#BFBFBF",
        },
      },
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    indicator: {
      pointer: {
        style: {
          fontSize: 4,
          stroke: "#BFBFBF",
          lineWidth: 2,
        },
      },
      pin: {
        style: {
          r: 8,
          lineWidth: 2,
          stroke: "#BFBFBF",
        },
      },
    },
    statistic: {
      content: {
        formatter: (datum) => {
          return (datum?.percent * 100).toFixed(2) + "%";
        },
        style: {
          color: "rgba(0,0,0,0.85)",
          fontWeight: 500,
          fontSize: "24px",
        },
      },
    },
  }), [chartPercentage, expectedRevenueIsLoading, realizedRevenueIsLoading]);

  return (
    <>
      {openModal && <AddGoalForm onClose={handleCloseModal} />}
      <Card
        style={{ height: "100%" }}
        bodyStyle={{
          padding: "0 32px 32px 32px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        headStyle={{ padding: "16px" }}
        title={
          <div className="flex justify-between">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <DollarOutlined />
              <Text size="sm">Valor Vendido</Text>
            </div>
            <Button className="bg-transparent" onClick={handleOpenModal}>
              Definir Meta
            </Button>
          </div>
        }
      >
        <Suspense>
          <Gauge
            key={JSON.stringify(config)}
            {...config}
            padding={0}
            width={280}
            height={280}
          />
        </Suspense>

        <div
          style={{
            display: "flex",
            gap: "32px",
          }}
        >
          <Space direction="vertical" size={0}>
            <Text size="xs" className="secondary">
              Meta
            </Text>
            {!expectedRevenueIsLoading || !realizedRevenueIsLoading ? (
              <Text
                size="md"
                className="primary"
                style={{
                  minWidth: "100px",
                }}
              >
                {formatForDisplay(totalExpectedRevenue || 0)}
              </Text>
            ) : (
              <Skeleton.Button
                style={{
                  width: "100px",
                  height: "16px",
                  marginTop: "6px",
                }}
                active={true}
              />
            )}
          </Space>
          <Space direction="vertical" size={0}>
            <Text size="xs" className="secondary">
              Atingido
            </Text>
            {!expectedRevenueIsLoading || !realizedRevenueIsLoading ? (
              <Text
                size="md"
                className="primary"
                style={{
                  minWidth: "100px",
                }}
              >
                {formatForDisplay(totalRealizationRevenue || 0)}
              </Text>
            ) : (
              <Skeleton.Button
                style={{
                  width: "100px",
                  height: "16px",
                  marginTop: "6px",
                }}
                active={true}
              />
            )}
          </Space>
        </div>
      </Card>
    </>
  );
};
