import { useSearchParams } from "react-router-dom";

import { useModalForm } from "@refinedev/antd";
import { useGetIdentity, useNavigation } from "@refinedev/core";

import { Form, Input, Modal } from "antd";
import { User } from "@/interfaces";

export const KanbanCreatePage = () => {
  const [searchParams] = useSearchParams();
  const { data } = useGetIdentity<User>();

  const { list } = useNavigation();
  const { formProps, modalProps, close } = useModalForm({
    action: "create",
    defaultVisible: true,
  });

  return (
    <Modal
      {...modalProps}
      onCancel={() => {
        close();
        list("tasks", "replace");
      }}
      title="Adicionar nova tarefa"
      width={512}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          formProps?.onFinish?.({
            ...values,
            createdBy: data?.id,
            enterprise: data?.userData?.enterprise,
            stageId: searchParams.get("stageId")
              ? Number(searchParams.get("stageId"))
              : null,
            userIds: [],
          });
        }}
      >
        <Form.Item
          label="Título da tarefa"
          name="title"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
