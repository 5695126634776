import React, { useState } from "react";

import { useModal, useSelect } from "@refinedev/antd";

import {
  Checkbox,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  FormInstance,
  FormProps,
  Input,
  Row,
  Select,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import { BaseOption, useGetIdentity } from "@refinedev/core";
import { User } from "@/interfaces";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { PlusOutlined } from "@ant-design/icons";
import { DefaultOptionType } from "antd/es/select";
import { CalendarManageCategories } from "../categories/manage-categories";

type CalendarFormProps = {
  isAllDayEvent: boolean;
  setIsAllDayEvent: (value: boolean) => void;
  formProps: FormProps;
  form: FormInstance;
};

const { RangePicker } = DatePicker;

export const CalendarForm: React.FC<CalendarFormProps> = ({
  form,
  formProps,
  isAllDayEvent = false,
  setIsAllDayEvent,
}) => {
  const { data } = useGetIdentity<User>();
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const { modalProps, show, close } = useModal();
  const { selectProps: categorySelectProps } = useSelect({
    resource: "eventCategories",
    meta: {
      fields: ["id", "title"],
    },
    filters: [
      {
        field: "createdBy",
        operator: "eq",
        value: data?.id,
      },
    ],
  });

  function handleChangeCategory(
    value: BaseOption,
    option: DefaultOptionType | DefaultOptionType[]
  ) {
    if (String(value) === "new_category") {
      show();
      return;
    }

    setSelectedCategory(option);
  }

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setIsAllDayEvent(checked);

    // Resetting the date fields based on checkbox state
    if (checked) {
      form.setFieldsValue({
        date: undefined,
        time: undefined,
        rangeDate: [dayjs(), dayjs()],
      });
    } else {
      form.setFieldsValue({
        rangeDate: undefined,
        date: dayjs(),
        time: [dayjs().startOf("hour"), dayjs().startOf("hour").add(1, "hour")],
      });
    }
  };

  const { selectProps: userSelectProps } = useSelect({
    optionLabel: "name",
    resource: "users",
    meta: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "createdBy",
        operator: "eq",
        value: data?.id,
      },
    ],
  });

  return (
    <Form layout="vertical" form={form} {...formProps}>
      <CalendarManageCategories {...modalProps} saveSuccces={() => close()} />
      <Form.Item
        label="Título"
        name="title"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Descrição"
        name="description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="Data e Hora"
        name={isAllDayEvent ? "rangeDate" : "date"} // This ensures the correct field is used
        rules={[
          {
            required: true,
            message: "Por favor, insira a data",
          },
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, width: 1000 }}>
            <Checkbox checked={isAllDayEvent} onChange={handleCheckboxChange}>
              O dia todo
            </Checkbox>
          </div>

          {isAllDayEvent ? (
            <Form.Item>
              <RangePicker
                style={{
                  width: 416,
                }}
                format={"DD/MM/YYYY"}
                // defaultValue={[dayjs(date), dayjs(date)]}
              />
            </Form.Item>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                  },
                ]}
                noStyle
              >
                <DatePicker
                  style={{
                    width: "160px",
                  }}
                  format={"DD/MM/YYYY"}
                  // defaultValue={dayjs(rangeDate ? rangeDate[0] : undefined)}
                />
              </Form.Item>
              <Form.Item
                name="time"
                rules={[
                  {
                    required: true,
                  },
                ]}
                noStyle
              >
                <TimePicker.RangePicker
                  style={{
                    width: 240,
                  }}
                  format={"HH:mm"}
                  placeholder={["Início", "Fim"]}
                  minuteStep={15}
                />
              </Form.Item>
            </div>
          )}
        </div>
      </Form.Item>
      <Row gutter={[300, 300]} className="flex justify-center">
        <Col span={14}>
          <Form.Item
            label="Categoria"
            name="categoryId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              {...categorySelectProps}
              options={[
                ...(categorySelectProps.options ?? []),
                {
                  label: (
                    <span className="text-sm text-blue-500">
                      <PlusOutlined
                        className="mr-1 h-3 w-3"
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      />
                      Adicionar Nova
                    </span>
                  ),
                  value: "new_category",
                },
              ]}
              onChange={handleChangeCategory}
              value={selectedCategory}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            label="Cor"
            name="color"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={"#1677FF"}
          >
            <ColorPicker
              defaultValue="#1677FF"
              panelRender={(_, { components: { Presets } }) => <Presets />}
              presets={[
                {
                  label: "Recommended",
                  colors: [
                    "#F5222D",
                    "#FA8C16",
                    "#8BBB11",
                    "#52C41A",
                    "#13A8A8",
                    "#1677FF",
                    "#2F54EB",
                    "#722ED1",
                    "#EB2F96",
                  ],
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <Form.Item
        label="Convidar participantes"
        name="participantIds"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select mode="multiple" allowClear {...userSelectProps} />
      </Form.Item> */}
    </Form>
  );
};
