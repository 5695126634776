import React, { useEffect, useState } from "react";
import { Button, Input, notification } from "antd";
import { CopyOutlined, KeyOutlined } from "@ant-design/icons";
import { supabase } from "@/providers/supabase";

export function WebhookIntegrationDetails() {
  const [apiKey, setApiKey] = useState<string | undefined>();

  useEffect(() => {
    const fetchSession = async () => {
      const session = await supabase.auth.getSession();
      if (session) {
        const storedApiKey = localStorage.getItem("apiKey");
        if (storedApiKey) {
          setApiKey(storedApiKey);
        }
      }
    };

    fetchSession();
  }, []);

  const generateApiKey = async () => {
    const session = await supabase.auth.getSession();

    if (!session.data.session) {
      notification.error({
        message:
          "Nenhum token de autenticação encontrado. Por favor, faça login novamente.",
      });
      return;
    }

    const email = session.data.session.user.email;
    if (!email) {
      notification.error({
        message: "Email não encontrado na sessão. Por favor, tente novamente.",
      });
      return;
    }

    try {
      const response = await fetch("https://trial.fexei.com.br/api/gen", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.data.session.access_token}`,
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) throw new Error("Response not OK");

      const data = await response.json();
      const newApiKey = data.apiKey;

      localStorage.setItem("apiKey", newApiKey);
      setApiKey(newApiKey);

      notification.success({
        message: "Chave de acesso gerada com sucesso!",
        description: `Sua nova chave de acesso é: ${newApiKey.substring(
          0,
          15
        )}...`,
      });
    } catch (error) {
      console.error("Error generating API key:", error);
      notification.error({
        message: "Erro ao gerar a chave de API",
        description: (error as any).message as string,
      });
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      notification.success({
        message: "Chave de acesso copiada para a área de transferência!",
      });
    });
  };

  return (
    <div>
      {!apiKey && (
        <Button
          icon={
            <KeyOutlined
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
          }
          onClick={generateApiKey}
        >
          Gerar Chave API
        </Button>
      )}
      {apiKey && (
        <div className="flex items-center justify-center space-x-12 p-4">
          <p className="flex space-x-4 items-center justify-center ">
            <KeyOutlined
              className="text-xl"
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
            <p className="text-center text-md font-bold">Chave API:</p>
          </p>
          <Input
            readOnly
            className="text-center"
            value={`${apiKey.substring(0, 40)}...`}
          />
          <Button
            className="flex items-center justify-center !w-32"
            icon={
              <CopyOutlined
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            }
            onClick={() => copyToClipboard(apiKey)}
          >
            Copiar
          </Button>
        </div>
      )}
    </div>
  );
}
