export function PlugaDescription(){
  return (
    <div className="space-y-4">
      <p>
        A Pluga permite que você crie integrações com o seu Fexei CRM
        sem precisar desenvolver uma única linha de código.
      </p>
      <p>
        Para isso, basta acessar a página de integrações do Fexei na Pluga,
        escolher qual processo deseja automatizar e começar já.
      </p>
      <p>
        Importante! A Pluga oferece um trial de 7 dias gratuitos,
        e ainda possui um plano Free. Em caso de dúvidas, envie um e-mail para
        suporte@pluga.com. A equipe da Pluga estará à disposição para ajudar.
      </p>
      <p>
        Através da Pluga é possível criar integrações em que o Fexei é
        tanto a ferramenta de origem, isto é, a ferramenta que irá enviar as
        informações para as outras ferramentas que você usa; quanto a
        ferramenta de destino, ou seja, realizar ações no Fexei a partir dos
        dados recebidos de outras ferramentas.
      </p>
      <p>
        Quando o Fexei é a ferramenta de origem, é possível enviar dados para
        outras ferramentas sempre que:
      </p>

      <ul className="ml-4">
        <li className="list-disc">Um negócio é movido para uma determinada etapa;</li>
        <li className="list-disc">Um negócio é marcado como ganho;</li>
        <li className="list-disc">Um negócio é marcado como perdido;</li>
        <li className="list-disc">Um contato é criado;</li>
      </ul>

      <p>
        Agora, quando o Fexei é a ferramenta de destino, é possível
        criar e/ou atualizar um negócio ou um contato sempre que algo acontecer
        na sua outra ferramenta.
      </p>

      <p>
        Veja, a seguir, alguns exemplos de automatizações que você pode
        criar através da Pluga.
      </p>

      <ul className="ml-4">
        <li className="list-disc">
          A cada resposta em um anúncio do Facebook Lead Ads, criar ou atualizar um negócio no Fexei CRM
        </li>
        <li className="list-disc">
          A cada negócio criado no Fexei CRM, avisar em um canal do Slack
        </li>
        <li className="list-disc">
          A cada negócio ganho no Fexei CRM, adicionar os dados em uma nova linha de uma planilha do Google Sheets
        </li>
        <li className="list-disc">
          A cada negócio ganho no Fexei CRM, criar uma venda na Conta Azul
        </li>
        <li className="list-disc">
          Quando o lead atender o(s) filtro(s) [ex: tags e leadscore] de um gatilho inteligente do leadlovers, criar ou atualizar um negócio no Fexei CRM
        </li>
      </ul>
    </div>
  )
}