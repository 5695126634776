import { User } from "@/interfaces";
import { useGetIdentity, useList } from "@refinedev/core";
import dayjs from "dayjs";

export function useSubscription() {
  const { data: identityData } = useGetIdentity<User>();
  const { data: subscriptionData } = useList({
    resource: "subscriptions",
    meta: {
      fields: ["id", "plan"],
    },
    filters: [
      {
        field: "ownerId",
        operator: "eq",
        value: identityData?.id,
      },
    ],
  });

  const subscriptionWithRemainingDays = subscriptionData?.data.map(
    (subscription) => {
      let trialDays = 0;
      if (subscription.plan === "Elite Trial 30 dias") {
        trialDays = 30;
      } else if (subscription.plan === "Elite Trial 7 dias") {
        trialDays = 7;
      }

      const purchaseDate = dayjs(subscription.purchaseDate);
      const endDate = purchaseDate.add(trialDays, "day").endOf("day");
      const today = dayjs().startOf("day");
      const remainingDays = endDate.diff(today, "day");

      return {
        ...subscription,
        remainingDays: remainingDays >= 0 ? remainingDays : 0,
      };
    }
  );

  const remainingDays = subscriptionWithRemainingDays?.map(
    (subscription) => subscription.remainingDays
  );

  const filteredSubscriptions = subscriptionData?.data.filter(
    (subscription) =>
      subscription.plan === "Profissional" ||
      subscription.plan === "Elite" ||
      subscription.plan === "Elite Anual" ||
      subscription.plan === "Profissional Anual" ||
      subscription.plan === "Elite Trial 30 dias" ||
      subscription.plan === "Elite Trial 7 dias" ||
      subscription.plan === "plano de teste"
  );

  const isProfessionalOrElite = filteredSubscriptions?.length! > 0;

  const isActive = subscriptionData?.data.some(
    (subscription) => subscription.status.toLowerCase() === "active"
  ) as boolean;

  const isTrial = subscriptionData?.data.some(
    (subscription) =>
      subscription.plan === "Elite Trial 30 dias" ||
      subscription.plan === "Elite Trial 7 dias"
  );

  const usersLimitToAdd = () => {
    if (
      subscriptionData?.data.some((subscription) =>
        subscription.plan.includes("Profissional")
      )
    ) {
      return 5;
    } else if (
      subscriptionData?.data.some((subscription) =>
        subscription.plan.includes("Elite")
      )
    ) {
      return 10;
    } else {
      return 3;
    }
  };

  const usersLimit = usersLimitToAdd();

  const planName = subscriptionData?.data.map(
    (subscription) => subscription.plan
  );

  return {
    isActive,
    subscriptionData,
    remainingDays,
    isProfessionalOrElite,
    isTrial,
    usersLimit,
    planName,
  };
}
