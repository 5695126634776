import { User } from "@/interfaces";
import { supabase } from "@/providers/supabase";
import { useModalForm } from "@refinedev/antd";
import { useGetIdentity, useList } from "@refinedev/core";
import { Form, Input, InputNumber, Modal } from "antd";

type PipelineType = {
  title: string;
};

export const AddPipelineForm = () => {
  const { data } = useGetIdentity<User>();

  const { formProps, modalProps, close } = useModalForm({
    action: "create",
    defaultVisible: true,
    resource: "pipelines",
    warnWhenUnsavedChanges: false,
    autoResetForm: true,
  });

  const handleFinish = async (values: PipelineType) => {
    const payload = {
      ...values,
      createdAt: new Date(),
      updatedAt: new Date(),
      enterprise: data?.userData?.enterprise,
    };

    await supabase.from("pipelines").insert([payload]);
    close();
    return;
  };

  return (
    <Modal
      {...modalProps}
      title="Digite o nome da pipeline"
      width={512}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => handleFinish(values as any)}
      >
        <Form.Item
          label="Digite o nome da pipeline"
          name="title"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
