import React, { PropsWithChildren, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useForm, useSelect } from "@refinedev/antd";
import { useGetIdentity, useNavigation } from "@refinedev/core";

import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select } from "antd";

import { SelectOptionWithAvatar } from "@/components";
import { Company, User } from "@/interfaces";

export const ContactCreatePage: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { list, replace } = useNavigation();
  const { pathname } = useLocation();
  const { data: user } = useGetIdentity<User>();
  const [searchParams] = useSearchParams();
  const { formProps, saveButtonProps, onFinish } = useForm({
    redirect: "list",
  });
  const { selectProps, queryResult } = useSelect<Company>({
    resource: "companies",
    optionLabel: "name",
    meta: {
      fields: ["id", "name", "avatarUrl"],
    },
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: user?.userData?.enterprise,
      },
    ],
  });

  useEffect(() => {
    const companyId = searchParams.get("companyId");

    if (companyId && companyId !== "null" && queryResult?.data?.data) {
      const company = queryResult.data.data.find(
        (c) => String(c.id) === companyId
      );

      if (company) {
        formProps.form?.setFieldsValue({
          companyId: company.id,
        });
      }
    }
  }, [searchParams, queryResult?.data?.data]);

  const isHaveOverModal = pathname === "/contacts/create/company-create";

  return (
    <>
      <Modal
        open
        title="Criar Contato"
        style={{ display: isHaveOverModal ? "none" : "inherit" }}
        onCancel={() => {
          list("contacts", "replace");
        }}
        okText="Salvar"
        cancelText="Cancelar"
        okButtonProps={{
          ...saveButtonProps,
        }}
        width={560}
      >
        <Form
          layout="vertical"
          {...formProps}
          onFinish={(values) => {
            onFinish({
              ...values,
              salesOwnerId: user?.id,
              createdBy: user?.id,
              enterprise: (user as any)?.userData?.enterprise,
            });
          }}
        >
          <Form.Item
            label="Nome do Contato"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: "E-mail inválido!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="phone"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Cargo"
            name="jobTitle"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Empresa"
            name="companyId"
            rules={[
              {
                required: true,
              },
            ]}
            help={
              <Button
                style={{ paddingLeft: 0 }}
                type="link"
                icon={
                  <PlusCircleOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                onClick={() => {
                  replace("company-create?to=/contacts/create");
                }}
              >
                Adicionar uma empresa
              </Button>
            }
          >
            <Select
              {...selectProps}
              options={
                queryResult.data?.data?.map(({ id, name, avatarUrl }) => ({
                  value: id,
                  label: (
                    <SelectOptionWithAvatar
                      name={name}
                      avatarUrl={avatarUrl ?? undefined}
                    />
                  ),
                })) ?? []
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      {children}
    </>
  );
};
