import { Checkbox, Input } from "antd";

import { CheckListItem } from "@/interfaces";
import { supabase } from "@/providers/supabase";

type Props = {
  value?: CheckListItem;
  onChange?: (value: CheckListItem) => void;
  taskId?: number;
};

export const CheckListInput = ({
  value = {
    title: "",
    checked: false,
  },
  onChange,
  taskId,
}: Props) => {
  const triggerChange = (changedValue: {
    title?: string;
    checked?: boolean;
  }) => {
    onChange?.({ ...value, ...changedValue });
  };

  const onTitleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;

    triggerChange({ title: newTitle });

    if (!(value as any).id && taskId && newTitle.trim() !== "") {
      // If no id, it's a new checklist item. Insert it to the database.
      try {
        const { data, error } = await supabase
          .from("checkListItems")
          .insert([{ title: newTitle, checked: false, taskid: taskId }]);

        if (error) {
          throw error;
        }

        // Call onChange with the new item data including the id returned from the database
        //@ts-ignore
        onChange?.({ ...value, ...(data[0] ?? {}) });
      } catch (error) {
        console.error("Error inserting checklist item:", error);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
      }}
    >
      <Checkbox
        checked={value?.checked}
        onChange={(e) => {
          const newChecked = e.target.checked;

          triggerChange({ checked: newChecked });
        }}
      />
      <Input
        bordered={false}
        value={value?.title}
        onChange={onTitleChange}
        placeholder="Digite o título do item"
        style={{
          backgroundColor: "#fff",
          textDecoration: value.checked ? "line-through" : "none",
        }}
      />
    </div>
  );
};
