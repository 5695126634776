import {
  DeleteButton,
  FilterDropdown,
  getDefaultSortOrder,
  ShowButton,
  useSelect,
} from "@refinedev/antd";
import { CrudFilters, CrudSorting, getDefaultFilter } from "@refinedev/core";

import { PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Button, Input, notification, Select, Space, Table, type TableProps } from "antd";

import {
  ContactStatusTag,
  CustomAvatar,
  PaginationTotal,
  Text,
} from "@/components";
import { ContactStatusEnum } from "@/enums";
import { Contact } from "@/interfaces";

type Props = {
  tableProps: TableProps<Contact>;
  filters: CrudFilters;
  sorters: CrudSorting;
};

const statusOptions = Object.keys(ContactStatusEnum).map((key) => ({
  label: `${key[0]}${key.slice(1).toLowerCase()}`,
  value: ContactStatusEnum[key as keyof typeof ContactStatusEnum],
}));

export const TableView: React.FC<Props> = ({
  tableProps,
  filters,
  sorters,
}) => {
  const { selectProps } = useSelect({
    resource: "companies",
    optionLabel: "name",
    meta: {
      fields: ["id", "name"],
    },
  });

  function handleSendWhatsappMessage(number?: string | null){
    if(!number) {
      return notification.open({
        type: "error",
        message: "Erro ao abrir Whatsapp",
        description:
          "Contato sem número de telefone fornecido.",
      });
    }

    const formatedPhone = number?.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '');
    window.open(`https://wa.me/55${formatedPhone}`, '_blank')
  }

  function handleCallToNumber(number?: string | null) {
    if(!number) {
      return notification.open({
        type: "error",
        message: "Erro ao fazer ligação",
        description:
          "Contato sem número de telefone fornecido.",
      });
    }
    window.open(`tel:${number}`)
  }

  return (
    <Table
      {...tableProps}
      pagination={{
        ...tableProps.pagination,
        pageSizeOptions: ["12", "24", "48", "96"],
        showTotal: (total) => (
          <PaginationTotal total={total} entityName="contacts" />
        ),
      }}
      rowKey="id"
    >
      <Table.Column
        dataIndex="name"
        title="Nome"
        width={200}
        defaultFilteredValue={getDefaultFilter("name", filters)}
        defaultSortOrder={getDefaultSortOrder("name", sorters)}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder="Pesquisar nome" />
          </FilterDropdown>
        )}
        render={(_, record: Contact) => {
          return (
            <Space>
              <CustomAvatar src={record.avatarUrl} name={record.name} />
              <Text>{record.name}</Text>
            </Space>
          );
        }}
      />
      <Table.Column
        dataIndex="email"
        title="E-mail"
        defaultFilteredValue={getDefaultFilter("email", filters)}
        defaultSortOrder={getDefaultSortOrder("email", sorters)}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder="Pesquisar e-mail" />
          </FilterDropdown>
        )}
      />
      <Table.Column
        dataIndex={["company", "id"]}
        title="Empresa"
        defaultFilteredValue={getDefaultFilter("company.id", filters)}
        defaultSortOrder={getDefaultSortOrder("company.id", sorters)}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              placeholder="Pesquisar empresa"
              style={{ width: 220 }}
              {...selectProps}
            />
          </FilterDropdown>
        )}
        render={(_, record: Contact) => {
          return <span>{record?.companyName}</span>;
        }}
      />
      <Table.Column
        dataIndex="jobTitle"
        title="Cargo"
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Input placeholder="Pesquisar cargo" />
          </FilterDropdown>
        )}
      />
      {/* <Table.Column
        dataIndex="status"
        title="Status"
        sorter
        defaultFilteredValue={getDefaultFilter("status", filters)}
        defaultSortOrder={getDefaultSortOrder("status", sorters)}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              style={{ width: "200px" }}
              defaultValue={null}
              mode="multiple"
              options={statusOptions}
            ></Select>
          </FilterDropdown>
        )}
        render={(value: ContactStatusEnum) => (
          <ContactStatusTag status={value} />
        )}
      /> */}
      <Table.Column<Contact>
        fixed="right"
        title="Ações"
        dataIndex="actions"
        render={(_, record) => (
          <Space>
            <ShowButton hideText size="small" recordItemId={record.id} />
            <Button
              size="small"
              icon={<WhatsAppOutlined />}
              onClick={() => handleSendWhatsappMessage(record?.phone)}
            />
            <Button
              size="small"
              onClick={() => handleCallToNumber(record?.phone)}
              icon={<PhoneOutlined />}
            />
            <DeleteButton hideText size="small" recordItemId={record.id} />
          </Space>
        )}
      />
    </Table>
  );
};
