import Cookies, { CookieAttributes } from "js-cookie";

// Explicit type definitions for the function parameters
type CookieName = string;
type CookieValue = string;
type CookieExpiryDays = number | undefined; // Allow 'days' to be optional

export function setCookie(
  name: CookieName,
  value: CookieValue,
  days: CookieExpiryDays
) {
  const options: CookieAttributes = {
    expires: days,
    path: "/",
    sameSite: "lax" as "lax", // Ensuring the 'sameSite' matches the expected literal type
    secure: process.env.NODE_ENV === "production",
  };
  Cookies.set(name, value, options);
}

export function getCookie(name: CookieName): string | undefined {
  return Cookies.get(name); // Returns a string or undefined if the cookie does not exist
}

export function removeCookie(name: CookieName): void {
  Cookies.remove(name, { path: "/" });
}
