import React from "react";

import { Button, Layout, Space, theme } from "antd";

import { AlgoliaSearch } from "./algolia-search";
import { CurrentUser } from "./current-user";
import { Notifications } from "./notifications";
import { useSubscription } from "@/hooks/useSubscription";
import { useNavigation } from "@refinedev/core";

const { useToken } = theme;

export const Header: React.FC = () => {
  const { token } = useToken();

  const { push } = useNavigation();

  const { isTrial, remainingDays, isActive } = useSubscription();

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
    position: "sticky",
    top: 0,
    zIndex: 999,
  };

  return (
    <Layout.Header style={headerStyles}>
      <h1></h1>
      {/* <AlgoliaSearch /> */}
      <Space align="center" size="middle">
        {isTrial && isActive && (
          <div className="flex gap-4 items-center">
            <Button onClick={() => push("/planos")}>Contrate agora</Button>
            <p>Dias restantes: {remainingDays}</p>
          </div>
        )}
        {/* <Notifications /> */}
        <CurrentUser />
      </Space>
    </Layout.Header>
  );
};
