import { useForm } from "@refinedev/antd";
import { BaseKey, HttpError, useGetIdentity, useParsed } from "@refinedev/core";

import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";

import { CustomAvatar } from "@/components";
import { ContactNote, User } from "@/interfaces";
import { findFullName } from "@/utilities/find-full-name";

type FormValues = ContactNote & {
  contactId: BaseKey;
};

export const ContactCommentForm = () => {
  const { id: contactId } = useParsed();

  const { data: me } = useGetIdentity<User>();

  const { formProps, onFinish, form, formLoading } = useForm<
    ContactNote,
    HttpError,
    FormValues
  >({
    action: "create",
    resource: "contactNotes",
    queryOptions: {
      enabled: false,
    },
    meta: {
      operation: "contactNotes",
    },
    redirect: false,
    mutationMode: "optimistic",
    successNotification: () => ({
      key: "contact-note",
      message: "Nota adicionada com sucesso",
      description: "Sucesso",
      type: "success",
    }),
  });

  const userName: string = findFullName(me?.identities!);

  const handleOnFinish = async (values: ContactNote) => {
    if (!contactId) {
      return;
    }

    const note = values.note.trim();
    if (!note) {
      return;
    }

    try {
      await onFinish({
        ...values,
        contact: contactId,
        createdBy: me?.id,
      } as any);

      form.resetFields();
    } catch (error) {}
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "1rem",
        borderBottom: "1px solid #F0F0F0",
      }}
    >
      <CustomAvatar
        style={{ flexShrink: 0 }}
        name={userName}
        src={me?.avatarUrl}
      />
      <Form {...formProps} style={{ width: "100%" }} onFinish={handleOnFinish}>
        <Form.Item
          name="note"
          noStyle
        //   rules={[
        //     {
        //       required: true,
        //       pattern: new RegExp(
        //         /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
        //       ),
        //       message: "Digite uma nota",
        //     },
        //   ]}
        >
          <Input
            placeholder="Adicione uma nota"
            style={{ backgroundColor: "#fff" }}
            addonAfter={formLoading && <LoadingOutlined />}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
