import { IntegrationsCard } from "./components/IntegrationCard";
import { INTEGRATIONS } from "./constants";

export function Integrations() {
  return (
    <div className="grid grid-cols-3 gap-4 place-items-center place-content-center">
      {INTEGRATIONS.map((integration) => (
        <a href={integration.url} key={integration.name}>
          <IntegrationsCard
            description={integration.description}
            name={integration.name}
            image={integration.image}
            isSoon={integration.isSoon}
          />
        </a>
      ))}
    </div>
  );
}
