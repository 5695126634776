import React, { useState } from "react";

import { useForm } from "@refinedev/antd";
import { HttpError, useGetIdentity, useNavigation } from "@refinedev/core";

import { Modal, notification } from "antd";
import dayjs from "dayjs";

import { Event, EventCreateInput, User } from "@/interfaces";

import { CalendarForm } from "./components";
import { supabase } from "@/providers/supabase";
import {
  useSession,
  useSessionContext,
  useSupabaseClient,
} from "@supabase/auth-helpers-react";
import { Info } from "lucide-react";

type FormValues = EventCreateInput & {
  rangeDate: [dayjs.Dayjs, dayjs.Dayjs];
  date: dayjs.Dayjs;
  time: [dayjs.Dayjs, dayjs.Dayjs];
  color: any;
  participantIds?: string[];
  recurrence?: any;
  location?: string;
};

export const CalendarCreatePage: React.FC = () => {
  const [isAllDayEvent, setIsAllDayEvent] = useState(false);

  const { list } = useNavigation();
  const { data } = useGetIdentity<User>();

  const { formProps, saveButtonProps, form, onFinish } = useForm<
    Event,
    HttpError,
    EventCreateInput
  >();

  const handleAllDayEventToggle = (isAllDay: boolean) => {
    setIsAllDayEvent(isAllDay);
    if (isAllDay) {
      const today = dayjs();
      form.setFieldsValue({
        rangeDate: [today.startOf("day"), today.endOf("day")],
      } as any);
    }
  };

  const handleOnFinish = async (values: FormValues) => {
    const { rangeDate, date, time, color, participantIds, ...otherValues } =
      values;

    const startDate = rangeDate
      ? rangeDate[0].startOf("day")
      : date
          .set("hour", time[0].hour())
          .set("minute", time[0].minute())
          .set("second", 0);
    const endDate = rangeDate
      ? rangeDate[1].endOf("day")
      : date
          .set("hour", time[1].hour())
          .set("minute", time[1].minute())
          .set("second", 0);

    const eventData = {
      summary: otherValues.title,
      description: otherValues.description,
      location: otherValues.location || "",
      start: {
        dateTime: startDate.toISOString(),
        timeZone: "America/Sao_Paulo",
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: "America/Sao_Paulo",
      },
      recurrence: otherValues.recurrence || [],
      //attendees: participantIds.map((id: string) => ({ email: id })) || [],
      reminders: {
        useDefault: true,
        overrides: [{ method: "email", minutes: 24 * 60 }],
      },
    };

    Modal.confirm({
      icon: null,

      title: (
        <div className="flex items-center">
          <Info className="text-blue-500 mx-2" size={50} />
          <h1 className="text-xl font-semibold text-gray-800 p-4">
            Deseja também salvar o evento em seu Google Calendar?
          </h1>
        </div>
      ),

      content: (
        <p className="ml-4 text-lg text-gray-600 p-2">
          Clique em "OK" para salvar o evento no seu Google Calendar.
        </p>
      ),

      type: "confirm",

      footer: (originNode, extra) => (
        <div className="flex justify-end mr-4 pb-4 space-x-4">{originNode}</div>
      ),

      onOk: async () => {
        const auth = await fetch(
          "https://trial.fexei.com.br/api/calendar/auth",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const response = await auth.json();

        if (!response) return;

        const oauthWindow = window.open(response.url, "_blank");

        const handleMessage = async (event: MessageEvent) => {
          if (event.origin !== "https://trial.fexei.com.br") return;

          const { tokens } = event.data;
          if (!tokens) return;

          localStorage.setItem("googleTokens", JSON.stringify(tokens));
          oauthWindow?.close();

          const createEvent = await fetch(
            "https://trial.fexei.com.br/api/calendar/create",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(eventData),
            }
          );

          const createEventResponse = await createEvent.json();

          if (!createEventResponse) return;

          notification.success({
            message: "Evento salvo no Google Calendar com sucesso!",
            description: "O evento foi salvo no seu calendário do Google.",
          });

          window.removeEventListener("message", handleMessage);
        };

        window.addEventListener("message", handleMessage);
      },
      onCancel: () => {
        notification.info({
          message: "Você optou por não salvar no Google Calendar.",
          description: "O evento será salvo apenas no calendário do Fexei.",
        });
      },
    });

    await onFinish({
      ...otherValues,
      participantIds,
      enterprise: data?.userData?.enterprise,
      startDate: startDate.toISOString(),
      createdBy: data?.id,
      updatedBy: data?.id,
      endDate: endDate.toISOString(),
      color: typeof color === "object" ? `#${color.toHex()}` : color,
    });
  };

  return (
    <Modal
      title="Criar Evento"
      open
      onCancel={() => {
        list("events");
      }}
      okButtonProps={{
        ...saveButtonProps,
      }}
      okText="Salvar"
      width={560}
    >
      <CalendarForm
        isAllDayEvent={isAllDayEvent}
        setIsAllDayEvent={handleAllDayEventToggle}
        form={form}
        formProps={{
          ...formProps,
          onFinish: handleOnFinish,
        }}
      />
    </Modal>
  );
};
