import React, { memo, useEffect, useCallback, useMemo, useRef } from "react";
import { Col, Row } from "antd";
import { CalendarUpcomingEvents } from "@/components";
import {
  DashboardDealsChart,
  DashboardTasksChart,
  DashboardTotalCountCard,
  DashboardTotalRevenueChart,
} from "./components";
import { createSwapy } from "swapy";

function useSwapyInit() {
  const swapyRef = useRef<any>(null);

  useEffect(() => {
    const container = document.querySelector(".swapy-container");
    if (container && !swapyRef.current) {
      try {
        swapyRef.current = createSwapy(container, {
          animationDuration: 300,
          smoothAnimation: true,
          adaptiveHeight: true,
        });
        swapyRef.current.onSwap(({ data }) => {
          if (data && data.object) {
            localStorage.setItem("dashboardLayout", JSON.stringify(data.object));
          }
        });
      } catch (error) {
        console.error("Swapy initialization error:", error);
      }
    }

    return () => {
      if (swapyRef.current) {
        swapyRef.current.destroy();
        swapyRef.current = null;
      }
    };
  }, []);

  return swapyRef.current;
}

export const DashboardPage: React.FC = memo(() => {
  const swapy = useSwapyInit();

  const dashboardLayout = useMemo(() => {
    return localStorage.getItem("dashboardLayout")
      ? JSON.parse(localStorage.getItem("dashboardLayout")!)
      : {
          "1": "totalCountCard1",
          "2": "totalCountCard2",
          "3": "totalCountCard3",
          "4": "revenueChart",
          "5": "dealsChart",
          "6": "tasksChart",
          "7": "upcomingEvents",
        };
  }, []);

  const getComponentById = useCallback((id: string | null) => {
    switch (id) {
      case "totalCountCard1":
        return <DashboardTotalCountCard resource="companies" />;
      case "totalCountCard2":
        return <DashboardTotalCountCard resource="conversionRate" />;
      case "totalCountCard3":
        return <DashboardTotalCountCard resource="churnRate" />;
      case "revenueChart":
        return <DashboardTotalRevenueChart />;
      case "dealsChart":
        return <DashboardDealsChart />;
      case "tasksChart":
        return <DashboardTasksChart />;
      case "upcomingEvents":
        return <CalendarUpcomingEvents showGoToListButton />;
      default:
        return null;
    }
  }, []);

  const MemoizedCard = memo(({ id, layout }: { id: string; layout: Record<string, string | null> }) => {
    const component = useMemo(() => getComponentById(layout[id]), [id, layout]);
    return (
      <div data-swapy-item={id}>
        {component}
      </div>
    );
  });

  return (
    <div className="page-container">
      <div className="swapy-container">
        <Row gutter={[32, 32]}>
          {["1", "2", "3"].map((id) => (
            <Col key={id} xs={24} sm={24} xl={8} data-swapy-slot={id}>
              <MemoizedCard id={id} layout={dashboardLayout} />
            </Col>
          ))}
        </Row>

        <Row gutter={[32, 32]} style={{ marginTop: "32px" }}>
          <Col xs={24} sm={24} xl={8} data-swapy-slot="4" style={{ height: "432px" }}>
            <MemoizedCard id="4" layout={dashboardLayout} />
          </Col>
          <Col xs={24} sm={24} xl={16} data-swapy-slot="5" style={{ height: "432px" }}>
            <MemoizedCard id="5" layout={dashboardLayout} />
          </Col>
        </Row>

        <Row gutter={[32, 32]} style={{ marginTop: "32px" }}>
          <Col xs={24} sm={24} xl={8} data-swapy-slot="6" style={{ height: "458px" }}>
            <MemoizedCard id="6" layout={dashboardLayout} />
          </Col>
          <Col xs={24} sm={24} xl={16} data-swapy-slot="7">
            <MemoizedCard id="7" layout={dashboardLayout} />
          </Col>
        </Row>
      </div>
    </div>
  );
});
