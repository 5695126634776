import { ArrowDownOutlined, } from "@ant-design/icons";
import { Modal, Select, Input, Switch, DatePicker } from "antd";
import { ACTIONS, TRIGGERS } from "../../constants";
import { DealStage, TaskStage, User } from "@/interfaces";
import { useAutomationModal } from "./hooks/useAutomationModal";
import { filterOption } from "./utils";
import dayjs from "dayjs";

const { TextArea } = Input;

interface AutomationModalProps {
  isOpen: boolean
  onClose: () => void
  taskStages?: TaskStage[]
  allStages?: DealStage[]
  users: User[]
}

export function AutomationModal({
  isOpen,
  onClose,
  allStages = [],
  taskStages,
  users,
}: AutomationModalProps) {

  const {
    selectedTrigger,
    selectedTriggerPhase,
    selectedAction,
    actionResponsible,
    actionTaskInfo,
    actionNotify,
    automationInfo,
    canShowActionField,
    canShowLastStep,
    setSelectedTrigger,
    setCelectedTriggerPhase,
    setSelectedAction,
    setActionResponsible,
    setActionTaskInfo,
    setActionNotify,
    setautomationInfo,
    handleCreateAutomation,
  } = useAutomationModal({ onClose })

  return (
    <Modal
      title="Adicionar nova automação"
      centered
      open={isOpen}
      onOk={handleCreateAutomation}
      onCancel={onClose}
    >
      <div className="space-y-4 max-h-[550px] overflow-auto">
        <div className="border border-zinc-300 rounded-md">
          <div className="p-3 border-b border-zinc-300">
            <h1 className="font-medium text-lg">Quando</h1>
            <p className="text-sm text-zinc-400 font-normal">
              Define um gatilho para iniciar a automação
            </p>
          </div>
          <div className="p-3 flex flex-col gap-3">
            <Select
              showSearch
              placeholder="Selecione o gatilhos"
              defaultValue={selectedTrigger}
              optionFilterProp="children"
              onChange={(value) => setSelectedTrigger(value)}
              filterOption={filterOption}
              notFoundContent="Nenhum gatilho encontrado..."
              options={TRIGGERS}
              style={{ width: '100%' }}
              size="middle"
            />

            {selectedTrigger && (
              <div className="pl-3">
                {selectedTrigger !== 'FUNIL' && (
                  <Select
                    showSearch
                    placeholder="Selecione a Etapa"
                    mode="multiple"
                    value={selectedTriggerPhase}
                    optionFilterProp="children"
                    onChange={(values) => setCelectedTriggerPhase(values)}
                    filterOption={filterOption}
                    notFoundContent="Nenhuma etapa encontrado..."
                    options={allStages?.map((stage) => ({
                      label: stage.title,
                      value: stage.id
                    }))}
                    style={{ width: '100%' }}
                    size="middle"
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {canShowActionField && (
          <>
            <div className="w-full text-center">
              <ArrowDownOutlined style={{ fontSize: '20px' }} />
            </div>

            <div className="border border-zinc-300 rounded-md">
              <div className="p-3 border-b border-zinc-300">
                <h1 className="font-medium text-lg">Ação</h1>
                <p className="text-sm text-zinc-400 font-normal">
                  Define uma ação a ser realizada no acionamento do gatilho
                </p>
              </div>
              <div className="p-3 flex flex-col gap-3">
                <Select
                  disabled={!selectedTrigger || selectedTriggerPhase?.length === 0}
                  showSearch
                  placeholder="Adicionar ação ou condição"
                  defaultValue={selectedAction}
                  optionFilterProp="children"
                  onChange={(value) => setSelectedAction(value)}
                  filterOption={filterOption}
                  notFoundContent="Nenhuma ação encontrada..."
                  options={ACTIONS}
                  style={{ width: '100%' }}
                  size="middle"
                />

                {selectedAction && (
                  <div className="pl-3">
                    {selectedAction === 'CHANGE_RESPONSIBLE' && (
                      <Select
                        showSearch
                        placeholder="Atribua um responsável"
                        mode="multiple"
                        defaultValue={actionResponsible}
                        optionFilterProp="children"
                        onChange={(value) => setActionResponsible(value)}
                        filterOption={filterOption}
                        notFoundContent="Nenhum responsável encontrado..."
                        options={users.map(user => ({
                          label: user.name,
                          value: user.id
                        }))}
                        style={{ width: '100%' }}
                        size="middle"
                      />
                    )}

                    {selectedAction === 'CREATE_ACTIVITY' && (
                      <div className="space-y-3">
                        <Input
                          placeholder="Nome da tarefa"
                          value={actionTaskInfo?.title}
                          onChange={(event) => setActionTaskInfo(prev => ({
                            ...prev,
                            title: event.target.value
                          }))}
                          size="middle"
                        />

                        <Select
                          placeholder="Etapa"
                          value={actionTaskInfo.stage ?? null}
                          onChange={(value) => setActionTaskInfo(prev => ({
                            ...prev,
                            stage: value
                          }))}
                          options={taskStages?.map((item) => ({
                            label: item.title,
                            value: item.id
                          }))}
                          style={{ width: '100%' }}
                          size="middle"
                        />

                        <DatePicker
                          format="DD/MM/YYYY HH:mm"
                          showTime={{
                            showSecond: false,
                            format: "HH:mm",
                          }}
                          onChange={(value) => setActionTaskInfo(prev => ({
                            ...prev,
                            date: dayjs(value).format('YYYY-MM-DD HH:mm')
                          }))}
                          placeholder="Data de vencimento"
                          style={{ width: '100%', backgroundColor: '#FFFFFF'}}
                        />

                        <TextArea
                          value={actionTaskInfo?.description}
                          onChange={(event) => setActionTaskInfo(prev => ({
                            ...prev,
                            description: event.target.value
                          }))}
                          placeholder="Insira a descrição da tarefa aqui"
                          rows={4}
                          size="middle"
                        />

                        <Select
                          showSearch
                          placeholder="Responsável"
                          optionFilterProp="children"
                          mode="multiple"
                          value={actionTaskInfo?.users}
                          onChange={(value) => setActionTaskInfo(prev => ({
                            ...prev,
                            users: value
                          }))}
                          filterOption={filterOption}
                          notFoundContent="Nenhum responsável encontrado..."
                          options={users.map(user => ({
                            label: user.name,
                            value: user.id
                          }))}
                          style={{ width: '100%' }}
                          size="middle"
                        />
                      </div>
                    )}

                    {selectedAction === 'NOTIFY' && (
                      <div className="space-y-3">
                        <Select
                          showSearch
                          placeholder="Tipo da notificação"
                          optionFilterProp="children"
                          mode="multiple"
                          value={actionNotify?.type}
                          onChange={(value) => setActionNotify(prev => ({
                            ...prev,
                            type: value
                          }))}
                          filterOption={filterOption}
                          notFoundContent="Nenhum tipo encontrado..."
                          options={[
                            { label: 'Dentro do Fexei', value: 'INSIDE' },
                            { label: 'No e-mail', value: 'OUTSIDE_EMAIL' }
                          ]}
                          style={{ width: '100%' }}
                          size="middle"
                        />
                        <TextArea
                          value={actionNotify?.description}
                          onChange={(event) => setActionNotify(prev => ({
                            ...prev,
                            description: event.target.value
                          }))}
                          placeholder="Conteúdo da notificação"
                          size="middle"
                          rows={4}
                        />
                        <Select
                          showSearch
                          placeholder="Usuários"
                          optionFilterProp="children"
                          mode="multiple"
                          value={actionTaskInfo?.users}
                          onChange={(value) => setActionTaskInfo(prev => ({
                            ...prev,
                            users: value
                          }))}
                          filterOption={filterOption}
                          notFoundContent="Nenhum usuário encontrado..."
                          options={users.map(user => ({
                            label: user.name,
                            value: user.id
                          }))}
                          style={{ width: '100%' }}
                          size="middle"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {canShowLastStep && (
          <>
            <div className="w-full text-center">
              <ArrowDownOutlined style={{ fontSize: '20px' }} />
            </div>

            <div className="border border-zinc-300 rounded-md">
              <div className="p-3 border-b border-zinc-300">
                <h1 className="font-medium text-lg">Sobre a automação</h1>
                <p className="text-sm text-zinc-400 font-normal">
                  Adicione aqui as informações sobre a sua automação
                </p>
              </div>
              <div className="p-3 flex flex-col gap-3">

                <div className="space-y-3">
                  <Input
                    placeholder="Titulo da automação"
                    value={automationInfo?.title}
                    onChange={(event) => setautomationInfo(prev => ({
                      ...prev,
                      title: event.target.value
                    }))}
                    size="middle"
                  />

                  <Input
                    placeholder="Objetivo da automação"
                    value={automationInfo?.objective}
                    onChange={(event) => setautomationInfo(prev => ({
                      ...prev,
                      objective: event.target.value
                    }))}
                    size="middle"
                  />

                  <div className="flex items-center justify-between">
                    <div>
                      <h1 className="font-medium text-lg">
                        Status da automação
                      </h1>
                      <p className="text-sm text-zinc-400 font-normal">
                        Sua automação ficará {automationInfo?.active ? 'ativada' : 'desativada'}
                      </p>
                    </div>
                    <Switch
                      defaultChecked
                      onChange={() => setautomationInfo(prev => ({
                        ...prev,
                        active: !automationInfo.active
                      }))}
                      className="bg-zinc-400"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}