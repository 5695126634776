import { Button } from "antd";
import { useState } from "react";

interface ActivActivityDescriptionProps {
  description: string | null
}

export function ActivityDescription({ description }: ActivActivityDescriptionProps){
  const [isOpenDescription, setIsOpenDescription] = useState(false)

  return (
    <div>
      {isOpenDescription && (
        <p className="mt-2">
          {description}
        </p>
      )}
      <Button
        type="link"
        className="p-0"
        onClick={() => setIsOpenDescription(!isOpenDescription)}
      >
        {isOpenDescription ? 'Fechar' : 'Ver'} descrição
      </Button>
    </div>
  )
}