import { useForm, useSelect } from "@refinedev/antd";
import { HttpError, useGetIdentity } from "@refinedev/core";

import { Button, Form, Select, Space } from "antd";

import { Task, User } from "@/interfaces";

type Props = {
  initialValues: {
    userIds?: { label: string; value: string }[];
  };
  cancelForm: () => void;
};

export const UsersForm = ({ initialValues, cancelForm }: Props) => {
  const { formProps, saveButtonProps } = useForm<Task, HttpError, Task>({
    queryOptions: {
      enabled: false,
    },
    redirect: false,
    onMutationSuccess: () => {
      cancelForm();
    },
  });

  const { data } = useGetIdentity<User>();

  const { selectProps } = useSelect({
    resource: "users",
    meta: {
      fields: ["name", "id"],
    },
    optionLabel: "name",
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "end",
        justifyContent: "space-between",
        gap: "12px",
      }}
    >
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish?.({
            ...values,
            enterprise: data?.userData?.enterprise as string,
          });
        }}
        style={{ width: "100%" }}
        initialValues={initialValues}
      >
        <Form.Item noStyle name="userIds">
          <Select
            {...selectProps}
            className="kanban-users-form-select"
            dropdownStyle={{ padding: "0px" }}
            style={{ width: "100%" }}
            mode="multiple"
          />
        </Form.Item>
      </Form>
      <Space>
        <Button type="default" onClick={cancelForm}>
          Cancelar
        </Button>
        <Button {...saveButtonProps} type="primary">
          Salvar
        </Button>
      </Space>
    </div>
  );
};
