import {
  AppstoreOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Form, Grid, Input, Radio, Space, Spin } from "antd";

interface HeaderButtonProps {
  screens: any;
  searchFormProps: any;
  debouncedOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tableQueryResult: any;
}

export const HeaderButtons = ({
  screens,
  searchFormProps,
  debouncedOnChange,
  tableQueryResult,
}: HeaderButtonProps) => {
  return (
    <Space
      style={{
        marginTop: screens.xs ? "1.6rem" : undefined,
      }}
    >
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="name" noStyle>
          <Input
            size="large"
            prefix={<SearchOutlined className="anticon tertiary" />}
            suffix={
              <Spin size="small" spinning={tableQueryResult.isFetching} />
            }
            placeholder="Pesquisar por nome"
            onChange={debouncedOnChange}
          />
        </Form.Item>
      </Form>
    </Space>
  );
};
