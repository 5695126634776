import { TaskStage, User } from "@/interfaces";
import { useModalForm } from "@refinedev/antd";
import { useGetIdentity, useInvalidate, useNavigation } from "@refinedev/core";

import { Form, Input, Modal } from "antd";

export const KanbanCreateStage = () => {
  const invalidate = useInvalidate();
  const { list } = useNavigation();
  const { data } = useGetIdentity<User>();
  const { formProps, modalProps, close } = useModalForm({
    action: "create",
    defaultVisible: true,
    resource: "taskStages",
    meta: {
      fields: ["id"],
    },
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "tasks" });
    },
    successNotification: () => {
      return {
        key: "create-stage",
        type: "success",
        message: "Etapa criada com sucesso",
        description: "Sucesso",
      };
    },
  });

  return (
    <Modal
      {...modalProps}
      onCancel={() => {
        close();
        list("tasks", "replace");
      }}
      title="Adicionar nova etapa"
      width={512}
    >
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish?.({
            ...values,
            createdBy: data?.id,
            updatedBy: data?.id,
            enterprise: data?.userData?.enterprise,
          });
        }}
        layout="vertical"
      >
        <Form.Item
          label="Título da etapa"
          name="title"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
