import * as AntdIcons from "@ant-design/icons";
import { User } from "@/interfaces";
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  TimePicker,
  notification,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { filterOption } from "../../AutomationModal/utils";
import { IconSelect } from "./IconSelect";
import { useModalForm } from "@refinedev/antd";
import { useGetIdentity, useInvalidate } from "@refinedev/core";
import { supabase } from "@/providers/supabase";

interface ActivityModalProps {
  isOpen: boolean;
  onClose: () => void;
  users: User[];
  dealId: number;
  activityToEdit: any;
}

export function ActivityModal({
  isOpen,
  onClose,
  users,
  dealId,
  activityToEdit,
}: ActivityModalProps) {
  const invalidate = useInvalidate();
  const { data } = useGetIdentity<User>();
  const [isAllDayEvent, setIsAllDayEvent] = useState(false);
  const [actionResponsible, setActionResponsible] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState("PhoneOutlined");

  const { form, formProps, modalProps, onFinish } = useModalForm({
    action: activityToEdit ? "edit" : "create",
    defaultVisible: true,
    resource: "dealActivities",
  });

  useEffect(() => {
    if (activityToEdit) {
      const timeInfo = (JSON.parse(activityToEdit.time) ?? []).map(
        (item: any) => dayjs(item)
      );

      const rangeInfo = (JSON.parse(activityToEdit.rangeDate) ?? []).map(
        (item: any) => dayjs(item)
      );

      setSelectedIcon(activityToEdit.icon);
      form.setFieldValue("title", activityToEdit.title);
      form.setFieldValue("description", activityToEdit.description);
      form.setFieldValue("owners", activityToEdit.ownersIds);
      form.setFieldValue("rangeDate", rangeInfo);
      form.setFieldValue("time", timeInfo);
      form.setFieldValue(
        "date",
        activityToEdit.date ? dayjs(JSON.parse(activityToEdit.date)) : ""
      );
      form.setFieldValue("isAllDayEvent", activityToEdit.isAllDayEvent);
    }

    return () => {
      form.setFieldValue("title", null);
      form.setFieldValue("description", null);
      form.setFieldValue("owners", null);
      form.setFieldValue("rangeDate", null);
      form.setFieldValue("date", null);
      form.setFieldValue("time", null);
      form.setFieldValue("isAllDayEvent", null);
    };
  }, [activityToEdit]);

  // @ts-ignore
  const Icon = AntdIcons[selectedIcon];

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setIsAllDayEvent(checked);

    if (checked) {
      form.setFieldsValue({
        date: undefined,
        time: undefined,
        rangeDate: [dayjs(), dayjs()],
      });
    } else {
      form.setFieldsValue({
        rangeDate: undefined,
        date: dayjs(),
        time: [dayjs().startOf("hour"), dayjs().startOf("hour").add(1, "hour")],
      });
    }
  };

  async function handleCreateActivity(values: any) {
    const { title, description, rangeDate, date, time, owners } = values;

    let startDate = rangeDate
      ? rangeDate[0].startOf("day")
      : date
          ?.set("hour", time[0].hour())
          ?.set("minute", time[0].minute())
          ?.set("second", 0);

    let endDate = rangeDate
      ? rangeDate[1].endOf("day")
      : date
          ?.set("hour", time[1].hour())
          ?.set("minute", time[1].minute())
          ?.set("second", 0);

    const eventData = {
      title: title,
      description: description,
      startDate: startDate,
      endDate: endDate,
    };

    const participantDetails = await Promise.all(
      owners.map(async (id: any) => {
        const { data, error } = await supabase
          .from("users")
          .select("id, name, email")
          .eq("id", id)
          .single();

        if (error) {
          throw error;
        }

        return data;
      })
    );

    const payload = {
      icon: selectedIcon,
      title,
      description,
      startDate,
      endDate,
      ownersIds: owners,
      createdBy: data?.id,
      enterprise: data?.userData?.enterprise,
      deal: dealId,
      rangeDate: JSON.stringify(rangeDate),
      date: JSON.stringify(date),
      time: JSON.stringify(time),
      isAllDayEvent,
    };

    if (activityToEdit) {
      await supabase
        .from("dealActivities")
        .update(payload)
        .eq("id", activityToEdit?.id);

      invalidate({
        invalidates: ["list"],
        resource: "dealActivities",
      });

      notification.success({
        message: "Sucesso!",
        description: "Atividade atualizada com sucesso!",
      });
    } else {
      const createdEvent = await supabase
        .from("events")
        .insert({
          title,
          description,
          participantIds: owners,
          participants: participantDetails,
          enterprise: data?.userData?.enterprise,
          startDate: startDate.toISOString(),
          createdBy: data?.id,
          updatedBy: data?.id,
          endDate: endDate.toISOString(),
          color: "#1677ff",
        })
        .select("id")
        .single();

      await onFinish({ ...payload, event: createdEvent?.data?.id });
    }

    onClose();
  }

  return (
    <Modal
      {...modalProps}
      open={isOpen}
      title={activityToEdit ? "Editar Atividade" : "Nova Atividade"}
      okText="Salvar Atividade"
      cancelText="Fechar"
      width={512}
      onCancel={onClose}
    >
      <Form {...formProps} onFinish={handleCreateActivity} layout="vertical">
        <Form.Item
          label="Nome da atividade"
          name="title"
          rules={[{ required: true }]}
          initialValue={activityToEdit?.title ?? ""}
        >
          <div className="w-full !flex items-center gap-2 !flex-row">
            <Popover
              className="py-2 px-2 hover:bg-zinc-100 rounded-md"
              content={
                <IconSelect onSelectIcon={(icon) => setSelectedIcon(icon)} />
              }
              trigger="click"
            >
              <Icon className="text-lg" />
            </Popover>
            <Input name="title" defaultValue={activityToEdit?.title ?? ""} />
          </div>
        </Form.Item>

        <Form.Item
          label="Responsável"
          name="owners"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Atribua um responsável"
            mode="multiple"
            defaultValue={actionResponsible}
            optionFilterProp="children"
            onChange={(value) => setActionResponsible(value)}
            filterOption={filterOption}
            notFoundContent="Nenhum responsável encontrado..."
            options={users?.map((user: any) => ({
              label: user.name,
              value: user.id,
            }))}
            style={{ width: "100%" }}
            size="middle"
          />
        </Form.Item>

        <Form.Item
          label="Data e Hora"
          name={isAllDayEvent ? "rangeDate" : "date"}
          rules={[
            {
              required: true,
              message: "Por favor, insira a data",
            },
          ]}
        >
          <div className="space-y-2">
            {isAllDayEvent ? (
              <div>
                <DatePicker.RangePicker
                  className="w-full"
                  format={"DD/MM/YYYY"}
                />
              </div>
            ) : (
              <div className="flex justify-between">
                <Form.Item
                  name="date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  noStyle
                >
                  <DatePicker className="w-[160px]" format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                  name="time"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  noStyle
                >
                  <TimePicker.RangePicker
                    className="w-[240px]"
                    format={"HH:mm"}
                    placeholder={["Início", "Fim"]}
                    minuteStep={15}
                  />
                </Form.Item>
              </div>
            )}
            <Checkbox checked={isAllDayEvent} onChange={handleCheckboxChange}>
              O dia todo
            </Checkbox>
          </div>
        </Form.Item>

        <Form.Item label="Descrição" name="description">
          <Input.TextArea
            placeholder="Insira a descrição da atividade aqui"
            rows={4}
            size="middle"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
