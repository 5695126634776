import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  TrophyOutlined,
  WarningOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

const icons = [
  {
    icon: CalendarOutlined,
    name: 'CalendarOutlined'
  },
  {
    icon: CheckCircleOutlined,
    name: 'CheckCircleOutlined'
  },
  {
    icon: ClockCircleOutlined,
    name: 'ClockCircleOutlined'
  },
  {
    icon: MailOutlined,
    name: 'MailOutlined'
  },
  {
    icon: PhoneOutlined,
    name: 'PhoneOutlined'
  },
  {
    icon: TrophyOutlined,
    name: 'TrophyOutlined'
  },
  {
    icon: WarningOutlined,
    name: 'WarningOutlined'
  },
  {
    icon: WhatsAppOutlined,
    name: 'WhatsAppOutlined'
  },
]

interface IconSelectProps {
  onSelectIcon: (icon: string) => void
}

export function IconSelect({ onSelectIcon }: IconSelectProps){
  return (
    <div className="min-w-32 p-2 bg-white shadow-sm">
      <p>Escolha um icone para a atividade</p>

      <div className="mt-6 grid grid-cols-5 gap-x-1 gap-y-6">
        {icons.map((icon) => {
          const Icon = icon.icon
          return (
            <button
              onClick={() => onSelectIcon(icon.name)}
              className="py-2 px-2 hover:bg-zinc-100 rounded-md"
            >
              <Icon />
            </button>
          )
        })}
      </div>
    </div>
  )
}