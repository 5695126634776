import { User } from "@/interfaces";
import { useCreate, useGetIdentity } from "@refinedev/core";
import { notification } from "antd";
import { useState } from "react";
import {
  INITIAL_ACTION_NOTIFY,
  INITIAL_ACTION_TASK_INFO,
  INITIAL_AUTOMATION_INFO,
} from "../constants";

interface UseAutomationModalProps {
  onClose: () => void;
}

export function useAutomationModal({ onClose }: UseAutomationModalProps) {
  const [selectedTrigger, setSelectedTrigger] = useState("");
  const [selectedTriggerPhase, setCelectedTriggerPhase] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [actionResponsible, setActionResponsible] = useState([]);
  const [actionTaskInfo, setActionTaskInfo] = useState(
    INITIAL_ACTION_TASK_INFO
  );
  const [actionNotify, setActionNotify] = useState(INITIAL_ACTION_NOTIFY);
  const [automationInfo, setautomationInfo] = useState(INITIAL_AUTOMATION_INFO);

  const canShowActionField = selectedTrigger && selectedTriggerPhase.length > 0;
  const canShowLastStep = canShowActionField && selectedAction;

  const { data } = useGetIdentity<User>();
  const { mutate } = useCreate();

  async function handleCreateAutomation() {
    const actions = {
      CHANGE_RESPONSIBLE: actionResponsible,
      CREATE_ACTIVITY: actionTaskInfo,
      NOTIFY: actionNotify,
    };

    if (!(selectedAction in actions)) {
      console.error("Selected action is not valid");
      return;
    }

    const automationConfig = {
      trigger: selectedTrigger,
      phase: selectedTriggerPhase,
      actionType: selectedAction,
      actionDetails: actions[selectedAction as keyof typeof actions],
      automationInfo: automationInfo,
      createdBy: data?.id, // Assuming 'data' contains the user's info
      active: automationInfo.active,
    };

    try {
      // const { error } = await supabase
      //   .from("automations")
      //   .insert([automationConfig]);

      // if (error) throw error;

      mutate({
        resource: "automations",
        values: automationConfig,
      });

      notification.open({
        description: "Sucesso!",
        type: "success",
        message: "Automação criada com sucesso",
      });
      onClose();
    } catch (error) {
      console.error("Error saving automation configuration:", error);
    }
  }

  return {
    selectedTrigger,
    selectedTriggerPhase,
    selectedAction,
    actionResponsible,
    actionTaskInfo,
    actionNotify,
    automationInfo,
    canShowActionField,
    canShowLastStep,
    setSelectedTrigger,
    setCelectedTriggerPhase,
    setSelectedAction,
    setActionResponsible,
    setActionTaskInfo,
    setActionNotify,
    setautomationInfo,
    handleCreateAutomation,
  };
}
