import { removeCookie, setCookie } from "@/utilities/cookies-manager";
import { createClient } from "@refinedev/supabase";

export const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_KEY,
  {
    db: {
      schema: "public",
    },
    auth: {
      persistSession: true,
    },
  }
);
supabase.auth.onAuthStateChange((event, session) => {
  if (event === "SIGNED_OUT" || event === "TOKEN_REFRESHED") {
    removeCookie("supabase.auth.token");
    removeCookie("supabase.auth.refresh_token");
  } else if (event === "SIGNED_IN") {
    if (session) {
      setCookie("supabase.auth.token", session.access_token, 30);
      setCookie("supabase.auth.refresh_token", session.refresh_token, 30);
    }
  }
});
