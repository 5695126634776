import React from "react";

export const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0.00 0.00 382.00 382.00"
    {...props}
  >
    <path
      fill="#0076ff"
      d="
  M 250.69 59.33
  C 185.70 29.69 111.17 51.53 70.74 109.49
  C 56.02 130.60 47.08 156.28 45.66 182.21
  C 42.00 248.71 81.95 308.12 145.26 329.07
  C 192.14 344.58 244.72 334.41 282.69 302.93
  C 320.98 271.19 340.40 222.64 334.18 173.01
  C 333.25 165.52 333.00 156.67 325.98 154.04
  Q 319.06 151.45 313.89 156.64
  Q 249.16 221.62 225.25 245.00
  Q 223.82 246.40 220.07 249.17
  C 196.89 266.34 164.16 262.83 144.09 242.68
  Q 123.72 222.23 103.30 201.83
  Q 95.10 193.63 93.20 189.90
  C 86.87 177.50 94.20 161.80 107.74 158.41
  C 117.01 156.09 124.20 159.24 130.68 165.83
  Q 150.50 186.01 170.47 206.05
  Q 177.63 213.22 181.12 214.57
  C 188.69 217.50 193.94 213.25 199.56 207.63
  Q 237.67 169.52 275.78 131.43
  Q 285.85 121.37 290.62 118.06
  C 319.62 98.00 359.46 110.04 373.16 142.60
  Q 375.22 147.52 377.07 157.48
  C 391.78 237.09 353.68 316.37 283.90 356.08
  C 235.08 383.87 177.23 388.66 124.52 369.17
  C 42.44 338.82 -7.87 256.42 1.68 169.50
  Q 4.07 147.74 11.77 127.24
  Q 38.61 55.79 105.81 21.32
  C 159.39 -6.16 225.56 -4.81 278.66 23.06
  C 283.01 25.35 287.89 28.06 290.95 31.59
  C 304.18 46.85 291.18 71.03 271.16 68.08
  C 264.65 67.12 256.84 62.13 250.69 59.33
  Z"
    />
  </svg>
);
