import { AuthContext } from "@/contexts/auth-context";
import { useNavigation } from "@refinedev/core";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSubscription } from "./useSubscription";

export const useCheckSubscription = () => {
  const location = useLocation();
  const navigation = useNavigation();
  const { isProfessionalOrElite, isTrial, remainingDays, isActive } =
    useSubscription();
  const { session } = useContext(AuthContext);

  useEffect(() => {
    const shouldNavigateToTrial = isTrial && !isActive;

    if (shouldNavigateToTrial && location.pathname !== "/trial") {
      navigation.push("/trial");
    }
  }, [
    session,
    isProfessionalOrElite,
    isTrial,
    remainingDays,
    location.pathname,
    navigation,
  ]);
};
