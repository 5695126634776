import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useModalForm, useSelect } from "@refinedev/antd";
import {
  HttpError,
  useCreate,
  useGetIdentity,
  useList,
  useNavigation,
} from "@refinedev/core";

import {
  DollarOutlined,
  MailOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

import { SelectOptionWithAvatar } from "@/components";
import { Company, Contact, Deal, User } from "@/interfaces";
import { supabase } from "@/providers/supabase";
import { formatForDisplay } from "./lib/format-display";
import { pipeline } from "stream";

type FormValues = {
  stageId?: string | null;
  companyId?: string;
  dealContactId?: string;
  dealOwnerId?: string;
  title?: string;
  contactName?: string;
  contactEmail?: string;
};

export const SalesCreatePage: FC<PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { list, replace } = useNavigation();

  const { data } = useGetIdentity<User>();

  const [isTypingContact, setIsTypingContact] = useState(false);

  const [initialCompany, setInitialCompany] = useState<
    { value: string; label: JSX.Element } | undefined
  >(undefined);

  const { selectProps: pipelineSelectProps } = useSelect({
    resource: "pipelines",
    meta: {
      fields: ["title", "id"],
    },
    defaultValue: 0,
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    optionLabel: "title",
  });

  const { formProps, modalProps, close } = useModalForm<
    Deal,
    HttpError,
    FormValues
  >({
    action: "create",
    defaultVisible: true,
  });

  const { selectProps: stageSelectProps } = useSelect({
    resource: "dealStages",
    meta: {
      fields: ["title", "id"],
    },
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
  });

  useEffect(() => {
    const companyId = searchParams.get("companyId");

    if (companyId && companyId !== "null") {
      formProps.form?.setFieldsValue({
        companyId,
      });
    }
  }, [searchParams]);

  const { data: user } = useGetIdentity<User>();

  const { selectProps: userSelectProps, queryResult: userQueryResult } =
    useSelect<User>({
      resource: "users",
      meta: {
        fields: ["name", "id", "avatarUrl"],
      },
      optionLabel: "name",
      filters: [
        {
          field: "enterprise",
          operator: "eq",
          value: data?.userData?.enterprise,
        },
      ],
    });

  const { data: companyData } = useList<Company>({
    resource: "companies",
    meta: { fields: ["id", "name", "avatarUrl", "enterprise"] },
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
  });

  const { data: contactData } = useList<Contact>({
    resource: "contacts",
    meta: { fields: ["id", "name", "avatarUrl", "companyId"] },
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
  });

  const combinedData = companyData?.data.map((company) => ({
    ...company,
    contacts: contactData?.data.filter(
      (contact) => contact.companyId === company.id
    ),
  }));

  const companyId = formProps.form?.getFieldValue("companyId")?.value ?? "0";

  useEffect(() => {
    formProps.form?.setFieldValue("dealContactId", undefined);
  }, [companyId]);

  useEffect(() => {
    if (companyId && companyId !== "null") {
      const selectedCompany = companyData?.data.find(
        (company) => Number(company.id) === Number(companyId)
      );

      if (selectedCompany) {
        formProps.form?.setFieldsValue({
          companyId: selectedCompany.id,
        });
        setInitialCompany({
          value: selectedCompany.id,
          label: (
            <SelectOptionWithAvatar
              name={selectedCompany.name}
              shape="square"
              avatarUrl={selectedCompany.avatarUrl ?? undefined}
            />
          ),
        });
      }
    }
  }, [searchParams, companyData, formProps.form]);

  const renderContactForm = () => {
    if (!companyId) {
      return null;
    }

    const selectedCompany = combinedData?.find(
      (company) => company.id === companyId
    );

    const hasContact = selectedCompany?.contacts?.length ?? 0 > 0;

    if (hasContact) {
      const options = selectedCompany?.contacts?.map((contact) => ({
        label: (
          <SelectOptionWithAvatar
            name={contact.name}
            avatarUrl={contact.avatarUrl ?? undefined}
          />
        ),
        value: contact.id,
      }));

      return (
        <Form.Item
          label="Selecione um contato"
          name="dealContactId"
          rules={[{ required: false }]}
        >
          <Select options={options} />
        </Form.Item>
      );
    }

    return (
      <Row gutter={12} className="flex justify-center">
        <>
          <Col span={12}>
            <Form.Item
              label="Nome do contato"
              name="contactName"
              rules={[{ required: false }]}
            >
              <Input
                addonBefore={
                  <UserOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                placeholder="Digite o nome do contato"
                onChange={(e) => setIsTypingContact(e.target.value.length > 0)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="E-mail do contato"
              name="contactEmail"
              rules={[{ required: false }]}
            >
              <Input
                addonBefore={
                  <MailOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                placeholder="Digite o e-mail do contato"
                onChange={(e) => setIsTypingContact(e.target.value.length > 0)}
              />
            </Form.Item>
          </Col>
        </>
      </Row>
    );
  };

  const isHaveOverModal =
    pathname === "/scrumboard/sales/create/company/create";

  return (
    <>
      <Modal
        {...modalProps}
        style={{ display: isHaveOverModal ? "none" : "inherit" }}
        onCancel={() => {
          close();
          list("deals", "replace");
        }}
        title="Adicionar novo negócio"
        width={512}
        okText="Salvar"
        cancelText="Cancelar"
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={async (values: any) => {
            console.log({ values });

            let companyName: string;
            let companyAvatarUrl: string;
            if (companyId === undefined) {
              companyName = "Sem nome da empresa";
              companyAvatarUrl = "semavatar.com.br";
            } else {
              const companyResponse = await supabase
                .from("companies")
                .select("name, avatarUrl")
                .eq("id", companyId)
                .single();

              if (companyResponse.data) {
                companyName = companyResponse.data.name;
                companyAvatarUrl = companyResponse.data.avatarUrl;
              } else {
                companyName = "Sem nome da empresa";
                companyAvatarUrl = "semavatar.com.br";
              }
            }

            const { data: userData } = await supabase
              .from("users")
              .select("*")
              .eq("id", values.dealOwnerId)
              .single();

            if (values.contactName && values.contactEmail) {
              try {
                const contactResponse = await supabase
                  .from("contacts")
                  .insert([
                    {
                      name: values.contactName,
                      email: values.contactEmail,
                      salesOwnerId: user?.id,
                      createdBy: user?.id,
                      updatedBy: user?.id,
                      company: companyId ?? null,
                      companyId: companyId ?? null,
                      enterprise: user?.userData?.enterprise,
                    },
                  ])
                  .select();

                if (!contactResponse.data) {
                  console.error(
                    "No data returned from the API after creating contact",
                    contactResponse
                  );
                  return;
                }

                const dealData = {
                  ...values,
                  dealContactId: contactResponse.data[0].id,
                  companyName,
                  companyAvatarUrl,
                  dealOwner: user?.userData?.id,
                  dealContact: contactResponse.data[0].id,
                  dealOwnerName: userData?.name,
                  dealOwnerAvatarUrl: userData?.avatarUrl,
                  enterprise: user?.userData?.enterprise,
                  createdBy: user?.id,
                  updatedBy: user?.id,
                  company: companyId ?? null,
                  companyId: companyId ?? null,
                  pipelineId: values.pipelineId,
                  stage: values.stageId,
                };

                delete dealData.contactName;
                delete dealData.contactEmail;
                delete dealData.selectedContactName;

                formProps.onFinish?.(dealData);
              } catch (err) {
                console.error("Error creating contact", err);
              }
            } else if (values.dealContactId) {
              const selectedContactId = values.dealContactId;

              const dealData = {
                ...values,
                dealContact: selectedContactId,
                dealContactId: selectedContactId,
                company: companyId ?? null,
                companyId: companyId ?? null,
                companyName,
                companyAvatarUrl,
                dealOwner: user?.userData?.id,
                dealOwnerName: userData?.name,
                dealOwnerAvatarUrl: userData?.avatarUrl,
                createdBy: user?.id,
                updatedBy: user?.id,
                stage: values.stageId,
                enterprise: user?.userData?.enterprise,
                pipelineId: values.pipelineId,
              };

              delete dealData.contactName;
              delete dealData.contactEmail;
              delete dealData.selectedContactName;

              formProps.onFinish?.(dealData);
            } else {
              const dealData = {
                ...values,
                company: companyId ?? null,
                companyId: companyId ?? null,
                companyName,
                companyAvatarUrl,
                dealOwner: user?.userData?.id,
                dealOwnerName: userData?.name,
                dealOwnerAvatarUrl: userData?.avatarUrl,
                createdBy: user?.id,
                updatedBy: user?.id,
                stage: values.stageId,
                enterprise: user?.userData?.enterprise,
                pipelineId: values.pipelineId,
              };

              delete dealData.contactName;
              delete dealData.contactEmail;
              delete dealData.selectedContactName;

              formProps.onFinish?.(dealData);
            }
          }}
        >
          <Form.Item
            label="Nome do negócio"
            name="title"
            rules={[{ required: true }]}
          >
            <Input placeholder="Digite o nome do negócio" />
          </Form.Item>

          <Form.Item
            label="Pipeline"
            name="pipelineId"
            rules={[
              { required: true, message: "Por favor, selecione uma pipeline" },
            ]}
          >
            <Select
              {...pipelineSelectProps}
              placeholder="Selecione uma pipeline"
            />
          </Form.Item>

          <Form.Item
            label="Empresa"
            name="companyId"
            rules={[{ required: false }]}
            extra={
              <Typography.Link
                style={{ marginTop: 8, display: "block" }}
                onClick={() =>
                  replace("company/create?to=/scrumboard/sales/create")
                }
              >
                <PlusCircleOutlined
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />{" "}
                Adicionar nova empresa
              </Typography.Link>
            }
          >
            <Select
              labelInValue
              defaultValue={initialCompany}
              placeholder="Selecione uma empresa"
              options={
                companyData?.data?.map((company) => ({
                  value: company.id,
                  label: (
                    <SelectOptionWithAvatar
                      name={company.name}
                      shape="square"
                      avatarUrl={company.avatarUrl ?? undefined}
                    />
                  ),
                })) ?? []
              }
            />
          </Form.Item>

          {renderContactForm()}

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Etapa" name="stageId">
                <Select
                  placeholder="Selecione uma etapa"
                  {...stageSelectProps}
                  optionLabelProp="label"
                  showSearch={false}
                  options={stageSelectProps?.options?.concat([
                    {
                      label: "BACKLOG",
                      value: null,
                    },
                  ])}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                rules={[{ required: true }]}
                label="Valor do negócio"
                name="value"
              >
                <InputNumber
                  min={0}
                  addonBefore={
                    <DollarOutlined
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    />
                  }
                  placeholder="0,00"
                  formatter={(value) => {
                    if (!value) return "";
                    // Assuming `formatForDisplay` expects value in cents
                    return formatForDisplay(value);
                  }}
                  // Adjust the parser
                  parser={(displayValue: any) => {
                    if (!displayValue) return 0;
                    // Remove non-numeric characters and convert to integer
                    const numericValue = displayValue.replace(/[^\d]/g, "");
                    return parseInt(numericValue, 10) || 0;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Responsável pelo negócio"
            name="dealOwnerId"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Selecione um usuário"
              {...userSelectProps}
              options={
                userQueryResult.data?.data?.map((user) => ({
                  value: user.id,
                  label: (
                    <SelectOptionWithAvatar
                      name={user.name}
                      avatarUrl={user.avatarUrl ?? undefined}
                    />
                  ),
                })) ?? []
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      {children}
    </>
  );
};
