/**
 * v0 by Vercel.
 * @see https://v0.dev/t/HifkHkBPAeM
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

import { CheckCircleOutlined } from "@ant-design/icons";

export function TrialPage() {
  return (
    <section className="w-full py-6 md:py-12 lg:py-16 ">
      <div className="container px-4 md:px-6 flex flex-col ">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-4">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
              O teste grátis do fexei acabou
            </h2>
            <p className="max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
              Escolha um plano para continuar usando o fexei.
            </p>
          </div>
        </div>
        <div className="mx-auto flex flex-col max-w-sm justify-items-center gap-6 border-t border-b py-6 sm:max-w-4xl  md:gap-12 lg:max-w-5xl lg:grid-cols-3 border-gray-200 dark:border-gray-800 xs:flex-col  md:flex-row">
          <div className="flex flex-col items-center p-4 space-y-1.5 bg-slate-200 rounded-xl">
            {" "}
            <h3 className="text-2xl font-semibold">Essencial</h3>
            <p className="text-3xl font-bold">R$47</p>
            <ul className="grid gap-2 py-2">
              <li>
                <CheckCircleOutlined className="mx-2" />
                Gestor de Leads e Tarefas Kanban
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Calendário de Eventos Integrado ao Google
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Dashboard de Métricas Avançado
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Suporte padrão
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Integrações básicas
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Até 3 Usuários Cadastrados
              </li>
            </ul>
            <a
              className="inline-flex h-8 items-center rounded-md bg-gray-900 px-4 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
              href="https://pay.kirvano.com/b40f0513-24a3-465c-af89-db669718abbf"
            >
              Assinar
            </a>
          </div>
          <div className="flex flex-col items-center p-4 space-y-1.5 bg-slate-200 rounded-xl border-2 border-blue-500">
            {" "}
            <h3 className="text-2xl font-semibold">Profissional</h3>
            <p className="text-3xl font-bold">R$127</p>
            <ul className="grid gap-2 py-2">
              <li>
                <CheckCircleOutlined className="mx-2" />
                Todas as funcionalidades do Plano Essencial +
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Automações do Funil de Vendas e Tarefas
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Dashboard Personalizável (Em breve)
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Suporte Prioritário
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Integrações Avançadas
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Até 5 Usuários Cadastrados
              </li>
            </ul>
            <a
              className="inline-flex h-8 items-center rounded-md bg-gray-900 px-4 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
              href="https://pay.kirvano.com/60d582c7-1070-4cd6-9927-7de47b1c15b7"
            >
              Assinar
            </a>
          </div>
          <div className="flex flex-col items-center p-4 space-y-1.5 bg-slate-200 rounded-xl">
            <h3 className="text-2xl font-semibold">Elite</h3>
            <p className="text-3xl font-bold">R$347</p>
            <ul className="grid gap-2 py-2">
              <li>
                <CheckCircleOutlined className="mx-2" />
                Todas as funcionalidades do Plano Profissional +
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Acesso a Novas Funcionalidades e Betas
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Relatórios por E-mail (Em breve)
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Suporte Exclusivo
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Integrações Personalizadas
              </li>
              <li>
                <CheckCircleOutlined className="mx-2" />
                Até 10 Usuários Cadastrados
              </li>
            </ul>
            <a
              className="inline-flex h-8 items-center rounded-md bg-gray-900 px-4 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
              href="https://pay.kirvano.com/709e5c48-c5f8-46aa-bee6-c22212b04e53"
            >
              Assinar
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
