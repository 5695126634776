import { supabase } from "@/providers/supabase";
import { useState, useEffect } from "react";

export const useComments  = (deal: number) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    const subscription = supabase
      .channel("dealComments")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "dealComments",
          filter: `deal=eq.${deal}`,
        },
        async (payload) => {
          const { data: userData } = await supabase
            .from("users")
            .select("*")
            .eq("uuid", payload.new.createdBy)
            .single();

          const newComment = {
            ...payload.new,
            createdBy: userData,
          }

          return setData((currentData) => [
            ...currentData,
            newComment,
          ] as any);
        }
      )
      .subscribe();

    const fetchData = async () => {
      try {
        const { data, error } = await supabase
          .from("dealComments")
          .select(
            `
            id,
            comment,
            createdAt,
            updatedAt,
            deal,
            createdBy:users(id, name, avatarUrl)
          `
          )
          .eq("deal", deal)
          .order("createdAt", { ascending: false });

        if (error) throw error;
        setData(data as any);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      subscription.unsubscribe();
    };
  }, [deal]);

  return { data, loading, error };
};
