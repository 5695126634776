import { DeleteButton, FilterDropdown, useTable } from "@refinedev/antd";
import {
  getDefaultFilter,
  useGetIdentity,
  useList,
  useOne,
} from "@refinedev/core";

import {
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  ShopOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Input,
  Row,
  Select,
  Space,
  Table,
  Button,
  notification,
} from "antd";

import cn from "classnames";

import { CustomAvatar, ListTitleButton, Logo, Text } from "@/components";
import { User } from "@/interfaces";

import { RoleTag } from "./components";

import styles from "./settings.module.css";
import { useEffect, useState } from "react";
import { UserForm } from "./add-user-form";
import { LogoPNG } from "@/components/layout/logo-png";
import { supabase } from "@/providers/supabase";
import { useSubscription } from "@/hooks/useSubscription";

export const SettingsPage = () => {
  return (
    <div className="page-container">
      <Space
        size={16}
        style={{
          width: "100%",
          paddingBottom: "24px",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <LogoPNG
          width={100}
          style={{
            maxWidth: "20%",
          }}
        />
      </Space>
      <Row
        gutter={[32, 32]}
        style={{
          marginTop: 32,
        }}
      >
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 32 }}
          xl={{ span: 32 }}
        >
          <UsersTable />
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 8 }}
        >
          {/* <CompanyInfo /> */}
        </Col>
      </Row>
    </div>
  );
};

const roleOptions: {
  label: string;
  value: User["role"];
}[] = [
  {
    label: "Admin",
    value: "ADMIN",
  },
  {
    label: "Sales Intern",
    value: "SALES_INTERN",
  },
  {
    label: "Sales Person",
    value: "SALES_PERSON",
  },
  {
    label: "Sales Manager",
    value: "SALES_MANAGER",
  },
];

async function countUsersForAccount(enterpriseId: string) {
  const { count, error } = await supabase
    .from("users")
    .select("*", { count: "exact" }) // select all fields, count results
    .eq("role", 2) // check for members (role 2)
    .eq("enterprise", enterpriseId);

  if (error) {
    console.error("Error fetching users:", error);
    return 0;
  }

  return count;
}

type PlanLimits = {
  [key: string]: number;
};

const UsersTable = () => {
  const [showUserForm, setShowUserForm] = useState(false);
  const { data, isLoading } = useGetIdentity<User>();
  const [initialized, setInitialized] = useState(false);

  const enterpriseId = data?.userData?.enterprise;

  // Initialize table filters only after the identity is loaded and enterpriseId is available.
  useEffect(() => {
    if (!isLoading && enterpriseId && !initialized) {
      setInitialized(true);
    }
  }, [isLoading, enterpriseId, initialized]);

  // const { data: subscriptionData } = useList({
  //   resource: "subscriptions",
  //   meta: {
  //     fields: ["plan"],
  //   },
  //   filters: [
  //     {
  //       field: "ownerId",
  //       operator: "eq",
  //       value: data?.id,
  //     },
  //   ],
  // });

  const { subscriptionData } = useSubscription();

  const [userHasLimit, setUserHasLimit] = useState(true);

  const toggleUserForm = async () => {
    const userLimits: PlanLimits = {
      Essencial: 3,
      "Essencial Anual": 3,
      Profissional: 5,
      "Profissional Anual": 5,
      Elite: 10,
      "Elite Anual": 10,
      "Elite Trial 30 dias": 10,
      "Elite Trial 7 dias": 10,
      // "plano de teste": 3,
    };

    const planName = subscriptionData?.data?.[0]?.plan;

    // Check if the plan name exists in userLimits
    if (planName && !(planName in userLimits)) {
      console.warn("Plan name not found in userLimits:", planName);
    }

    // Assuming you have a function to count users
    const currentUsersCount = await countUsersForAccount(
      data?.userData?.enterprise!
    );

    // Get the user limit for the current plan
    const currentUserLimit =
      planName && planName in userLimits ? userLimits[planName] : 0;

    const userStillHasLimit = currentUsersCount! < currentUserLimit;
    setUserHasLimit(userStillHasLimit);

    if (currentUsersCount! < currentUserLimit) {
      setShowUserForm(!showUserForm);
    } else {
      notification.open({
        message: "Limite de usuários atingido",
        type: "error",
      });
    }
  };

  const { tableProps, filters } = useTable<User>({
    resource: "users",
    // sorters: {
    //   initial: [
    //     {
    //       field: "role",
    //       order: "asc",
    //     },
    //   ],
    // },

    queryOptions: {
      enabled: initialized,
    },

    filters: {
      initial: [
        {
          field: "enterprise",
          operator: "eq",
          value: data?.userData?.enterprise,
        },
      ],

      permanent: [
        {
          field: "enterprise",
          operator: "eq",
          value: data?.userData?.enterprise,
        },
      ],
      mode: "server",
    },
    meta: {
      fields: ["id", "name", "jobTitle", "role", "avatarUrl", "enterprise"],
    },
  });

  return (
    <>
      {showUserForm && (
        <UserForm
          isVisible={showUserForm}
          onClose={() => setShowUserForm(false)}
        />
      )}

      <Card
        bodyStyle={{ padding: 0 }}
        headStyle={{
          borderBottom: "1px solid #D9D9D9",
          marginBottom: "1px",
        }}
        title={
          <Space size="middle">
            <TeamOutlined />
            <Text>Contatos</Text>
          </Space>
        }
        extra={
          <Space>
            <Text className="tertiary">Total de usuários: </Text>
            <Text strong>
              {tableProps?.pagination !== false && tableProps.pagination?.total}
            </Text>
            {userHasLimit ? (
              <Button onClick={toggleUserForm} className="flex items-center">
                <PlusCircleOutlined />
                Adicionar Usuário
              </Button>
            ) : (
              <Button
                onClick={toggleUserForm}
                className="flex items-center"
                disabled
              >
                <PlusCircleOutlined />
                Adicionar Usuário
              </Button>
            )}
          </Space>
        }
      >
        <Table {...tableProps}>
          <Table.Column<User>
            dataIndex="name"
            title="Name"
            filterIcon={<SearchOutlined />}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input placeholder="Pesquisar nome" />
              </FilterDropdown>
            )}
            render={(_, record) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <CustomAvatar src={record.avatarUrl} name={record.name} />
                  <Text>{record.name}</Text>
                </div>
              );
            }}
          />
          <Table.Column
            dataIndex="jobTitle"
            title="Cargo"
            // defaultFilteredValue={getDefaultFilter(
            //   "jobTitle",
            //   filters,
            //   "contains"
            // )}
            filterIcon={<SearchOutlined />}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input placeholder="Search title" />
              </FilterDropdown>
            )}
          />
          <Table.Column<User>
            dataIndex="role"
            title="Admin"
            // defaultFilteredValue={getDefaultFilter("role", filters, "in")}
            // filterDropdown={(props) => (
            //   <FilterDropdown {...props}>
            //     <Select
            //       style={{ width: "200px" }}
            //       mode="multiple"
            //       placeholder="Selecione a etapa"
            //       options={roleOptions}
            //     />
            //   </FilterDropdown>
            // )}
            render={(_, record) => {
              return <RoleTag role={record.role} />;
            }}
          />
          <Table.Column
            title="Ações"
            render={(_, record: User) => {
              return (
                <Button>
                  <DeleteButton
                    disabled={+record.role === 1}
                    resource="users"
                    recordItemId={record.id}
                    size="small"
                    type="link"
                    successNotification={() => ({
                      key: "user-deleted-by-admin",
                      message: "Usuário deletado com sucesso",
                      description: "Sucesso",
                      type: "success",
                    })}
                    icon={<DeleteOutlined />}
                    className="ant-typography secondary"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {+record.role === 1
                      ? "Você não pode deletar um administrador"
                      : "Deletar"}
                  </DeleteButton>
                </Button>
              );
            }}
          />
        </Table>
      </Card>
    </>
  );
};
