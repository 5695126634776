import { DeleteButton, useForm } from "@refinedev/antd";
import {
  HttpError,
  useGetIdentity,
  useInvalidate,
  useList,
  useParsed,
} from "@refinedev/core";

import { Button, Form, Input, Space, Typography } from "antd";
import dayjs from "dayjs";

import { CustomAvatar, Text } from "@/components";
import { ContactNote, User } from "@/interfaces";
import { findFullName } from "@/utilities/find-full-name";

const ContactCommentListItem = ({ item }: { item: ContactNote }) => {
  const invalidate = useInvalidate();
  const { formProps, setId, id, saveButtonProps } = useForm<
    ContactNote,
    HttpError,
    ContactNote
  >({
    resource: "contactNotes",
    action: "edit",
    queryOptions: {
      enabled: false,
    },
    mutationMode: "optimistic",
    onMutationSuccess: () => {
      setId(undefined);
      invalidate({
        invalidates: ["list"],
        resource: "contactNotes",
      });
    },
    successNotification: () => ({
      key: "contact-note",
      message: "Nota atualizada com sucesso",
      description: "Sucesso",
      type: "success",
    }),
  });
  const { data: me } = useGetIdentity<User>();

  const userName: string = findFullName(me?.identities!);

  const isMe = (me?.id as string) === (item.createdBy as unknown as string);

  return (
    <div style={{ display: "flex", gap: "12px" }}>
      <CustomAvatar
        style={{ flexShrink: 0 }}
        name={userName}
        src={item?.createdBy?.avatarUrl ?? ""}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ fontWeight: 500 }}>{userName}</Text>
          <Text size="xs" style={{ color: "#000000a6" }}>
            {dayjs(item.createdAt).format("MMMM D, YYYY - h:ma")}
          </Text>
        </div>

        {id === item.id ? (
          <Form {...formProps} initialValues={{ note: item.note }}>
            <Form.Item
              name="note"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                  ),
                  message: "Digite uma nota",
                },
              ]}
            >
              <Input.TextArea
                autoFocus
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                }}
              />
            </Form.Item>
          </Form>
        ) : (
          <Typography.Paragraph
            style={{
              background: "#fff",
              borderRadius: "6px",
              padding: "8px",
              marginBottom: 0,
            }}
            ellipsis={{ rows: 3, expandable: true }}
          >
            {item.note}
          </Typography.Paragraph>
        )}

        {isMe && !id && (
          <Space size={16}>
            <Typography.Link
              style={{ color: "inherit", fontSize: "12px" }}
              onClick={() => setId(item.id)}
            >
              Editar
            </Typography.Link>
            <DeleteButton
              resource="contactNotes"
              recordItemId={item.id}
              meta={{ operation: "contactNote" }}
              size="small"
              type="link"
              successNotification={(data, values, resource) => {
                return {
                  key: "contact-delete-note",
                  message: "Nota deletada com sucesso",
                  description: "Sucesso",
                  type: "success",
                };
              }}
              icon={null}
              style={{
                padding: 0,
                fontSize: "12px",
                color: "inherit",
              }}
            />
          </Space>
        )}

        {id && (
          <Space>
            <Button size="small" onClick={() => setId(undefined)}>
              Cancelar
            </Button>
            <Button size="small" type="primary" {...saveButtonProps}>
              Salvar
            </Button>
          </Space>
        )}
      </div>
    </div>
  );
};

export const ContactCommentList = () => {
  const { id } = useParsed();

  const { data } = useList<ContactNote>({
    resource: "contactNotes",
    filters: [{ field: "contact", operator: "eq", value: id }],
    sorters: [{ field: "createdAt", order: "desc" }],
    pagination: {
      mode: "off",
    },
    meta: {
      fields: [
        "id",
        "note",
        "createdAt",
        "contact", // Embedding the contact details

        { createdBy: ["id", "name", "avatarUrl"] },
      ],
    },
  });

  return (
    <Space
      size={16}
      direction="vertical"
      style={{
        backgroundColor: "#FAFAFA",
        padding: "1rem",
        width: "100%",
      }}
    >
      {data?.data?.map((item) => (
        <ContactCommentListItem key={item.id} item={item} />
      ))}
    </Space>
  );
};
