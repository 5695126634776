import { EditButton } from "@refinedev/antd";
import { useNavigation, useResource, useShow } from "@refinedev/core";

import {
  CalendarOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  FlagOutlined,
  InfoCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Skeleton, Space, Tag } from "antd";
import dayjs from "dayjs";

import { Text, UserTag } from "@/components";
import { Event } from "@/interfaces";

export const CalendarShowPage: React.FC = () => {
  const { id } = useResource();
  const { list } = useNavigation();

  const { queryResult } = useShow<Event>({
    id,
    resource: "eventDetails",
    meta: {
      fields: [
        "id",
        "title",
        "description",
        "startDate",
        "endDate",
        "color",
        "createdAt",
        "participantDetails",
      ],
    },
  });

  const { data, isLoading, isError, error } = queryResult;

  if (isError) {
    console.error("Error fetching event", error);
    return null;
  }

  const { description, startDate, endDate, categoryTitle } = data?.data ?? {};

  const utcStartDate = startDate
    ? dayjs(startDate).tz("America/Sao_Paulo")
    : null;
  const utcEndDate = endDate ? dayjs(endDate).tz("America/Sao_Paulo") : null;

  let allDay = false;

  // Ensure both dates are not null before performing the diff operation
  if (utcStartDate && utcEndDate) {
    // check if more than 23 hours
    allDay = utcEndDate.diff(utcStartDate, "day") >= 1;
  }

  const handleOnClose = () => {
    list("events");
  };

  return (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "8px" }}>
            <div
              style={{
                backgroundColor: data?.data.color,
                flexShrink: 0,
                borderRadius: "50%",
                width: "10px",
                height: "10px",
                marginTop: "8px",
              }}
            />
            <Text strong size="md">
              {data?.data.title}
            </Text>
          </div>
          <div style={{ display: "flex", gap: "4px" }}>
            <EditButton icon={<EditOutlined />} hideText type="text" />
            <Button
              icon={<CloseOutlined />}
              type="text"
              onClick={handleOnClose}
            />
          </div>
        </div>
      }
      closeIcon={false}
      open
      onClose={handleOnClose}
      width={378}
    >
      {isLoading ? (
        <Skeleton
          loading={isLoading}
          active
          avatar
          paragraph={{
            rows: 3,
          }}
          style={{
            padding: 0,
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          {allDay ? (
            <div>
              <CalendarOutlined style={{ marginRight: ".5rem" }} />
              <Text>
                {/* {`${dayjs(utcStartDate).format("MMMM D")} - ${dayjs(
                utcEndDate
              ).format("MMMM D")}`} */}

                {dayjs(utcStartDate).subtract(3, "hours").fromNow()}
              </Text>
              <Tag style={{ marginLeft: ".5rem" }} color="blue">
                O dia todo
              </Tag>
            </div>
          ) : (
            <>
              <div>
                <CalendarOutlined style={{ marginRight: ".5rem" }} />
                <Text>
                  {dayjs(utcStartDate).subtract(3, "hours").fromNow()}
                </Text>
              </div>
              <div>
                <ClockCircleOutlined style={{ marginRight: ".5rem" }} />
                <Text>
                  {`${dayjs(utcStartDate)
                    .subtract(3, "hours")
                    .format("HH:mm")} - ${dayjs(utcEndDate)
                    .subtract(3, "hours")
                    .format("HH:mm")}`}
                </Text>
              </div>
            </>
          )}
          {categoryTitle && (
            <div>
              <FlagOutlined style={{ marginRight: ".5rem" }} />
              <Text>{categoryTitle}</Text>
            </div>
          )}

          <div style={{ display: "flex", alignItems: "start" }}>
            <InfoCircleOutlined
              style={{
                marginRight: ".5rem",
                marginTop: "0.32rem",
              }}
            />
            <Text>{description}</Text>
          </div>
        </div>
      )}
    </Drawer>
  );
};
