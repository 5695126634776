import { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Modal, notification } from "antd";
import {
  UserOutlined,
  MailOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  HttpError,
  useCreateMany,
  useGetIdentity,
  useInvalidate,
} from "@refinedev/core";
import { useModalForm } from "@refinedev/antd";
import { Company, Role, User, UserCreateInput } from "@/interfaces";

import { useLocation } from "react-router-dom";
import { supabase } from "@/providers/supabase";
import { useSubscription } from "@/hooks/useSubscription";

type UserFormValues = {
  users: UserCreateInput[] & {
    role: number;
  };
};

type UserFormProps = {
  isVisible: boolean;
  onClose: () => void;
};

type FormValues = {
  contacts?: {
    name?: string;
    email?: string;
  }[];
};

const USER_MEMBER = 2;

export const UserForm = ({ isVisible, onClose }: UserFormProps) => {
  const [form] = Form.useForm<UserFormValues>();
  const users = Form.useWatch("users", form);
  const { pathname } = useLocation();

  const { usersLimit, subscriptionData, planName } = useSubscription();

  const invalidate = useInvalidate();

  const { data: identityData } = useGetIdentity<User>();

  const [loading, setLoading] = useState(false);

  const { mutateAsync } = useCreateMany<User, HttpError, UserCreateInput>();

  const { formProps, formLoading } = useModalForm<
    Company,
    HttpError,
    FormValues
  >({
    action: "create",
    defaultVisible: true,
    resource: "users",
    redirect: false,
    warnWhenUnsavedChanges: false,
    mutationMode: "pessimistic",
    meta: {
      fields: ["id", { users: ["id"] }],
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      users: [{ name: "", email: "" }],
    });
  }, [form]);

  const handleOnFinish = async (args: UserFormValues) => {
    setLoading(true);
    try {
      await form.validateFields();

      const users = args.users.map((user) => ({
        ...user,
        role: USER_MEMBER as unknown as Role,
        createdBy: identityData?.id,
        enterprise: identityData?.userData?.enterprise,
        createdAt: new Date(),
        updatedAt: new Date(),
      }));

      const promises = users.map(async (user) => {
        const password = "SuperSenha@123";

        const signUpResult = await fetch(
          `${import.meta.env.VITE_TRIAL_URL}/api/addUserToSupabase`,
          {
            method: "POST",
            body: JSON.stringify({
              email: user.email,
              password,
            }),
          }
        ).then((res) => res.json());

        console.log({ signUpResult });

        if (signUpResult.error) throw signUpResult.error;

        const userInsertResult = await supabase
          .from("users")
          .insert([
            {
              ...user,
              role: USER_MEMBER,
              // subscription: planName,
              userMetadata: {
                planName,
              },
              createdBy: identityData?.id,
              enterprise: identityData?.userData?.enterprise,
              createdAt: new Date(),
              updatedAt: new Date(),
              uuid: signUpResult.data.user?.id,
            },
          ])
          .single();

        if (userInsertResult.error) throw userInsertResult.error;

        const resetPasswordResult = await supabase.auth.resetPasswordForEmail(
          user.email,
          {
            redirectTo: `${window.location.origin}/update-password`,
          }
        );

        if (resetPasswordResult.error) throw resetPasswordResult.error;

        return { userInsertResult, resetPasswordResult };
      });

      const results = await Promise.allSettled(promises);

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          notification.open({
            type: "success",
            message: "Sucesso",
            description: `Usuário ${users[index].name} criado e e-mail enviado com sucesso!`,
          });
        } else {
          console.error(
            `Error processing user ${users[index].name}:`,
            result.reason
          );
        }
      });

      await invalidate({
        resource: "users",
        invalidates: ["list", "many"], // Adjust based on what you need
      });

      setLoading(false);

      form.resetFields();
      onClose();
      notification.success({
        message: "Usuário adicionado com sucesso",
      });
    } catch (err) {
      console.error("Error during form processing:", err);
      notification.error({
        message: "Erro",
        description:
          "Houve um erro ao processar o formulário. Por favor, verifique os logs para mais detalhes.",
      });
    }
  };

  const hasUsers = users?.length > 0;

  const isHaveOverModal = pathname === "/users/create";

  return (
    <Modal
      open={isVisible}
      title="Criar usuário"
      style={{ display: isHaveOverModal ? "none" : "inherit" }}
      onCancel={onClose}
      okText="Convidar usuário(s)"
      confirmLoading={loading}
      cancelText="Cancelar"
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{}}
      width={560}
    >
      <Form {...(formProps as any)} form={form} onFinish={handleOnFinish}>
        <Form.List name="users">
          {(fields, { add, remove }) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start ",
                  flexDirection: "column",
                  gap: "16px",
                  padding: "4px 16px",
                }}
              >
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <Row
                      key={key}
                      gutter={12}
                      align="top"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Col span={11}>
                        <Form.Item
                          {...restField}
                          style={{
                            marginBottom: 0,
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Por favor, digite o nome de usuário",
                            },
                          ]}
                          name={[name, "name"]}
                        >
                          <Input
                            addonBefore={<UserOutlined />}
                            placeholder="Nome do usuário"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          required
                          style={{
                            marginBottom: 0,
                          }}
                          rules={[
                            {
                              required: true,
                              message:
                                "Insira o e-mail do usuário que quer convidar",
                            },
                            {
                              type: "email",
                              message: "E-mail inválido",
                            },
                          ]}
                          name={[name, "email"]}
                        >
                          <Input
                            addonBefore={<MailOutlined />}
                            placeholder="E-mail do usuário"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  type="link"
                  icon={<PlusCircleOutlined />}
                  className=""
                  onClick={() => add()}
                  disabled={usersLimit <= users?.length}
                  style={{
                    marginBottom: hasUsers ? 16 : 0,
                  }}
                >
                  Adicionar Novo Usuário
                </Button>
              </div>
            );
          }}
        </Form.List>
        {hasUsers && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
              padding: "16px",
              borderTop: "1px solid #D9D9D9",
            }}
          ></div>
        )}
      </Form>
    </Modal>
  );
};
