import React, { useEffect } from "react";

import { AuthPage } from "@refinedev/antd";

import { Title } from "@/components";
import { useCookies } from "react-cookie";
import { supabase } from "@/providers/supabase";
import { useNavigate } from "react-router-dom";

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [cookies, _, removeCookie] = useCookies(["token"]);

  async function validateSession() {
    const { data, error } = await supabase.auth.setSession({
      access_token: cookies.token,
      refresh_token: cookies.token,
    });

    removeCookie("token");

    if (data.session?.access_token && !error) {
      navigate("/");
    }
  }

  useEffect(() => {
    if (cookies.token) {
      validateSession();
    }
  }, []);

  return (
    <AuthPage
      type="login"
      contentProps={{
        className: "auth-page",
        title: (
          <h3 className="text-blue-500 text-center text-2xl">
            Entre na sua conta
          </h3>
        ),
      }}
      title={<Title collapsed={false} />}
      registerLink={false}
    />
  );
};
