import * as AntdIcons from "@ant-design/icons";
import { Button } from "antd";
import { ActivityDescription } from "./components/ActivityDescription";
import { useState } from "react";
import dayjs from "dayjs";
import { useGetIdentity, useList, useNavigation } from "@refinedev/core";
import { User } from "@/interfaces";
import { ActivityModal } from "./components/ActivityModal";
import { DeleteButton, EditButton } from "@refinedev/antd";

interface DealActivitiesProps {
  dealId: number | null;
}

export function DealActivities({ dealId }: DealActivitiesProps) {
  const { list } = useNavigation();
  const { data } = useGetIdentity<User>();
  const [isOpenActivityForm, setIsOpenActivityForm] = useState(false);
  const [activityToEdit, setActivityToEdit] = useState<any>(null);

  const { data: users } = useList<User>({
    resource: "users",
    pagination: {
      mode: "off",
    },
    sorters: [
      {
        field: "role",
        order: "asc",
      },
    ],
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    meta: {
      fields: ["id", "title", "name", "enterprise"],
    },
  });

  const { data: dealActivities } = useList<any>({
    resource: "dealActivities",
    pagination: {
      mode: "off",
    },
    sorters: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
      {
        field: "deal",
        operator: "eq",
        value: dealId,
      },
    ],
    meta: {
      fields: [
        "id",
        "title",
        "description",
        "endDate",
        "icon",
        "ownersIds",
        "startDate",
      ],
    },
  });

  function getActivityOwner(ownersIds: number[]) {
    let owners = ``;

    ownersIds.forEach((ownerId) => {
      const currentOwner = users?.data?.find(
        (user) => Number(user.id) === ownerId
      );

      owners += `${currentOwner?.name}`;
      ownersIds.length > 1 && (owners += ",");
    });

    return owners;
  }

  return (
    <>
      <div className="space-y-5 max-h-96 overflow-auto">
        {dealActivities?.data?.map((dealActivity) => {
          // @ts-ignore
          const Icon = AntdIcons[dealActivity?.icon];
          return (
            <div key={dealActivity.id} className="py-2 px-4 border rounded-md">
              <div>
                <div className="flex items-center justify-between w-full">
                  <p className="text-lg font-medium flex gap-2 items-center">
                    {dealActivity?.icon && <Icon />}
                    {dealActivity.title}
                  </p>

                  <div className="flex items-center gap-2">
                    <EditButton
                      onClick={() => setActivityToEdit(dealActivity)}
                      hideText
                      recordItemId={dealActivity.id}
                      size="small"
                    />
                    <DeleteButton
                      type="link"
                      onSuccess={() => {
                        list("dealActivities", "replace");
                      }}
                      resource="dealActivities"
                      hideText
                      recordItemId={dealActivity.id}
                      size="small"
                    />
                  </div>
                </div>

                <div className="mt-3 space-y-2">
                  <p>
                    <AntdIcons.CalendarOutlined className="mr-1.5 text-sm" />
                    Data:{" "}
                    <span className="text-gray-500">
                      {dayjs(dealActivity.startDate)
                        .subtract(3, "hour")
                        .format("D MMMM, YYYY - HH:mm")}
                    </span>
                  </p>
                  <p>
                    <AntdIcons.UserOutlined className="mr-1.5 text-sm" />
                    Responsável:{" "}
                    <span className="text-gray-500">
                      {getActivityOwner(dealActivity?.ownersIds)}
                    </span>
                  </p>
                </div>
                {dealActivity.description && (
                  <ActivityDescription description={dealActivity.description} />
                )}
              </div>
            </div>
          );
        })}
      </div>

      <Button
        className="w-full mt-5"
        onClick={() => {
          setActivityToEdit(null);
          setIsOpenActivityForm(true);
        }}
      >
        Criar Atividade
      </Button>

      {Boolean(isOpenActivityForm || activityToEdit) && (
        <ActivityModal
          dealId={dealId ?? 0}
          isOpen={Boolean(isOpenActivityForm || activityToEdit)}
          onClose={() => {
            setIsOpenActivityForm(false);
            setActivityToEdit(null);
          }}
          users={users?.data ?? []}
          activityToEdit={activityToEdit}
        />
      )}
    </>
  );
}
