import React from "react";

import { useNavigation } from "@refinedev/core";

import { Badge } from "antd";
import dayjs from "dayjs";

import type { Event } from "@/interfaces";

import { Text } from "../../../text";

import styles from "../index.module.css";

type CalendarUpcomingEventProps = {
  item: Event;
};

export const CalendarUpcomingEvent: React.FC<CalendarUpcomingEventProps> = ({
  item,
}) => {
  const { show } = useNavigation();
  const { id, title, startDate, endDate, color } = item;

  // Adjust dates by subtracting 3 hours
  const adjustedStartDate = dayjs.utc(startDate).subtract(3, "hour");
  const adjustedEndDate = dayjs.utc(endDate).subtract(3, "hour");

  const isToday = adjustedStartDate.isSame(dayjs.utc(), "day");
  const isTomorrow = adjustedStartDate.isSame(dayjs.utc().add(1, "day"), "day");
  const isAllDayEvent =
    adjustedStartDate.startOf("day").isSame(adjustedStartDate) &&
    adjustedEndDate.endOf("day").isSame(adjustedEndDate);

  const renderDate = () => {
    if (isToday) {
      return `Hoje`;
    }

    if (isTomorrow) {
      return `Amanhã`;
    }

    return adjustedStartDate.format("MMM DD");
  };

  const renderTime = () => {
    if (isAllDayEvent) {
      return "O dia todo";
    }

    return `${adjustedStartDate.format("HH:mm")} - ${adjustedEndDate.format(
      "HH:mm"
    )}`;
  };

  return (
    <div
      onClick={() => {
        show("events", id);
      }}
      key={id}
      className={styles.item}
    >
      <div className={styles.date}>
        <Badge color={color} className={styles.badge} />
        <Text size="xs">{`${renderDate()}, ${renderTime()}`}</Text>
      </div>
      <Text ellipsis={{ tooltip: true }} strong className={styles.title}>
        {title}
      </Text>
    </div>
  );
};
