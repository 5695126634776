import CalendarLogo from "../assets/calendar.png";
import MakeLogo from "../assets/make.png";
import PlugaLogo from "../assets/pluga.jpeg";
import WebhookLogo from "../assets/webhook.png";
import ZapierLogo from "../assets/zapier.jpeg";

export const INTEGRATIONS = [
  {
    name: "Agenda",
    description: "Integre seu calendário aqui e agende entrevistas.",
    image: CalendarLogo,
    url: "/integrations/calendar",
    slug: "calendar",
  },
  {
    name: "Pluga",
    description:
      "Faça integrações entre as ferramentas web que você mais utiliza.",
    image: PlugaLogo,
    url: "/integrations/pluga",
    slug: "pluga",
    instructions:
      "https://pluga.zendesk.com/hc/pt-br/articles/5830661585555-Como-integrar-o-Moskit-CRM-a-outras-ferramentas",
    site: "https://pluga.co/",
  },
  {
    name: "Zapier",
    description:
      "Integre ao seu Fexei CRM as mais de 5.000 ferramentas suportadas.",
    image: ZapierLogo,
    url: "/integrations/zapier",
    slug: "zapier",
    instructions:
      "https://ajuda.moskitcrm.com/pt-BR/articles/3712470-zapier-como-utilizar-com-o-moskit",
    site: "https://zapier.com/",
  },
  {
    name: "Make",
    description:
      "Crie e automatize diversas tarefas com uma poderosa plataforma visual.",
    image: MakeLogo,
    url: "/integrations/make",
    slug: "make",
    instructions: "https://www.make.com/en/integrations/moskitcrm",
    site: "https://www.make.com/en",
  },
  {
    name: "Webhooks",
    description: "Faça integrações personalizadas com o Fexei.",
    image: WebhookLogo,
    isSoon: true,
    url: "/integrations/webhook",
    slug: "webhook",
    instructions: "https://www.make.com/en/integrations/moskitcrm",
    site: "https://www.make.com/en",
  },
];
