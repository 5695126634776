import { Button, Card } from "antd";
import { useParams } from "react-router-dom";
import { IntegrationsCard } from "../integration/components/IntegrationCard";
import { INTEGRATIONS } from "../integration/constants";
import { useMemo } from "react";
import { IntegrationCardButtons } from "./components/IntegrationCardButtons";
import { PlugaDescription } from "./components/PlugaDescription";
import { ZapierDescription } from "./components/ZapierDescription";
import { MakeDescription } from "./components/MakeDescription";
import { WebhookIntegrationDetails } from "./components/WebhookDescription";

export function IntegrationDetails() {
  const params = useParams();

  const currentIntegration = useMemo(() => {
    return INTEGRATIONS.find((integration) => integration.slug === params.name);
  }, [params]);

  console.log({ currentIntegration });

  if (!currentIntegration) {
    return <h1>Nenhuma integração encontrada com esse nome.</h1>;
  }

  function getIntegrationsCardButtons() {
    const actions = {
      calendar: <Button>Integrar Agenda</Button>,
    };

    return (
      actions[params.name as keyof typeof actions] ?? (
        <IntegrationCardButtons
          instructions={currentIntegration?.instructions ?? ""}
          site={currentIntegration?.site ?? ""}
        />
      )
    );
  }

  function renderIntegrationDescription() {
    const description = {
      calendar: <Button>Integrar Agenda</Button>,
      pluga: <PlugaDescription />,
      zapier: <ZapierDescription />,
      make: <MakeDescription />,
      webhook: <WebhookIntegrationDetails />,
    };

    return description[params.name as keyof typeof description];
  }

  return (
    <div className="flex items-start justify-center gap-4">
      <IntegrationsCard
        name={currentIntegration.name}
        image={currentIntegration.image}
        description={currentIntegration.description}
      >
        <div className="flex items-center flex-col justify-center pb-4 mt-4 gap-2 w-full">
          {getIntegrationsCardButtons()}
        </div>
      </IntegrationsCard>

      <Card
        bodyStyle={{ padding: "8px 8px 8px 12px" }}
        size="small"
        className="min-w-[800px] max-w-[800px] border border-[#d8d8d8]"
      >
        {renderIntegrationDescription()}
      </Card>
    </div>
  );
}
