export enum ContactStatusEnum {
    NEW = "NEW",
    CONTACTED = "CONTACTED",
    INTERESTED = "INTERESTED",
    UNQUALIFIED = "UNQUALIFIED",
    QUALIFIED = "QUALIFIED",
    NEGOTIATION = "NEGOTIATION",
    LOST = "LOST",
    WON = "WON",
    CHURNED = "CHURNED",
}
