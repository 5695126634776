import { Space, Tag, Typography } from "antd";
import dayjs from "dayjs";

import { Text } from "@/components";
import { Task } from "@/interfaces";
import { getDateColor } from "@/utilities";

type Props = {
  dueData?: Task["dueDate"];
};

export const DueDateHeader = ({ dueData }: Props) => {
  if (dueData) {
    const color = getDateColor({
      date: dueData,
      defaultColor: "processing",
    });
    const getTagText = () => {
      switch (color) {
        case "error":
          return "Vencido";
        case "warning":
          return "Vencendo em breve";
        default:
          return "Pendente";
      }
    };

    return (
      <Space size={[0, 8]}>
        <Tag color={color}>{getTagText()}</Tag>
        <Text>
          {dayjs(dueData).tz("America/Sao_Paulo").format("MMMM D, YYYY - h:ma")}
        </Text>
      </Space>
    );
  }

  return <Typography.Link>Adicionar data de vencimento</Typography.Link>;
};
