import { useEffect } from "react";

import { useModalForm, useSelect } from "@refinedev/antd";
import { HttpError, useGetIdentity, useNavigation } from "@refinedev/core";

import {
  CalendarOutlined,
  DollarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Tabs,
} from "antd";

import { SelectOptionWithAvatar } from "@/components";
import { Company, Deal, User } from "@/interfaces";
import { CommentForm } from "../components";
import { DealCommentForm } from "./components/Comment";
import { DealCommentList } from "./components/CommentList";
import { DealActivities } from "./components/Activity";

type FormValues = {
  stageId?: string | null;
  companyId?: string;
  dealContactId?: string;
  dealOwnerId?: string;
  title?: string;
  contactName?: string;
  contactEmail?: string;
  value?: number;
  pipelineId?: number;
};

export const SalesEditPage = () => {
  const { list } = useNavigation();
  const { data } = useGetIdentity<User>();

  const { formProps, modalProps, close, queryResult } = useModalForm<
    Deal & {
      pipelineId?: number | null;
      companyId?: number | null;
    },
    HttpError,
    FormValues
  >({
    action: "edit",
    defaultVisible: true,
    meta: {
      fields: [
        "title",
        "stageId",
        "value",
        "pipelineId",
        "companyId",
        "dealOwnerId",
        { company: ["id"] },
        { dealContact: ["id"] },
      ],
    },
  });

  const { selectProps: pipelineSelectProps, queryResult: pipelinesQueryResult } = useSelect({
    resource: "pipelines",
    optionLabel: "title",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.pipelineId,
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    meta: {
      fields: ["id", "title", "enterprise"],
    },
    onSearch: (value) =>
      value ? [{ field: "title", operator: "contains", value }] : [],
  });



  const renderPipelineForm = () => (
    <Form.Item
      label="Pipeline"
      name="pipelineId"
      rules={[
        {
          required: true,
          message: "Por favor, selecione uma pipeline de vendas",
        },
      ]}
    >
      <Select {...pipelineSelectProps}
        defaultValue={
          queryResult?.data?.data?.pipelineId
        }

        placeholder="Selecione uma pipeline" />
    </Form.Item>
  );

  const { selectProps, queryResult: companyQueryResult } = useSelect<Company>({
    resource: "companies",
    meta: {
      fields: [
        "name",
        "id",
        "avatarUrl",
        { contacts: [{ nodes: ["name", "id", "avatarUrl"] }] },
      ],
    },
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    optionLabel: "name",
  });

  const { selectProps: stageSelectProps } = useSelect({
    resource: "dealStages",
    meta: {
      fields: ["title", "id"],
    },
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
  });

  const { queryResult: userQueryResult } = useSelect<User>({
    resource: "users",
    meta: {
      fields: ["name", "id", "avatarUrl", "phone"],
    },
    optionLabel: "name",
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
  });

  const company = Form.useWatch("company", formProps.form);
  const companyId = company?.id;

  useEffect(() => {
    const initialCompanyId = queryResult?.data?.data?.company?.id;
    if (initialCompanyId !== companyId) {
      formProps.form?.setFieldValue(["dealContact", "id"], undefined);
    }
  }, [companyId]);

  useEffect(() => {
    const initialCompanyId = queryResult?.data?.data?.company?.id;
    if (initialCompanyId) {
      formProps.form?.setFieldsValue({
        companyId: initialCompanyId,
      });
    }
  }, [queryResult?.data?.data?.company?.id, formProps.form]);

  const renderContactForm = () => {
    if (companyQueryResult.isLoading) {
      return null;
    }

    const selectedCompany = companyQueryResult.data?.data?.find(
      (company) => company.id === companyId
    );

    const hasContact =
      selectedCompany?.contacts?.nodes?.length !== undefined &&
      selectedCompany.contacts.nodes.length > 0;

    if (hasContact) {
      const options = selectedCompany?.contacts?.nodes?.map((contact) => ({
        label: (
          <SelectOptionWithAvatar
            name={contact.name}
            avatarUrl={contact.avatarUrl ?? undefined}
          />
        ),
        value: contact.id,
      }));

      return (
        <Form.Item
          label="Deal contact"
          name={["dealContact", "id"]}
          rules={[{ required: true }]}
        >
          <Select options={options} />
        </Form.Item>
      );
    }

    return null;
  };

  return (
    <Modal
      {...modalProps}
      onCancel={() => {
        close();
        list("deals", "replace");
      }}
      title="Editar negócio"
      width={512}
      styles={{ body: { padding: "0 16px 16px 16px" } }}
      cancelText="Fechar"
    >
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Informações Principais",
            key: "1",
            children: (
              <>
                <Form
                  {...formProps}
                  layout="vertical"
                  preserve={false}
                  onFinish={(values) => {
                    const val = values as unknown as Deal;

                    formProps.onFinish?.({
                      title: val.title,
                      value: val.value || 0,
                      dealOwnerId: val.dealOwnerId,
                      stageId: val.stageId,
                      companyId: val.companyId,
                      dealContactId: val.dealContact?.id,
                      pipelineId: +val.pipelineId,
                    });
                  }}
                >
                  <div className="flex items-center gap-4">
                    <Form.Item
                      label="Nome do negócio"
                      name="title"
                      rules={[{ required: true }]}
                      className="w-[100%]"
                    >
                      <Input placeholder="Digite o nome do negócio" />
                    </Form.Item>
                    <Form.Item
                      label="Empresa"
                      name="companyId"
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Selecione uma empresa"
                        {...selectProps}
                        options={
                          companyQueryResult.data?.data?.map((company) => ({
                            value: company.id,
                            label: (
                              <SelectOptionWithAvatar
                                name={company.name}
                                shape="square"
                                avatarUrl={company.avatarUrl ?? undefined}
                              />
                            ),
                          })) ?? []
                        }
                      />
                    </Form.Item>
                  </div>
                  {renderPipelineForm()}
                  {renderContactForm()}
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item label="Etapa" name="stageId">
                        <Select
                          placeholder="Selecione uma etapa"
                          {...stageSelectProps}
                          showSearch={false}
                          options={stageSelectProps.options?.concat({
                            label: "Backlog",
                            value: null,
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Valor do negócio" name="value">
                        <InputNumber
                          min={0}
                          addonBefore={<DollarOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                          placeholder="0,00"
                          formatter={(value: any) => {
                            // Only format if there's a value
                            if (value) {
                              // Remove previously added formatting to get the raw number
                              const rawValue = value
                                .replace(/\.\d{2}$/, "")
                                .replace(/\D/g, "");
                              // Format the number with Brazilian currency format, without currency symbol
                              return new Intl.NumberFormat("pt-BR", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(parseInt(rawValue, 10) / 100);
                            }
                            return "";
                          }}
                          // Ensure the parser correctly interprets the formatted value into a number for internal use
                          parser={(displayValue) =>
                            displayValue
                              ?.replace(/\.\d{2}$/, "")
                              .replace(/\D/g, "")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Responsável pelo negócio"
                    name="dealOwnerId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Selecione um usuário"
                      {...selectProps}
                      options={
                        userQueryResult.data?.data?.map((user) => ({
                          value: user.id,
                          label: (
                            <SelectOptionWithAvatar
                              name={user.name}
                              avatarUrl={user.avatarUrl ?? undefined}
                            />
                          ),
                        })) ?? []
                      }
                    />
                  </Form.Item>
                </Form>
                <div
                  style={{
                    backgroundColor: "#f0f2f5",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    borderBottom: "1px solid #d9d9d9",
                  }}
                >
                  <DealCommentForm />
                  <DealCommentList />
                </div>
              </>
            ),
          },
          {
            label: "Atividades do deal",
            key: "2",
            children: (
              <DealActivities
                dealId={Number(queryResult?.data?.data?.id) ?? null}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
};