import React, { useCallback } from "react";

import {
  useCreateMany,
  useDelete,
  useGetIdentity,
  useList,
} from "@refinedev/core";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, ModalProps, Popconfirm } from "antd";

import { Text } from "@/components";
import { EventCategory, User } from "@/interfaces";

import styles from "./index.module.css";

type CalendarManageCategoriesProps = {
  saveSuccces?: () => void;
} & ModalProps;

export const CalendarManageCategories: React.FC<
  CalendarManageCategoriesProps
> = ({ saveSuccces: saveSuccess, ...rest }) => {
  const [form] = Form.useForm();
  const { mutate: createManyMutation } = useCreateMany({
    mutationOptions: {
      meta: {
        select: "id, title",
      },
    },
  });

  const { mutate: deleteMutation } = useDelete();
  const { data: identityData } = useGetIdentity<User>();
  const { data } = useList<EventCategory>({
    queryOptions: {
      _optimisticResults: "optimistic",
      enabled: true,
    },
    resource: "eventCategories",
    meta: {
      fields: ["id", "title"],
    },
    filters: [
      {
        field: "createdBy",
        operator: "eq",
        value: identityData?.id,
      },
    ],
  });

  const handleOnFinish = useCallback(
    (formValues: { title: string[] }) => {
      try {
        if (!formValues?.title || formValues.title.length === 0) {
          return saveSuccess?.();
        }

        formValues.title = formValues.title.filter(
          (title) => title !== undefined
        );
        const values = [
          ...formValues.title.map((title) => ({
            title,
            enterprise: identityData?.userData?.enterprise,
            createdBy: identityData?.id,
          })),
        ];

        createManyMutation(
          {
            resource: "eventCategories",
            meta: {
              select: "id, title",
            },
            values,
            successNotification: () => ({
              key: "event-category-create",
              message: "Categoria criada com sucesso",
              description: "Sucesso",
              type: "success",
            }),
          },
          {
            onSuccess: () => {
              saveSuccess?.();
              form.resetFields();
            },
            onError: (error, variables, context) => {
              console.error("Error:", error);
            },
          }
        );
      } catch (err) {
        console.log({ err });
      }
    },
    [identityData]
  );

  return (
    <Modal
      {...rest}
      title="Gerenciar Categorias"
      okText="Salvar"
      destroyOnClose
      style={{ paddingTop: "1rem" }}
      okButtonProps={{
        onClick: () => {
          form.submit();
        },
      }}
    >
      <div className={styles.container}>
        {data?.data.map((category) => (
          <div key={category.id} className={styles.category}>
            <Text className={styles.title}>{category.title}</Text>
            <Popconfirm
              title="Deletar a categoria"
              description="Tem certeza de que quer deletar esta categoria?"
              okText="Sim"
              cancelText="Não"
              onConfirm={() => {
                deleteMutation({
                  resource: "eventCategories",
                  id: category.id,
                  successNotification: () => ({
                    key: "event-category-delete",
                    message: "Categoria deletada com sucesso",
                    description: "Sucesso",
                    type: "success",
                  }),
                });
              }}
            >
              <Button
                type="text"
                icon={
                  <DeleteOutlined
                    className="tertiary"
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
              />
            </Popconfirm>
          </div>
        ))}

        <Form form={form} onFinish={handleOnFinish}>
          <Form.List name="title">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <div key={field.key} className={styles.category}>
                    <Form.Item required={false} noStyle>
                      <Form.Item {...field} noStyle>
                        <Input
                          className={styles["new-category-input"]}
                          placeholder="Digite o título da categoria"
                          bordered={false}
                        />
                      </Form.Item>
                    </Form.Item>
                    <Button
                      type="text"
                      onClick={() => {
                        remove(field.name);
                      }}
                      icon={
                        <DeleteOutlined
                          className="tertiary"
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                        />
                      }
                    />
                  </div>
                ))}

                <div className={styles.category}>
                  <Button
                    type="link"
                    icon={
                      <PlusOutlined
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      />
                    }
                    onClick={() => {
                      add();
                    }}
                    className={styles["new-category-button"]}
                  >
                    Adicionar categoria
                  </Button>
                </div>
              </>
            )}
          </Form.List>
        </Form>
      </div>
    </Modal>
  );
};
