import Resubscribe from "resubscribe-react-sdk";
import { InstantSearch } from "react-instantsearch";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import { Authenticated, Refine } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import routerProvider, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";

import { ConfigProvider, App as AntdApp, Button } from "antd";

import "./utilities/init-dayjs";

import { resources, themeConfig } from "@/config";
import {
  authProvider,
  // dataProvider,
  indexName,
  liveProvider,
  searchClient,
} from "@/providers";

import { dataProvider } from "@refinedev/supabase";
import { supabase } from "./providers/supabase";

import { Layout } from "./components";
import { AuditLogPage, SettingsPage } from "./routes/administration";
import {
  CalendarCreatePage,
  CalendarEditPage,
  CalendarPageWrapper,
  CalendarShowPage,
} from "./routes/calendar";
import {
  CompanyCreatePage,
  CompanyEditPage,
  CompanyListPage,
} from "./routes/companies";
import {
  ContactCreatePage,
  ContactShowPage,
  ContactsListPage,
} from "./routes/contacts";
import { DashboardPage } from "./routes/dashboard";
import { ForgotPasswordPage } from "./routes/forgot-password";
import { LoginPage } from "./routes/login";
import {
  QuotesCreatePage,
  QuotesEditPage,
  QuotesListPage,
  QuotesShowPage,
} from "./routes/quotes";
import {
  KanbanCreatePage,
  KanbanCreateStage,
  KanbanEditPage,
  KanbanEditStage,
  KanbanPage,
} from "./routes/scrumboard/kanban";
import {
  SalesCreateDetails,
  SalesCreatePage,
  SalesCreateStage,
  SalesEditPage,
  SalesEditStage,
  SalesPage,
} from "./routes/scrumboard/sales";
import { UpdatePasswordPage } from "./routes/update-password";

import "@refinedev/antd/dist/reset.css";
import "./styles/antd.css";
import "./styles/fc.css";
import "./styles/index.css";
import { Integrations } from "./routes/integration";
import { IntegrationDetails } from "./routes/integrationDetails";

import type { I18nProvider } from "@refinedev/core";
import { useTranslation } from "react-i18next";

import ptBR from "antd/lib/locale/pt_BR";
import { useContext, useEffect, useLayoutEffect } from "react";
import { Automations } from "./routes/Automations";

import { useTour } from "@reactour/tour";
import { AuthContext } from "./contexts/auth-context";

import { CookiesProvider } from "react-cookie";
import { TrialPage } from "./components/trial-page";
import { SubscribeNowPage } from "./components/subscribe-now";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { OAuthRedirectHandler } from "./config/oauth-redirect-handler";
import { removeCookie, setCookie } from "./utilities/cookies-manager";
import { Session } from "@supabase/supabase-js";
import { PipelineProvider } from "./contexts/pipeline-context";

const AuthenticatedRoutes = () => {
  const location = useLocation();
  const isPasswordUpdatePage = location.pathname === "/update-password";

  return (
    <Authenticated key="authenticated-auth" fallback={<Outlet />}>
      {isPasswordUpdatePage ? (
        <Outlet />
      ) : (
        <NavigateToResource resource="dashboard" />
      )}
    </Authenticated>
  );
};

const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  // const feedbackIntegration = Sentry.feedbackIntegration();

  // feedbackIntegration.attachTo(document.querySelector("#sentry-feedback"), {
  //   formTitle: "Conte-nos o que aconteceu",
  // });

  const i18nProvider: I18nProvider = {
    translate: (key: string, options?: any) => t(key, options) as string,
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { isFirstLogin } = useContext(AuthContext);
  const { setIsOpen } = useTour();

  const startTour = () => {
    if (location.pathname === "/dashboard" && isFirstLogin) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const updatePlaceholder = () => {
      const input = document.querySelector(
        'input[aria-controls="kbar-listbox"]'
      ) as HTMLInputElement | null;

      if (input && input.placeholder !== "Pesquisar...") {
        input.setAttribute("placeholder", "Pesquisar...");
      }
    };

    updatePlaceholder();

    const interval = setInterval(updatePlaceholder, 100);

    return () => clearInterval(interval);
  }, []);

  useLayoutEffect(() => {
    const timer = setTimeout(startTour, 1000); // Adjust delay as needed
    return () => clearTimeout(timer);
  }, [isFirstLogin, setIsOpen, location]);

  return (
    <ConfigProvider locale={ptBR}>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <BrowserRouter>
          <ConfigProvider theme={themeConfig}>
            <CookiesProvider>
              <PipelineProvider>
                <AntdApp>
                  <DevtoolsProvider>
                    <RefineKbarProvider
                      i18nIsDynamicList
                      options={{
                        animations: {
                          enterMs: 400,
                          exitMs: 400,
                        },
                        enableHistory: true,
                      }}
                      commandBarProps={{
                        placeholder: "Pesquisar...",
                        defaultPlaceholder: "Pesquisar...",
                      }}
                    >
                      <Refine
                        i18nProvider={i18nProvider}
                        authProvider={authProvider}
                        dataProvider={dataProvider(supabase)}
                        liveProvider={liveProvider}
                        routerProvider={routerProvider}
                        resources={resources}
                        notificationProvider={useNotificationProvider}
                        options={{
                          liveMode: "auto",
                          syncWithLocation: true,
                          warnWhenUnsavedChanges: true,
                          projectId: "mPoMw0-7rsmdD-aQseJk",
                        }}
                      >
                        <RefineKbar />
                        <Resubscribe.Component />
                        <Routes>
                          <Route
                            element={
                              <Authenticated
                                key="authenticated-layout"
                                fallback={<CatchAllNavigate to="/login" />}
                              >
                                <Layout>
                                  <Outlet />
                                </Layout>
                              </Authenticated>
                            }
                          >
                            <Route index element={<DashboardPage />} />
                            <Route path="/trial" element={<TrialPage />} />
                            <Route
                              path="/planos"
                              element={<SubscribeNowPage />}
                            />
                            <Route
                              path="/calendar"
                              element={
                                <CalendarPageWrapper>
                                  <Outlet />
                                </CalendarPageWrapper>
                              }
                            >
                              <Route index element={null} />
                              <Route
                                path="show/:id"
                                element={<CalendarShowPage />}
                              />
                              <Route
                                path="edit/:id"
                                element={<CalendarEditPage />}
                              />
                              <Route
                                path="create"
                                element={<CalendarCreatePage />}
                              />
                            </Route>
                            <Route path="/scrumboard" element={<Outlet />}>
                              <Route
                                path="kanban"
                                element={
                                  <KanbanPage>
                                    <Outlet />
                                  </KanbanPage>
                                }
                              >
                                <Route
                                  path="create"
                                  element={<KanbanCreatePage />}
                                />
                                <Route
                                  path="edit/:id"
                                  element={<KanbanEditPage />}
                                />
                                <Route
                                  path="stages/create"
                                  element={<KanbanCreateStage />}
                                />
                                <Route
                                  path="stages/edit/:id"
                                  element={<KanbanEditStage />}
                                />
                              </Route>
                              <Route
                                path="sales"
                                element={
                                  <SalesPage>
                                    <Outlet />
                                  </SalesPage>
                                }
                              >
                                <Route
                                  path="create"
                                  element={
                                    <SalesCreatePage>
                                      <Outlet />
                                    </SalesCreatePage>
                                  }
                                >
                                  <Route
                                    path="company/create"
                                    element={<CompanyCreatePage isOverModal />}
                                  />
                                  <Route
                                    path="contacts/create"
                                    element={
                                      <ContactCreatePage>
                                        <Outlet />
                                      </ContactCreatePage>
                                    }
                                  />
                                </Route>
                                <Route
                                  path="edit/:id"
                                  element={<SalesEditPage />}
                                />
                                <Route
                                  path="stages/create"
                                  element={<SalesCreateStage />}
                                />
                                <Route
                                  path="stages/edit/:id"
                                  element={<SalesEditStage />}
                                />
                                <Route
                                  path="details/edit/:id"
                                  element={<SalesCreateDetails />}
                                />
                              </Route>
                            </Route>
                            <Route
                              path="/companies"
                              element={
                                <CompanyListPage>
                                  <Outlet />
                                </CompanyListPage>
                              }
                            >
                              <Route
                                path="create"
                                element={<CompanyCreatePage />}
                              />
                            </Route>
                            <Route
                              path="/companies/edit/:id"
                              element={<CompanyEditPage />}
                            />
                            <Route
                              path="/contacts"
                              element={
                                <ContactsListPage>
                                  <Outlet />
                                </ContactsListPage>
                              }
                            >
                              <Route index element={null} />
                              <Route
                                path="show/:id"
                                element={<ContactShowPage />}
                              />
                              <Route
                                path="create"
                                element={
                                  <ContactCreatePage>
                                    <Outlet />
                                  </ContactCreatePage>
                                }
                              >
                                <Route
                                  path="company-create"
                                  element={<CompanyCreatePage isOverModal />}
                                />
                              </Route>
                            </Route>
                            <Route
                              path="/quotes"
                              element={
                                <QuotesListPage>
                                  <Outlet />
                                </QuotesListPage>
                              }
                            >
                              <Route
                                path="create"
                                element={
                                  <QuotesCreatePage>
                                    <Outlet />
                                  </QuotesCreatePage>
                                }
                              >
                                <Route
                                  path="company-create"
                                  element={<CompanyCreatePage isOverModal />}
                                />
                              </Route>
                              <Route
                                path="edit/:id"
                                element={
                                  <QuotesEditPage>
                                    <Outlet />
                                  </QuotesEditPage>
                                }
                              >
                                <Route
                                  path="company-create"
                                  element={<CompanyCreatePage isOverModal />}
                                />
                              </Route>
                            </Route>
                            <Route
                              path="/quotes/show/:id"
                              element={<QuotesShowPage />}
                            />
                            <Route
                              path="/integrations"
                              element={<Integrations />}
                            />
                            <Route
                              path="/automations"
                              element={<Automations />}
                            />

                            <Route
                              path="/integrations/:name"
                              element={<IntegrationDetails />}
                            />
                            <Route path="/administration" element={<Outlet />}>
                              <Route
                                path="settings"
                                element={<SettingsPage />}
                              />

                              <Route
                                path="audit-log"
                                element={<AuditLogPage />}
                              />
                            </Route>
                            <Route path="*" element={<ErrorComponent />} />
                          </Route>
                          <Route
                            element={
                              <AuthenticatedRoutes key="authenticated-routes" />
                            }
                          >
                            <Route path="/login" element={<LoginPage />} />
                            {/* <Route path="/register" element={<RegisterPage />} /> */}
                            <Route
                              path="/forgot-password"
                              element={<ForgotPasswordPage />}
                            />
                            <Route
                              path="/update-password"
                              element={<UpdatePasswordPage />}
                            />
                          </Route>
                        </Routes>
                        <UnsavedChangesNotifier />
                        <DocumentTitleHandler
                          handler={({ resource }) =>
                            resource?.meta?.label ??
                            resource?.meta?.label ??
                            resource?.name ??
                            resource?.identifier ??
                            (resource?.name
                              ?.toString()
                              .replace(/_/g, " ")
                              .replace(/\w\S*/g, (w) =>
                                w.replace(/^\w/, (c) => c.toUpperCase())
                              )
                              .replace(/\b\w/g, (c) => c.toUpperCase())
                              .replace(/\s+/g, " ")
                              .trim()
                              .replace(/\s/g, "-") as string) ??
                            "Fexei CRM"
                          }
                        />
                      </Refine>
                    </RefineKbarProvider>
                    <DevtoolsPanel />
                  </DevtoolsProvider>
                </AntdApp>
              </PipelineProvider>
            </CookiesProvider>
          </ConfigProvider>
        </BrowserRouter>
      </InstantSearch>
    </ConfigProvider>
  );
};

export default App;
