export const TRIGGERS = [
  {
    label: "⁠Quando criar um negócio",
    value: "DEAL_CREATED",
    disabled: false,
  },
  {
    label: "⁠Quando entrar na etapa",
    value: "DEAL_IN_STAGE",
    disabled: false,
  },
  {
    label: "⁠Quando sair da etapa",
    value: "DEAL_OUT_STAGE",
    disabled: false,
  },
  {
    label: "⁠Quando ganhar o negócio (EM DESENVOLVIMENTO)",
    value: "DEAL_WON",
    disabled: true,
  },
  {
    label: "⁠Quando perder o negócio (EM DESENVOLVIMENTO)",
    value: "DEAL_LOST",
    disabled: true,
  },
];

export const ACTIONS = [
  {
    label: "⁠Definir um responsável",
    value: "CHANGE_RESPONSIBLE",
    disabled: false,
  },
  {
    label: "⁠Criar nova tarefa",
    value: "CREATE_ACTIVITY",
    disabled: false,
  },
  {
    label: "⁠Enviar notificação",
    value: "NOTIFY",
    disabled: false,
  },
  {
    label: "Mover o lead para um novo funil (EM DESENVOLVIMENTO)",
    value: "MOVE_LEAD",
    disabled: true,
  },
];

// Ação
// •⁠  ⁠Enviar notificação
// 	- Tipo da notificação (multi select)
// 		- Dentro do Fexei
// 		- No e-mail
//  - Descrição
//  - Usuários a serem notificados (multi select)
//
