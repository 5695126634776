import React from "react";

import { List, useTable } from "@refinedev/antd";
import { HttpError, useGetIdentity } from "@refinedev/core";

import { Grid } from "antd";
import debounce from "lodash/debounce";

import { ListTitleButton } from "@/components";
import { Contact, User } from "@/interfaces";

import { TableView } from "./components";
import { HeaderButtons } from "./header-buttons";

type Props = React.PropsWithChildren<{}>;

export const ContactsListPage: React.FC<Props> = ({ children }) => {
  const screens = Grid.useBreakpoint();

  const { data } = useGetIdentity<User>();

  const {
    tableProps,
    searchFormProps,
    filters,
    sorters,
    tableQueryResult,
  } = useTable<Contact, HttpError, { name: string }>({
    pagination: {
      pageSize: 12,
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    filters: {
      permanent: [
        {
          field: "enterprise",
          operator: "eq",
          value: data?.userData?.enterprise,
        },
      ],
    },
    onSearch: (values) => {
      return [
        {
          field: "name",
          operator: "contains",
          value: values.name,
        },
      ];
    },
    meta: {
      fields: [
        "id",
        "name",
        "email",
        "jobTitle",
        "status",
        "avatarUrl",
        "company:name",
        "enterprise"
      ],
    },
  });

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchFormProps?.onFinish?.({
      name: e.target.value,
    });
  };
  const debouncedOnChange = debounce(onSearch, 500);

  return (
    <div className="page-container">
      <List
        breadcrumb={false}
        headerButtons={
          <HeaderButtons
            debouncedOnChange={debouncedOnChange}
            tableQueryResult={tableQueryResult}
            searchFormProps={searchFormProps}
            screens={screens}
          />
        }
        contentProps={{
          style: {
            marginTop: "28px",
          },
        }}
        title={
          <ListTitleButton
            toPath="contacts"
            buttonText="Adicionar novo contato"
          />
        }
      >
        <TableView
          tableProps={tableProps}
          filters={filters}
          sorters={sorters}
        />
        {children}
      </List>
    </div>
  );
};
