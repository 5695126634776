import React, { useState } from "react";

import { useSelect } from "@refinedev/antd";
import {
  useDelete,
  useGetIdentity,
  useNavigation,
  useShow,
  useUpdate,
} from "@refinedev/core";

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import dayjs from "dayjs";

import {
  CustomAvatar,
  SelectOptionWithAvatar,
  SingleElementForm,
  Text,
  TextIcon,
} from "@/components";
import { TimezoneEnum } from "@/enums";
import type { Company, Contact, User } from "@/interfaces";

import { ContactComment, ContactStatus } from "../components";

import styles from "./index.module.css";
import { C } from "@fullcalendar/core/internal-common";

const timezoneOptions = Object.keys(TimezoneEnum).map((key) => ({
  label: TimezoneEnum[key as keyof typeof TimezoneEnum],
  value: TimezoneEnum[key as keyof typeof TimezoneEnum],
}));

export const ContactShowPage: React.FC = () => {
  const [activeForm, setActiveForm] = useState<
    "email" | "companyId" | "jobTitle" | "phone" | "timezone"
  >();
  const { list } = useNavigation();
  const { mutate } = useUpdate<Contact>();
  const { mutate: deleteMutation } = useDelete<Contact>();
  const { queryResult } = useShow<Contact>({
    meta: {
      fields: [
        "id",
        "name",
        "email",
        {
          company: ["id", "name", "avatarUrl"],
        },
        "jobTitle",
        "phone",
        "timezone",
        "stage",
        "status",
        "avatarUrl",
        {
          salesOwner: ["id", "name", "avatarUrl"],
        },
      ],
    },
  });

  const { data: idData } = useGetIdentity<User>();

  const {
    selectProps: companySelectProps,
    queryResult: companySelectQueryResult,
  } = useSelect<Company>({
    resource: "companies",
    meta: {
      fields: ["id", "name", "avatarUrl"],
    },
    optionLabel: "name",
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: idData?.userData?.enterprise,
      },
    ],
  });
  const { selectProps: usersSelectProps, queryResult: usersSelectQueryResult } =
    useSelect<User>({
      resource: "users",
      meta: {
        fields: ["id", "name", "avatarUrl"],
      },
      optionLabel: "name",
      filters: [
        {
          field: "enterprise",
          operator: "eq",
          value: idData?.userData?.enterprise,
        },
      ],
    });

  const closeModal = () => {
    setActiveForm(undefined);

    list("contacts");
  };

  const { data, isLoading, isError } = queryResult;

  if (isError) {
    closeModal();
    return null;
  }

  if (isLoading) {
    return (
      <Drawer
        open
        width={756}
        bodyStyle={{
          background: "#f5f5f5",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </Drawer>
    );
  }

  const {
    id,
    name,
    email,
    jobTitle,
    phone,
    timezone,
    avatarUrl,
    company,
    createdAt,
    salesOwner,
    companyId,
    companyName,
    salesOwnerId,
  } = data?.data ?? {};


  return (
    <Drawer
      open
      onClose={() => closeModal()}
      width={756}
      bodyStyle={{ background: "#f5f5f5", padding: 0 }}
      headerStyle={{ display: "none" }}
    >
      <div className={styles.header}>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={() => closeModal()}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.name}>
          <CustomAvatar
            style={{
              marginRight: "1rem",
              flexShrink: 0,
              fontSize: "40px",
            }}
            size={96}
            src={avatarUrl}
            name={name}
          />
          <Typography.Title
            level={3}
            style={{ padding: 0, margin: 0, width: "100%" }}
            className={styles.title}
            editable={{
              onChange(value) {
                mutate({
                  resource: "contacts",
                  id,
                  values: {
                    name: value,
                  },
                  successNotification: false,
                });
              },
              triggerType: ["text", "icon"],
              icon: <EditOutlined className={styles.titleEditIcon} />,
            }}
          >
            {name}
          </Typography.Title>
        </div>

        <div className={styles.form}>
          <SingleElementForm
            icon={<MailOutlined className="tertiary" />}
            state={
              activeForm && activeForm === "email"
                ? "form"
                : email
                ? "view"
                : "empty"
            }
            itemProps={{
              name: "email",
              label: "Email",
            }}
            view={<Text>{email}</Text>}
            onClick={() => setActiveForm("email")}
            onUpdate={() => setActiveForm(undefined)}
            onCancel={() => setActiveForm(undefined)}
          >
            <Input defaultValue={email} />
          </SingleElementForm>

          <SingleElementForm
            icon={<ShopOutlined className="tertiary" />}
            state={
              activeForm && activeForm === "companyId"
                ? "form"
                : companyId
                ? "view"
                : "empty"
            }
            itemProps={{
              name: "companyId",
              label: "Empresa",
            }}
            view={
              <Space>
                <CustomAvatar src={company.avatarUrl} name={companyName} />
                <Text>{companyName}</Text>
              </Space>
            }
            onClick={() => setActiveForm("companyId")}
            onCancel={() => setActiveForm(undefined)}
            onUpdate={() => {
              setActiveForm(undefined);
            }}
            extra={
              <Form.Item
                name="salesOwnerId"
                label="Responsável"
                labelCol={{
                  style: {
                    marginTop: "0.8rem",
                  },
                }}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue={{
                    label: "",
                    value: salesOwnerId,
                  }}
                  {...usersSelectProps}
                  options={
                    usersSelectQueryResult.data?.data?.map(
                      ({ id, name, avatarUrl }) => ({
                        value: id,
                        label: (
                          <SelectOptionWithAvatar
                            name={name}
                            avatarUrl={avatarUrl ?? undefined}
                          />
                        ),
                      })
                    ) ?? []
                  }
                />
              </Form.Item>
            }
          >
            <Select
              style={{ width: "100%" }}
              defaultValue={{
                label: data.data.company.name,
                value: data.data.company.id,
              }}
              {...companySelectProps}
              options={
                companySelectQueryResult.data?.data?.map(
                  ({ id, name, avatarUrl }) => ({
                    value: id,
                    label: (
                      <SelectOptionWithAvatar
                        name={name}
                        avatarUrl={avatarUrl ?? undefined}
                      />
                    ),
                  })
                ) ?? []
              }
            />
          </SingleElementForm>
          <SingleElementForm
            icon={<IdcardOutlined className="tertiary" />}
            state={
              activeForm && activeForm === "jobTitle"
                ? "form"
                : jobTitle
                ? "view"
                : "empty"
            }
            itemProps={{
              name: "jobTitle",
              label: "Cargo",
            }}
            view={<Text>{jobTitle}</Text>}
            onClick={() => setActiveForm("jobTitle")}
            onUpdate={() => setActiveForm(undefined)}
            onCancel={() => setActiveForm(undefined)}
          >
            <Input defaultValue={jobTitle || ""} />
          </SingleElementForm>
          <SingleElementForm
            icon={<PhoneOutlined className="tertiary" />}
            state={
              activeForm && activeForm === "phone"
                ? "form"
                : phone
                ? "view"
                : "empty"
            }
            itemProps={{
              name: "phone",
              label: "Telefone",
            }}
            view={<Text>{phone}</Text>}
            onClick={() => setActiveForm("phone")}
            onUpdate={() => setActiveForm(undefined)}
            onCancel={() => setActiveForm(undefined)}
          >
            <Input defaultValue={phone || ""} />
          </SingleElementForm>
          <SingleElementForm
            style={{ borderBottom: "none" }}
            icon={<GlobalOutlined className="tertiary" />}
            state={
              activeForm && activeForm === "timezone"
                ? "form"
                : timezone
                ? "view"
                : "empty"
            }
            itemProps={{
              name: "timezone",
              label: "Fuso Horário",
            }}
            view={<Text>{timezone}</Text>}
            onClick={() => setActiveForm("timezone")}
            onUpdate={() => setActiveForm(undefined)}
            onCancel={() => setActiveForm(undefined)}
          >
            <Select
              style={{ width: "100%" }}
              options={timezoneOptions}
              defaultValue={data.data.timezone}
            />
          </SingleElementForm>
        </div>

        {/* <div className={styles.stage}>
          <ContactStatus contact={data.data} />
        </div> */}

        <Card
          title={
            <>
              <TextIcon />
              <Text style={{ marginLeft: ".8rem" }}>Notas</Text>
            </>
          }
          bodyStyle={{
            padding: 0,
          }}
        >
          <ContactComment />
        </Card>

        <div className={styles.actions}>
          <Text className="ant-text tertiary">
            Criado em: {dayjs(createdAt).format("DD/MM/YYYY")}
          </Text>

          <Popconfirm
            title="Deletar o contato"
            description="Tem certeza de que deseja deletar este contato?"
            onConfirm={() => {
              deleteMutation(
                {
                  id,
                  resource: "contacts",
                },
                {
                  onSuccess: () => closeModal(),
                }
              );
            }}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Deletar contato
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Drawer>
  );
};
