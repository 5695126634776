import { ContactCommentForm } from "./comment-form";
import { ContactCommentList } from "./comment-list";

export const ContactComment = () => {
    return (
        <>
            <ContactCommentForm />
            <ContactCommentList />
        </>
    );
};
