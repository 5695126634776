import { supabase } from "../supabase";

export async function isSubscriptionActive(email: string) {
  const { data: subscriptionData, error } = await supabase
    .from("subscriptions")
    .select("status")
    .eq("ownerEmail", email)
    .order("createdAt", { ascending: false })
    .limit(1)
    .single();

  if (error || !subscriptionData) {
    console.error("Error fetching subscription data:", error);
    return false;
  }

  return subscriptionData.status === "ACTIVE";
}
