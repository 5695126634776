import { useLocation, useSearchParams } from "react-router-dom";

import { useModalForm, useSelect } from "@refinedev/antd";
import {
  HttpError,
  useGetIdentity,
  useGetToPath,
  useGo,
} from "@refinedev/core";

import { LeftOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select } from "antd";

import { SelectOptionWithAvatar } from "@/components";
import { Company, User } from "@/interfaces";
import { supabase } from "@/providers/supabase";

type Props = {
  isOverModal?: boolean;
};

type FormValues = {
  createdBy: string | undefined;
  updatedBy: string | undefined;
  name: string;
  salesOwnerId: string;
  enterprise: string | undefined;
  contacts?: {
    name?: string;
    email?: string;
  }[];
};

export const CompanyCreatePage = ({ isOverModal }: Props) => {
  const getToPath = useGetToPath();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const go = useGo();
  const { data: identityData } = useGetIdentity<User>();

  const { formProps, modalProps, close, onFinish } = useModalForm<
    Company,
    HttpError,
    FormValues
  >({
    action: "create",
    defaultVisible: true,
    resource: "companies",
    redirect: false,
    warnWhenUnsavedChanges: !isOverModal,
    mutationMode: "pessimistic",
    meta: {
      fields: ["id", { salesOwner: ["id"] }],
    },
  });

  const { selectProps, queryResult } = useSelect<User>({
    resource: "users",
    optionValue: "id",
    meta: {
      fields: ["name", "id", "avatarUrl"],
    },

    optionLabel: "name",
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: identityData?.userData?.enterprise,
      },
    ],
  });

  return (
    <Modal
      {...modalProps}
      mask={!isOverModal}
      onCancel={() => {
        close();
        go({
          to:
            searchParams.get("to") ??
            getToPath({
              action: "list",
            }) ??
            "",
          query: {
            to: undefined,
          },
          options: {
            keepQuery: true,
          },
          type: "replace",
        });
      }}
      title="Adicionar uma empresa"
      width={512}
      closeIcon={
        <LeftOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      }
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={async (values) => {
          try {
            const modifiedValues = {
              ...values,
              enterprise: identityData?.userData?.enterprise,
              createdBy: identityData?.id,
              updatedBy: identityData?.id,
            };

            const data = await supabase
              .from("companies")
              .insert(modifiedValues)
              .select();

            if (!data) {
              console.error("Company not created");
              return;
            }

            const createdCompany = data?.data?.[0];

            if (!createdCompany) {
              console.error("Company not created");
              return;
            }

            // if ((values.contacts ?? [])?.length > 0) {
            //   await createManyMutateAsync({
            //     resource: "contacts",
            //     values:
            //       values.contacts?.map((contact) => ({
            //         ...contact,
            //         companyId: createdCompany.id,
            //         salesOwnerId: createdCompany.salesOwner.id,
            //         createdBy: identityData?.id,
            //         updatedBy: identityData?.id,
            //         enterprise: identityData?.userData?.enterprise,
            //       })) ?? [],
            //     successNotification: false,
            //   });
            // }

            go({
              to: searchParams.get("to") ?? pathname,
              query: {
                companyId: createdCompany.id,
                to: undefined,
              },
              options: {
                keepQuery: true,
              },
              type: "replace",
            });
          } catch (error) {
            console.error(error);
            Promise.reject(error);
          }
        }}
      >
        <Form.Item
          label="Nome da empresa"
          name="name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Digite o nome da empresa" />
        </Form.Item>
        <Form.Item
          label="Usuário responsável"
          name="salesOwnerId"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Selecione o usuário responsável"
            {...selectProps}
            options={
              queryResult.data?.data?.map((user) => ({
                value: user.id,
                label: (
                  <SelectOptionWithAvatar
                    name={user.name}
                    avatarUrl={user.avatarUrl ?? undefined}
                  />
                ),
              })) ?? []
            }
          />
        </Form.Item>
        {/* <Form.List name="contacts">
          {(fields, { add, remove }) => (
            <Space direction="vertical">
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} gutter={12} align="middle">
                  <Col span={11}>
                    <Form.Item noStyle {...restField} name={[name, "name"]}>
                      <Input
                        addonBefore={<UserOutlined />}
                        placeholder="Nome do contato"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item noStyle name={[name, "email"]}>
                      <Input
                        addonBefore={<MailOutlined />}
                        placeholder="E-mail do contato"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}
              <Typography.Link onClick={() => add()}>
                <PlusCircleOutlined /> Adicionar novos contatos
              </Typography.Link>
            </Space>
          )}
        </Form.List> */}
      </Form>
    </Modal>
  );
};
