import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import "./i18n";

import { TourProvider } from "@reactour/tour";
import { AuthProvider } from "./contexts/auth-context";

// import * as Sentry from "@sentry/react";
// Sentry.init({
//   dsn: "https://726111456583588dc1013f9b0e58930e@o4506884177133568.ingest.us.sentry.io/4506884180410368",
//   beforeSend(event, hint) {
//     // Check if it is an exception, and if so, show the report dialog
//     if (event.exception && event.event_id) {
//       Sentry.showReportDialog({
//         eventId: event.event_id,
//         labelEmail: "Reportar bug",
//         labelClose: "Fechar",
//         labelComments: "Comentaários",
//         labelSubmit: "Reportar",
//         lang: "pt",

//         subtitle: "Envie um bug report para nos ajudar a melhorar o Fexei.",
//         title: "Reportar bug",
//         successMessage: "Feedback enviado com sucesso!",
//         subtitle2: "Envie-nos um feedback",
//       });
//     }
//     return event;
//   },
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", "crm.fexei.com.br"],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const steps = [
  {
    selector: ".dashboard_tour",
    content:
      "Este é o seu painel de controle principal. Aqui você pode visualizar um resumo das atividades e métricas importantes.",
  },
  {
    selector: ".events_tour",
    content: "Acesse o Calendário para gerenciar seus eventos e compromissos.",
  },
  {
    selector: ".ant-menu-submenu-title",
    content:
      "Aqui você pode visualizar e gerenciar suas tarefas e projetos no quadro Scrum.",
  },
  {
    selector: ".contacts_tour",
    content:
      "Nesta seção, você pode gerenciar seus contatos, adicionar novos contatos e editar informações existentes.",
  },
  {
    selector: ".automations_tour",
    content: "Nesta seção você pode gerenciar as automações criadas por você!",
  },
  {
    selector: ".integrations_tour",
    content:
      "Explore as Integrações disponíveis para aumentar a eficiência e a conectividade do seu CRM.",
  },
  {
    selector: ".administration_tour",
    content:
      "Na seção de Administração, você pode gerenciar configurações avançadas e auditar registros.",
  },
];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <TourProvider
        steps={steps}
        // defaultOpen={isOpen}
        position={"right"}
        // showBadge
      >
        <App />
      </TourProvider>
    </AuthProvider>
  </React.StrictMode>
);
