export enum TimezoneEnum {
    "UTC (Coordinated Universal Time)" = "UTC (Coordinated Universal Time)",
    "GMT (Greenwich Mean Time)" = "GMT (Greenwich Mean Time)",
    "EST (Eastern Standard Time)" = "EST (Eastern Standard Time)",
    "CST (Central Standard Time)" = "CST (Central Standard Time)",
    "MST (Mountain Standard Time)" = "MST (Mountain Standard Time)",
    "PST (Pacific Standard Time)" = "PST (Pacific Standard Time)",
    "CET (Central European Time)" = "CET (Central European Time)",
    "IST (Indian Standard Time)" = "IST (Indian Standard Time)",
    "JST (Japan Standard Time)" = "JST (Japan Standard Time)",
    "AEST (Australian Eastern Standard Time)" = "AEST (Australian Eastern Standard Time)",
}
