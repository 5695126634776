export function MakeDescription(){
  return (
    <div className="space-y-4">
      <p>
        O Make é uma poderosa ferramenta de automação que conecta
        seus aplicativos e serviços para seu negócio trabalhar de maneira
        mais inteligente e automatizada.
      </p>

      <p>
        Com planos gratuitos e sem a necessidade de codificação,
        conecte aplicativos e serviços on-line, exatamente como você precisa,
        para automatizar seu trabalho on-line. Com uma interface gráfica fácil de usar,
        você tem a liberdade de automatizar tarefas diárias tediosas ou criar
        integrações complexas com apenas alguns cliques.
      </p>

      <p>
        Defina suas automações para serem executadas instantaneamente ou por
        agendamento, ou assista a execução da automação em tempo real sempre que
        precisar.
      </p>
    </div>
  )
}