
import { useModalForm } from "@refinedev/antd";
import {
  BaseKey,
  useGetIdentity,
  useList
} from "@refinedev/core";

import { Form, InputNumber, Modal } from "antd";
import { User } from "@/interfaces";
import { DollarOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { supabase } from "@/providers/supabase";

interface AddGoalFormProps {
  onClose: () => void;
}

type GoalType = {
  id: number;
  enterprise: number;
  value: number;
};

async function createGoal(payload: GoalType) {
  const { id, ...payloadWithoutId } = payload;
  return await supabase.from("goal").insert([payloadWithoutId]);
}

async function updateGoal({ id, value }: GoalType) {
  return await supabase.from("goal").update({ value }).eq("id", id);
}

export const AddGoalForm = (
  { onClose }: AddGoalFormProps = { onClose: () => {} }
) => {
  const { data } = useGetIdentity<User>();

  const { formProps, modalProps, close } = useModalForm({
    action: "create",
    defaultVisible: true,
    resource: "goal",
  });

  const { data: goalData, refetch } = useList({
    resource: "goal",
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    meta: {
      fields: ["value"],
    },
  });

  const [existingGoalId, setExistingGoalId] = useState<BaseKey | undefined>(
    undefined
  );

  useEffect(() => {
    if (goalData?.data?.length) {
      setExistingGoalId(goalData.data[0].id);
    }
  }, [goalData]);

  const handleFinish = async (values: GoalType) => {
    const payload = {
      ...values,
      id: existingGoalId as any,
      enterprise: Number(data?.userData?.enterprise!),
    };

    const { id, value, enterprise } = payload;

    if (existingGoalId) {
      await updateGoal({ id, value, enterprise });
    } else {
      await createGoal({
        id,
        value,
        enterprise,
      });
    }

    refetch();

    if (onClose) {
      onClose();
    }
    close();
  };

  return (
    <Modal
      {...modalProps}
      title="Defina sua meta"
      width={512}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => handleFinish(values as GoalType)}
      >
        <Form.Item
          label="Valor da meta"
          name="value"
          rules={[{ required: true }]}
        >
          <InputNumber
            min={0}
            defaultValue={
              goalData?.data?.length ? goalData?.data[0].value : undefined
            }
            addonBefore={<DollarOutlined />}
            placeholder="0,00"
            formatter={(value) => {
              // Only format if there's a value
              if (value) {
                // Remove previously added formatting to get the raw number
                const rawValue = value
                  .replace(/\.\d{2}$/, "")
                  .replace(/\D/g, "");
                // Format the number with Brazilian currency format, without currency symbol
                return new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                }).format(parseInt(rawValue, 10) / 100);
              }
              return "";
            }}
            // Ensure the parser correctly interprets the formatted value into a number for internal use
            parser={(value) => value?.replace(/\.\d{2}$/, "").replace(/\D/g, "")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
