import React, { FC, PropsWithChildren, Suspense } from "react";

import { useGetIdentity, useList } from "@refinedev/core";

import { AuditOutlined, ShopOutlined, TeamOutlined } from "@ant-design/icons";
import { AreaConfig } from "@ant-design/plots";
import { Card, Skeleton } from "antd";
import { Company, Contact, Deal, User } from "interfaces/graphql";

import { Text } from "@/components";

import styles from "./index.module.css";

const Area = React.lazy(() => import("@ant-design/plots/es/components/area"));

type Type =
  | "companies"
  | "contacts"
  | "deals"
  | "sales"
  | "conversionRate"
  | "churnRate";

const calculateRates = (deals: Deal[]) => {
  const wonStageId = 1; // ID for won deals
  const lostStageId = 2; // ID for lost deals

  const relevantDeals = deals.filter(
    (deal) => +deal.stageId! === wonStageId || +deal.stageId! === lostStageId
  );
  const totalRelevantDeals = relevantDeals.length;

  const wonDeals = relevantDeals.filter(
    (deal) => +deal.stageId! === wonStageId
  ).length;
  const lostDeals = relevantDeals.filter(
    (deal) => +deal.stageId! === lostStageId
  ).length;

  const conversionRate =
    totalRelevantDeals > 0 ? (wonDeals / totalRelevantDeals) * 100 : 0;
  const lossChurnRate =
    totalRelevantDeals > 0 ? (lostDeals / totalRelevantDeals) * 100 : 0;

  return { conversionRate, lossChurnRate };
};

export const DashboardTotalCountCard: React.FC<{
  resource: Type;
}> = ({ resource }) => {
  const { data: identityData } = useGetIdentity<User>();
  const { data, isLoading, isError, error } = useList<Company | Contact | Deal>(
    {
      resource:
        resource === "conversionRate" || resource === "churnRate"
          ? "deals"
          : resource,
      meta: {
        fields: ["id", "stageId"],
      },
      filters: [
        {
          field: "enterprise",
          operator: "eq",
          value: identityData?.userData?.enterprise,
        },
      ],
    }
  );

  const {
    data: dealsData,
    isLoading: isLoadingDeals,
    isError: isErrorDeals,
    error: errorDeals,
  } = useList<Deal>({
    resource: "deals",
    meta: {
      fields: ["id", "stageId"],
    },
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: identityData?.userData?.enterprise,
      },
    ],
  });

  const { conversionRate, lossChurnRate } = calculateRates(
    (data as any)?.data || []
  );

  if (isError) {
    console.error("Error fetching dashboard data", error);
    return null;
  }

  const displayRate =
    resource === "conversionRate"
      ? conversionRate
      : resource === "churnRate"
      ? lossChurnRate
      : null;

  const { primaryColor, secondaryColor, icon, title } = variants[resource];

  const config: AreaConfig = {
    className: styles.area,
    appendPadding: [1, 0, 0, 0],
    padding: 0,
    syncViewPadding: true,
    data: variants[resource].data,
    autoFit: true,
    tooltip: false,
    animation: false,
    xField: "index",
    yField: "value",
    xAxis: false,
    yAxis: {
      tickCount: 12,
      label: {
        style: {
          fill: "transparent",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "transparent",
          },
        },
      },
    },
    smooth: true,
    areaStyle: () => {
      return {
        fill: `l(270) 0:#fff 0.2:${secondaryColor} 1:${primaryColor}`,
      };
    },
    line: {
      color: primaryColor,
    },
  };

  return (
    <Card
      style={{ height: "96px", padding: 0 }}
      bodyStyle={{
        padding: "8px 8px 8px 12px",
      }}
      size="small"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          whiteSpace: "nowrap",
        }}
      >
        {icon}
        <Text size="md" className="secondary" style={{ marginLeft: "8px" }}>
          {title}
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text
          size="xxxl"
          strong
          style={{
            textAlign: "start",
            marginLeft: "48px",
            fontVariantNumeric: "tabular-nums",
          }}
        >
          {isLoading ? (
            <Skeleton.Button
              style={{
                marginTop: "8px",
                width: "74px",
              }}
            />
          ) : displayRate !== null ? (
            `${displayRate.toFixed(2)}%`
          ) : (
            dealsData?.total
          )}
        </Text>
        <Suspense>
          <Area {...config} />
        </Suspense>
      </div>
    </Card>
  );
};

const IconWrapper: FC<PropsWithChildren<{ color: string }>> = ({
  color,
  children,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        backgroundColor: color,
      }}
    >
      {children}
    </div>
  );
};

const variants: {
  [key in Type]: {
    primaryColor: string;
    secondaryColor?: string;
    icon: React.ReactNode;
    title: string;
    data: { index: string; value: number }[];
  };
} = {
  companies: {
    primaryColor: "#1677FF",
    secondaryColor: "#BAE0FF",
    icon: (
      <IconWrapper color="#E6F4FF">
        <ShopOutlined
          className="md"
          style={{
            color: "#1677FF",
          }}
        />
      </IconWrapper>
    ),
    title: "Negócios Totais",
    data: [
      {
        index: "1",
        value: 3500,
      },
      {
        index: "2",
        value: 2750,
      },
      {
        index: "3",
        value: 5000,
      },
      {
        index: "4",
        value: 4250,
      },
      {
        index: "5",
        value: 5000,
      },
    ],
  },
  conversionRate: {
    primaryColor: "#52C41A",
    secondaryColor: "#D9F7BE",
    icon: (
      <IconWrapper color="#F6FFED">
        <TeamOutlined
          className="md"
          style={{
            color: "#52C41A",
          }}
        />
      </IconWrapper>
    ),
    title: "Taxa de Conversão",
    data: [
      {
        index: "1",
        value: 10000,
      },
      {
        index: "2",
        value: 19500,
      },
      {
        index: "3",
        value: 13000,
      },
      {
        index: "4",
        value: 17000,
      },
      {
        index: "5",
        value: 13000,
      },
      {
        index: "6",
        value: 20000,
      },
    ],
  },
  churnRate: {
    primaryColor: "#FA541C",
    secondaryColor: "#FFD8BF",
    icon: (
      <IconWrapper color="#FFF2E8">
        <AuditOutlined
          className="md"
          style={{
            color: "#FA541C",
          }}
        />
      </IconWrapper>
    ),
    title: "Taxa de perda/churn",
    data: [
      {
        index: "1",
        value: 1000,
      },
      {
        index: "2",
        value: 1300,
      },
      {
        index: "3",
        value: 1200,
      },
      {
        index: "4",
        value: 2000,
      },
      {
        index: "5",
        value: 800,
      },
      {
        index: "6",
        value: 1700,
      },
      {
        index: "7",
        value: 1400,
      },
      {
        index: "8",
        value: 1800,
      },
    ],
  },
  sales: {
    primaryColor: "#FA541C",
    secondaryColor: "#FFD8BF",
    icon: (
      <IconWrapper color="#FFF2E8">
        <AuditOutlined
          className="md"
          style={{
            color: "#FA541C",
          }}
        />
      </IconWrapper>
    ),
    title: "Taxa de perda/churn",
    data: [
      {
        index: "1",
        value: 1000,
      },
      {
        index: "2",
        value: 1300,
      },
      {
        index: "3",
        value: 1200,
      },
      {
        index: "4",
        value: 2000,
      },
      {
        index: "5",
        value: 800,
      },
      {
        index: "6",
        value: 1700,
      },
      {
        index: "7",
        value: 1400,
      },
      {
        index: "8",
        value: 1800,
      },
    ],
  },
  contacts: {
    primaryColor: "",
    secondaryColor: undefined,
    icon: undefined,
    title: "",
    data: [],
  },
  deals: {
    primaryColor: "",
    secondaryColor: undefined,
    icon: undefined,
    title: "",
    data: [],
  },
};
