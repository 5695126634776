import { useState } from "react";

import { useModalForm } from "@refinedev/antd";
import { useList, useNavigation } from "@refinedev/core";

import {
  AlignLeftOutlined,
  ConsoleSqlOutlined,
  FieldTimeOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";

import { Task, User } from "@/interfaces";

import {
  Accordion,
  CheckListForm,
  CommentForm,
  CommentList,
  DescriptionForm,
  DescriptionHeader,
  DueDateForm,
  DueDateHeader,
  ModalFooter,
  StageForm,
  TitleForm,
  UsersForm,
  UsersHeader,
} from "../components";
import dayjs from "dayjs";

export const KanbanEditPage = () => {
  const [activeKey, setActiveKey] = useState<string | undefined>();

  const { list } = useNavigation();
  const { modalProps, close, queryResult } = useModalForm<Task>({
    action: "edit",
    defaultVisible: true,
    meta: {
      fields: [
        "id",
        "title",
        "completed",
        "description",
        "dueDate",
        { stage: ["id", "title"] },
        { users: ["id", "name", "avatarUrl"] },
        { checklist: ["title", "checked"] },
      ],
    },
  });

  const { data: users } = useList<User>({
    resource: "users",
    filters: [
      {
        field: "id",
        operator: "in",
        value: queryResult?.data?.data?.userIds,
      },
    ],
    meta: {
      fields: ["id", "name", "avatarUrl"],
    },
  });

  const { description, completed, stage, dueDate, checklist, title } =
    queryResult?.data?.data ?? {};
  const isLoading = queryResult?.isLoading ?? true;

  console.log(queryResult?.data?.data);

  return (
    <Modal
      {...modalProps}
      className="kanban-update-modal"
      onCancel={() => {
        close();
        list("tasks", "replace");
      }}
      title={<TitleForm initialValues={{ title }} isLoading={isLoading} />}
      width={586}
      footer={<ModalFooter />}
    >
      <StageForm
        initialValues={{ completed: completed ?? false, stage }}
        isLoading={isLoading}
      />
      <Accordion
        accordionKey="description"
        activeKey={activeKey}
        setActive={setActiveKey}
        fallback={<DescriptionHeader description={description} />}
        isLoading={isLoading}
        icon={<AlignLeftOutlined />}
        label="Descrição"
      >
        <DescriptionForm
          initialValues={{ description }}
          cancelForm={() => setActiveKey(undefined)}
        />
      </Accordion>
      <Accordion
        accordionKey="due-date"
        activeKey={activeKey}
        setActive={setActiveKey}
        fallback={<DueDateHeader dueData={dueDate} />}
        isLoading={isLoading}
        icon={<FieldTimeOutlined />}
        label="Data de vencimento"
      >
        <DueDateForm
          initialValues={{
            dueDate: dueDate
              ? dayjs(dueDate).tz("America/Sao_Paulo").format()
              : undefined,
          }}
          cancelForm={() => setActiveKey(undefined)}
        />
      </Accordion>
      <Accordion
        accordionKey="users"
        activeKey={activeKey}
        setActive={setActiveKey}
        fallback={<UsersHeader users={users} />}
        isLoading={isLoading}
        icon={<UsergroupAddOutlined />}
        label="Usuários"
      >
        <UsersForm
          initialValues={{
            userIds: queryResult?.data?.data.userIds as any,
          }}
          cancelForm={() => setActiveKey(undefined)}
        />
      </Accordion>
      <CheckListForm isLoading={isLoading} initialValues={{ checklist }} />

      <div
        style={{
          backgroundColor: "#f0f2f5",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "48px",
          borderBottom: "1px solid #d9d9d9",
        }}
      >
        <CommentForm />
        <CommentList />
      </div>
    </Modal>
  );
};
