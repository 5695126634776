import type { IResourceItem } from "@refinedev/core";

import {
  ApiOutlined,
  CalendarOutlined,
  CrownOutlined,
  DashboardOutlined,
  ProjectOutlined,
  RobotOutlined,
  TeamOutlined,
} from "@ant-design/icons";

export const resources: IResourceItem[] = [
  {
    name: "dashboard",
    list: "/",
    meta: {
      label: "Dashboard",
      icon: (
        <DashboardOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
    },
  },
  {
    name: "events",
    list: "/calendar",
    create: "/calendar/create",
    edit: "/calendar/edit/:id",
    show: "/calendar/show/:id",
    meta: {
      label: "Calendário",
      icon: (
        <CalendarOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
    },
  },
  {
    name: "scrumboard",
    meta: {
      label: "Meus quadros",
      icon: (
        <ProjectOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
    },
  },
  {
    name: "tasks",
    list: "/scrumboard/kanban",
    create: "/scrumboard/kanban/create",
    edit: "/scrumboard/kanban/edit/:id",
    meta: {
      label: "Tarefas",
      parent: "scrumboard",
    },
  },
  {
    name: "taskStages",
    create: "/scrumboard/kanban/stages/create",
    edit: "/scrumboard/kanban/stages/edit/:id",
    list: "/scrumboard/kanban",
    meta: {
      hide: true,
    },
  },
  {
    name: "deals",
    list: "/scrumboard/sales",
    create: "/scrumboard/sales/create",
    edit: "/scrumboard/sales/edit/:id",
    meta: {
      label: "Pipeline de Vendas",
      parent: "scrumboard",
    },
  },
  {
    name: "dealStages",
    create: "/scrumboard/sales/stages/create",
    edit: "/scrumboard/sales/stages/edit/:id",
    list: "/scrumboard/sales",
    meta: {
      hide: true,
    },
  },
  {
    name: "deals",
    identifier: "deals-2",
    edit: "/scrumboard/sales/details/edit/:id",
    list: "/scrumboard/sales",
    meta: {
      hide: true,
    },
  },
  // {
  //     name: "companies",
  //     list: "/companies",
  //     show: "/companies/:id",
  //     create: "/companies/create",
  //     edit: "/companies/edit/:id",
  //     meta: {
  //         label: "Companies",
  //         icon: <ShopOutlined />,
  //     },
  // },
  {
    name: "companies",
    identifier: "sales-companies",
    create: "/scrumboard/sales/create/company/create",
    meta: {
      hide: true,
    },
  },
  {
    name: "contacts",
    list: "/contacts",
    create: "/contacts/create",
    edit: "/contacts/edit/:id",
    show: "/contacts/show/:id",
    meta: {
      label: "Contatos",
      icon: (
        <TeamOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
    },
  },
  {
    name: "automations",
    list: "/automations",
    meta: {
      label: "Automações",
      icon: (
        <RobotOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
    },
  },
  // {
  //     name: "quotes",
  //     list: "/quotes",
  //     create: "/quotes/create",
  //     edit: "/quotes/edit/:id",
  //     show: "/quotes/show/:id",
  //     meta: {
  //         label: "Quotes",
  //         icon: <ContainerOutlined />,
  //     },
  // },
  {
    name: "integrations",
    list: "/integrations/",
    meta: {
      label: "Integrações",
      icon: (
        <ApiOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
    },
  },
  {
    name: "administration",
    list: "/administration/settings",
    meta: {
      label: "Administração",
      icon: (
        <CrownOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
    },
  },
  // {
  //   name: "settings",
  //   list: "/administration/settings",
  //   meta: {
  //     label: "Configurações",
  //     parent: "administration",
  //   },
  // },
  // {
  //   name: "audits",
  //   list: "/administration/audit-log",
  //   meta: {
  //     label: "Auditoria",
  //     parent: "administration",
  //   },
  // },
  // {
  //   name: "sales",
  //   list: "/administration/audit-log",
  //   meta: {
  //     label: "Auditoria",
  //     parent: "administration",
  //   },
  // },
];
