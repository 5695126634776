import React, { lazy, Suspense, useMemo } from "react";

import { useGetIdentity, useList, useNavigation } from "@refinedev/core";

import { DollarOutlined, RightCircleOutlined } from "@ant-design/icons";
import { AreaConfig } from "@ant-design/plots";
import { Button, Card } from "antd";
import dayjs from "dayjs";
import { DealStage, User } from "interfaces/graphql";

import { Text } from "@/components";
import { currencyNumber } from "@/utilities";

const Area = lazy(() => import("@ant-design/plots/es/components/area"));

export const DashboardDealsChart: React.FC<{}> = () => {
  const { list } = useNavigation();
  const {
    data: stagesData,
    isError: isErrorStages,
    error: errorStages,
  } = useList({
    resource: "dealStages",
    filters: [{ field: "title", operator: "in", value: ["WON", "LOST"] }],
    meta: {
      fields: ["title", "id"],
    },
  });

  const { data } = useGetIdentity<User>();

  const {
    data: dealsData,
    isError: isErrorDeals,
    error: errorDeals,
  } = useList({
    resource: "deals",
    filters: stagesData?.data
      ? [
          {
            field: "stageId",
            operator: "in",
            value: stagesData.data.map((stage) => stage.id),
          },
          {
            field: "enterprise",
            operator: "eq",
            value: data?.userData?.enterprise,
          },
        ]
      : [
          {
            field: "enterprise",
            operator: "eq",
            value: data?.userData?.enterprise,
          },
        ],
    meta: {
      fields: ["value", "stageId", "createdAt", "updatedAt"], // Include createdAt and updatedAt
    },
  });

  if (isErrorStages || isErrorDeals) {
    console.error("Error fetching data", errorStages || errorDeals);
    return null;
  }

  const dealData = useMemo(() => {
    if (!stagesData?.data || !dealsData?.data) return [];

    // Group deals by month and year of creation
    const dealsGroupedByDate = dealsData.data.reduce((acc, deal) => {
      const monthYear = dayjs(deal.createdAt).format("MMM YYYY");
      const stageTitle = stagesData.data.find(
        (stage) => stage.id === deal.stageId
      )?.title;
      const state = stageTitle === "WON" ? "Ganho" : "Perdido";

      if (!acc[monthYear]) acc[monthYear] = {};
      if (!acc[monthYear][state]) acc[monthYear][state] = 0;

      acc[monthYear][state] += deal.value;
      return acc;
    }, {});

    // Convert grouped data to array suitable for chart
    const processedData = Object.entries(dealsGroupedByDate).flatMap(
      ([date, values]) => {
        return Object.entries(values).map(([state, value]) => ({
          timeText: date,
          state,
          value,
        }));
      }
    );

    // Sort data by date
    return processedData.sort((a, b) =>
      dayjs(a.timeText).isAfter(dayjs(b.timeText)) ? 1 : -1
    );
  }, [stagesData, dealsData]);

  const config: AreaConfig = {
    isStack: false,
    data: dealData,
    xField: "timeText",
    yField: "value",

    seriesField: "state",
    animation: true,
    startOnZero: false,
    smooth: true,
    legend: {
      offsetY: -6,
    },
    yAxis: {
      tickCount: 4,
      label: {
        formatter: (v) => {
          return currencyNumber(+v);
        },
      },
    },
    tooltip: {
      formatter: (data) => {
        return {
          name: data.state,
          value: currencyNumber(data?.value),
        };
      },
    },
    areaStyle: (datum) => {
      const won = "l(270) 0:#ffffff 0.5:#b7eb8f 1:#52c41a";
      const lost = "l(270) 0:#ffffff 0.5:#f3b7c2 1:#ff4d4f";
      return { fill: datum.state === "Ganho" ? won : lost };
    },
    color: (datum) => {
      return datum.state === "Ganho" ? "#52C41A" : "#F5222D";
    },
  };

  return (
    <Card
      style={{ height: "100%" }}
      headStyle={{ padding: "8px 16px" }}
      bodyStyle={{ padding: "24px 24px 0px 24px" }}
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <DollarOutlined />
          <Text size="sm" style={{ marginLeft: ".5rem" }}>
            Volume de Vendas
          </Text>
        </div>
      }
      extra={
        <Button onClick={() => list("deals")} icon={<RightCircleOutlined />}>
          Ver pipeline de vendas
        </Button>
      }
    >
      <Suspense>
        <Area {...config} height={325} />
      </Suspense>
    </Card>
  );
};
