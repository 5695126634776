export const INITIAL_ACTION_TASK_INFO = {
  stage: null,
  description: '',
  title: '',
  date: '',
  users: [],
}

export const INITIAL_ACTION_NOTIFY = {
  type: [],
  description: '',
  users: [],
}

export const INITIAL_AUTOMATION_INFO = {
  title: '',
  objective: '',
  active: true,
}