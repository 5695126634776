import { AuthProvider } from "@refinedev/core";

import { notification } from "antd";
import { isSubscriptionActive } from "./lib/is-subscription-active";
import { supabase } from "./supabase";

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error("Login error:", error);
        return {
          success: false,
          error,
        };
      }

      if (data?.user) {
        // Check subscription status after successful authentication
        const subscriptionActive = await isSubscriptionActive(email);
        if (!subscriptionActive) {
          return {
            success: false,
            error: {
              message: "Sua assinatura não está ativa.",
              name: "Subscription not active",
            },
          };
        }

        // Fetch user data after verifying subscription
        const { data: userData, error: fetchError } =
          await supabase.auth.getUser(data.session.access_token);
        if (fetchError) {
          console.error("Error fetching user data:", fetchError);
          return {
            success: false,
            error: fetchError,
          };
        }

        // Determine if it's the first sign-in
        let isFirstSignIn = false;
        if (userData) {
          const createdAt = new Date(userData.user.created_at).getTime();
          const lastSignInAt = new Date(
            userData.user.last_sign_in_at as string
          )?.getTime();
          const timeThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds
          isFirstSignIn = lastSignInAt - createdAt < timeThreshold;
        }

        return {
          success: true,
          isFirstSignIn,
        };
      }
    } catch (error: any) {
      console.error("Error during login process:", error);
      return {
        success: false,
        error,
      };
    }

    // Fallback error message
    return {
      success: false,
      error: {
        message: "Login failed",
        name: "Invalid email or password",
      },
    };
  },

  register: async ({ email, password }) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        const appMetadata = {}; // your logic to set app metadata
        const userMetadata = {}; // your logic to set user metadata

        const { data: createdUser, error: updateError } = await supabase
          .from("users")
          .upsert({
            uuid: data.user?.id, //UUID assigned by Supabase
            email: data?.user?.email,
            appMetadata,
            userMetadata,
            createdAt: data.user?.created_at,
            updatedAt: data.user?.updated_at,
            role: 1,
            createdBy: 1,
            updatedBy: 1,
            avatarUrl: data.user?.user_metadata?.avatar_url,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            name: data.user?.email?.split("@")[0],
            phone: "",
          });

        if (updateError) {
          console.error("Error updating user:", updateError);
          return {
            success: false,
            error: updateError,
          };
        }

        return {
          success: true,
          data: createdUser,
          redirectTo: "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: false,
      error: {
        message: "Register failed",
        name: "Invalid email or password",
      },
    };
  },
  forgotPassword: async ({ email }) => {
    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/update-password`,
      });

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        notification.open({
          type: "success",
          message: "Sucesso",
          description:
            "Por favor, verifique seu e-mail para obter um link para redefinir sua senha. Se não aparecer em alguns minutos, verifique sua pasta de spam.",
        });
        return {
          success: true,
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: false,
      error: {
        message: "Forgot password failed",
        name: "Invalid email",
      },
    };
  },
  updatePassword: async ({ password }) => {
    try {
      const { data, error } = await supabase.auth.updateUser({
        password,
      });

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        return {
          success: true,
          redirectTo: "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: false,
      error: {
        message: "Update password failed",
        name: "Invalid password",
      },
    };
  },
  logout: async () => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      redirectTo: "/",
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  check: async () => {
    try {
      const { data } = await supabase.auth.getSession();
      const { session } = data;

      console.log({ session });

      if (!session) {
        return {
          authenticated: false,
          error: {
            message: "Check failed",
            name: "Session not found",
          },
          logout: true,
          redirectTo: "/login",
        };
      }
    } catch (error: any) {
      console.log("Error checking session:", error);
      return {
        authenticated: false,
        error: error || {
          message: "Check failed",
          name: "Session not found",
        },
        logout: true,
        redirectTo: "/login",
      };
    }

    return {
      authenticated: true,
    };
  },
  getPermissions: async () => {
    const user = await supabase.auth.getUser();

    if (user) {
      return user.data.user?.role;
    }

    return null;
  },
  getIdentity: async () => {
    const { data: authData } = await supabase.auth.getUser();

    if (!authData?.user?.id) {
      return null;
    }

    const userId = authData?.user?.id;

    const { data: userData, error } = await supabase
      .from("users")
      .select()
      .eq("uuid", userId)
      .single();

    if (error) {
      console.error("Error fetching user:", error);
      return null;
    }

    if (userData) {
      return {
        ...authData.user,
        name: authData.user.email,
        userData,
      };
    } else {
      return null;
    }
  },
};
