export const currencyNumber = (
    value: number,
    options?: Intl.NumberFormatOptions,
) => {
    if (
        typeof Intl == "object" &&
        Intl &&
        typeof Intl.NumberFormat == "function"
    ) {
        return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            ...options,
        }).format(value);
    }

    return value.toString();
};
