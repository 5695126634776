import { usePipelineId } from "@/contexts/pipeline-context";
import { User } from "@/interfaces";
import { useModalForm } from "@refinedev/antd";
import { useGetIdentity, useInvalidate, useNavigation } from "@refinedev/core";

import { Form, Input, Modal } from "antd";

export const SalesCreateStage = () => {
  const invalidate = useInvalidate();
  const { data: userData } = useGetIdentity<User>();

  const { pipelineId } = usePipelineId();

  // Custom form submission handler
  const handleFormSubmit = async (values: {}) => {
    // Combine form values with the createdBy field
    const combinedValues = {
      ...values,
      createdBy: userData?.id,
      enterprise: userData?.userData?.enterprise,
      pipelineId,
    };

    formProps.onFinish?.(combinedValues);
  };
  const { list } = useNavigation();
  const { formProps, modalProps, close } = useModalForm({
    action: "create",
    defaultVisible: true,
    resource: "dealStages",
    meta: {
      fields: ["id"],
    },
    onMutationSuccess: () => {
      invalidate({ invalidates: ["list"], resource: "deals" });
    },
    successNotification: () => {
      return {
        key: "create-stage",
        type: "success",
        message: "Etapa criada com sucesso",
        description: "Sucesso",
      };
    },
  });

  return (
    <Modal
      {...modalProps}
      onCancel={() => {
        close();
        list("deals", "replace");
      }}
      title="Adicionar uma etapa"
      width={512}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={handleFormSubmit} // Use the custom form submission handler
      >
        <Form.Item label="Nome" name="title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
