import { Badge, Card } from "antd";
import { ReactNode } from "react";

interface IntegrationsCardProps {
  image: string;
  name: string;
  description: string;
  children?: ReactNode;
  isSoon?: boolean;
}

export function IntegrationsCard({
  description,
  image,
  name,
  isSoon,
  children,
}: IntegrationsCardProps) {
  return (
    <Card
      bodyStyle={{ padding: 0 }}
      size="small"
      className="max-w-[300px] min-h-[160px] p-2 border border-[#d8d8d8]"
    >
      {isSoon && (
        <Badge className="bg-blue-500 p-2 rounded-full text-white">
          Em breve...
        </Badge>
      )}
      <div
        style={{
          borderBottom: "1px solid #d8d8d8",
          padding: "8px 8px 8px 12px",
        }}
      >
        <img
          src={image}
          alt={name}
          className="max-w-[64px] max-h-[48px] rounded-md"
        />
      </div>

      <div style={{ padding: "8px 8px 8px 12px" }}>
        <h1 className="font-bold text-lg">{name}</h1>
        <p className="mt-1 font-md">{description}</p>
      </div>

      {children}
    </Card>
  );
}
