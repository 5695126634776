import { FC, PropsWithChildren, useEffect, useMemo } from "react";

import {
  HttpError,
  useDelete,
  useGetIdentity,
  useInvalidate,
  useList,
  useNavigation,
  useUpdate,
  useUpdateMany,
} from "@refinedev/core";

import { ClearOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { MenuProps } from "antd";

import { Task, TaskStage, TaskUpdateInput, User } from "@/interfaces";

import {
  KanbanAddCardButton,
  KanbanAddStageButton,
  KanbanBoard,
  KanbanBoardSkeleton,
  KanbanColumn,
  KanbanColumnSkeleton,
  KanbanItem,
  ProjectCardMemo,
  ProjectKanbanCardSkeleton,
} from "../components";

const taskFragment = [
  "id",
  "title",
  "description",
  "dueDate",
  "completed",
  "stageId",
  {
    checklist: ["title", "checked"],
  },
  {
    users: ["id", "name", "avatarUrl"],
  },
  {
    comments: ["totalCount"],
  },
];

export const KanbanPage: FC<PropsWithChildren> = ({ children }) => {
  const invalidate = useInvalidate();
  const { create, edit, replace } = useNavigation();
  const { data } = useGetIdentity<User>();

  const { data: stages, isLoading: isLoadingStages } = useList<TaskStage>({
    resource: "taskStages",
    pagination: {
      mode: "off",
    },
    sorters: [
      {
        field: "createdAt",
        order: "asc",
      },
    ],
    meta: {
      fields: ["id", "title"],
    },
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
  });

  const { data: defaultStages } = useList<TaskStage>({
    resource: "taskStages",
    pagination: {
      mode: "off",
    },
    sorters: [
      {
        field: "createdAt",
        order: "asc",
      },
    ],
    meta: {
      fields: ["id", "title"],
    },
    filters: [
      {
        field: "title",
        operator: "eq",
        value: "CONCLUÍDO",
      },
    ],
  });

  const { data: tasks, isLoading: isLoadingTasks } = useList<Task>({
    resource: "tasks",
    sorters: [
      {
        field: "dueDate",
        order: "desc",
      },
    ],
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    queryOptions: {
      enabled: !!stages,
    },
    pagination: {
      mode: "off",
    },
    meta: {
      fields: taskFragment,
    },
  });

  // its convert Task[] to TaskStage[] (group by stage) for kanban
  // uses `stages` and `tasks` from useList hooks
  const taskStages = useMemo(() => {
    if (!tasks?.data || !stages?.data)
      return {
        unassignedStage: [],
        stages: [],
      };

    const unassignedStage = tasks.data.filter((task) => task.stageId === null);
    const completedTasks = tasks.data.filter((task) => task.completed);

    // prepare unassigned stage
    const grouped: TaskStage[] = stages.data.map((stage) => ({
      ...stage,
      tasks: tasks.data
        .filter((task) => !task.completed)
        .filter((task) => task.stageId?.toString() === stage.id.toString()),
    }));

    return {
      unassignedStage,
      stages: grouped,
      completedTasks,
    };
  }, [tasks, stages]);

  const { mutate: updateTask } = useUpdate<Task, HttpError, TaskUpdateInput>();
  const { mutate: updateManyTask } = useUpdateMany();
  const { mutate: deleteStage } = useDelete();

  const handleOnDragEnd = (event: DragEndEvent) => {
    let stageId = event.over?.id as undefined | string | null;
    const taskId = event.active.id as string;
    const taskStageId = event.active.data.current?.stageId;

    let isCompleted = false;

    if (taskStageId === stageId) {
      return;
    }

    if (stageId === "unassigned") {
      stageId = null;
    }

    if (stageId === defaultStages?.data[0]?.id) {
      isCompleted = true;
    }

    updateTask({
      resource: "tasks",
      id: taskId,
      values: {
        stageId: stageId,
        completed: isCompleted,
      },
      successNotification: {
        description: "Sucesso!",
        type: "success",
        message: "Etapa atualizada com sucesso",
      },
      mutationMode: "optimistic",
    });
  };

  const handleAddStage = () => {
    create("taskStages", "replace");
  };

  const handleEditStage = (args: { stageId: string }) => {
    edit("taskStages", args.stageId);
  };

  const handleDeleteStage = (args: { stageId: string }) => {
    deleteStage({
      resource: "taskStages", //it was taskStage instead of stage before.
      id: args.stageId,
      successNotification: () => ({
        key: "delete-stage",
        type: "success",
        message: "Etapa deletada com sucesso",
        description: "Sucesso",
      }),
    });
  };

  const handleAddCard = (args: { stageId: string }) => {
    const path =
      args.stageId === "unassigned"
        ? "create"
        : `create?stageId=${args.stageId}`;

    replace(path);
  };

  const handleClearCards = (args: { taskIds: string[] }) => {
    updateManyTask({
      resource: "tasks",
      ids: args.taskIds,
      values: {
        stageId: null,
      },
      successNotification: false,
    });
  };

  const getContextMenuItems = ({ column }: { column: TaskStage }) => {
    const hasItems = column.tasks.length > 0;

    const items: MenuProps["items"] = [
      {
        label: "Editar etapa",
        key: "1",
        icon: (
          <EditOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        ),
        onClick: () => handleEditStage({ stageId: column.id }),
      },
      {
        label: "Limpar todos os cards",
        key: "2",
        icon: (
          <ClearOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        ),
        disabled: !hasItems,
        onClick: () =>
          handleClearCards({
            taskIds: column.tasks.map((task) => task.id),
          }),
      },
      {
        danger: true,
        label: "Deletar etapa",
        key: "3",
        icon: (
          <DeleteOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        ),
        disabled: hasItems,
        onClick: () => handleDeleteStage({ stageId: column.id }),
      },
    ];

    return items;
  };

  const isLoading = isLoadingTasks || isLoadingStages;

  if (isLoading) return <PageSkeleton />;

  return (
    <>
      <KanbanBoard onDragEnd={handleOnDragEnd}>
        <KanbanColumn
          id={"unassigned"}
          title={"backlog"}
          count={taskStages?.unassignedStage?.length || 0}
          onAddClick={() => handleAddCard({ stageId: "unassigned" })}
        >
          {taskStages.unassignedStage?.map((task) => {
            return (
              <KanbanItem
                key={task.id}
                id={task.id}
                data={{ ...task, stageId: "unassigned" }}
              >
                <ProjectCardMemo {...task} />
              </KanbanItem>
            );
          })}
          <KanbanAddCardButton
            onClick={() => handleAddCard({ stageId: "unassigned" })}
          />
        </KanbanColumn>
        {taskStages.stages?.map((column) => {
          const contextMenuItems = getContextMenuItems({ column });

          return (
            <KanbanColumn
              key={column.id}
              id={column.id}
              title={column.title}
              count={column.tasks.length}
              contextMenuItems={contextMenuItems}
              onAddClick={() => handleAddCard({ stageId: column.id })}
            >
              {isLoading && <ProjectKanbanCardSkeleton />}
              {!isLoading &&
                column.tasks.map((task) => {
                  return (
                    <KanbanItem
                      key={task.id}
                      id={task.id}
                      data={{
                        ...task,
                        stageId: column.id,
                      }}
                    >
                      <ProjectCardMemo {...task} />
                    </KanbanItem>
                  );
                })}
              <KanbanAddCardButton
                onClick={() => handleAddCard({ stageId: column.id })}
              />
            </KanbanColumn>
          );
        })}
        <KanbanColumn
          id={defaultStages?.data[0]?.id ?? ""}
          title={defaultStages?.data[0]?.title ?? "concluído"}
          count={taskStages?.completedTasks?.length || 0}
          onAddClick={() =>
            handleAddCard({ stageId: defaultStages?.data[0]?.id ?? "" })
          }
        >
          {taskStages.completedTasks?.map((task) => {
            return (
              <KanbanItem
                key={task.id}
                id={task.id}
                data={{ ...task, stageId: "completed" }}
              >
                <ProjectCardMemo {...task} />
              </KanbanItem>
            );
          })}
          <KanbanAddCardButton
            onClick={() => handleAddCard({ stageId: "completed" })}
          />
        </KanbanColumn>
        <KanbanAddStageButton onClick={handleAddStage} />
      </KanbanBoard>
      {children}
    </>
  );
};

const PageSkeleton = () => {
  const columnCount = 6;
  const itemCount = 4;

  return (
    <KanbanBoardSkeleton>
      {Array.from({ length: columnCount }).map((_, index) => {
        return (
          <KanbanColumnSkeleton key={index} type="project">
            {Array.from({ length: itemCount }).map((_, index) => {
              return <ProjectKanbanCardSkeleton key={index} />;
            })}
          </KanbanColumnSkeleton>
        );
      })}
    </KanbanBoardSkeleton>
  );
};
