import { Deal, DealStage, TaskStage, User } from "@/interfaces";
import {
  HttpError,
  useDelete,
  useGetIdentity,
  useList,
  useUpdate,
} from "@refinedev/core";
import { Text } from "@/components";
import { Button, Card, Switch, Tooltip, notification } from "antd";
import { DeleteOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { useSubscription } from "@/hooks/useSubscription";
import { useMemo, useState } from "react";
import { AutomationModal } from "../scrumboard/sales/components/AutomationModal";
import { SwitchChangeEventHandler } from "antd/es/switch";
import { Link } from "react-router-dom";
import Icon from "@ant-design/icons/lib/components/Icon";
import { Code, SquareCode, SquareStack, Workflow } from "lucide-react";
import { Popconfirm } from "antd/lib";

interface IAutomations {
  id: number;
  trigger: string;
  phase: number[];
  actionType: string;
  actionDetails: number[];
  automationInfo: {
    title: string;
    active: boolean;
    objective: string;
  };
  active: boolean;
  createdBy: string;
  createdAt: Date;
}

const dealsFragment = [
  "id",
  "title",
  "value",
  "createdAt",
  "stageId",
  "enterprise",
  {
    company: ["id", "name", "avatarUrl"],
  },
  {
    dealOwner: ["id", "name", "avatarUrl"],
  },
];

const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));

export function Automations() {
  const { data } = useGetIdentity<User>();
  const { mutate } = useDelete();
  const { isProfessionalOrElite } = useSubscription();
  const { mutate: updateAutomation } = useUpdate<
    IAutomations,
    HttpError,
    any
  >();
  const [isOpenAddNewAutomationModal, setIsOpenAddNewAutomationModal] =
    useState(false);

  const { data: stages } = useList<DealStage>({
    resource: "dealStages",
    pagination: {
      mode: "off",
    },
    sorters: [
      {
        field: "createdAt",
        order: "asc",
      },
    ],
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    meta: {
      fields: [
        "id",
        "title",
        "enterprise",
        {
          dealsAggregate: [
            {
              sum: ["value"],
            },
          ],
        },
      ],
    },
  });

  const { data: tasksStages } = useList<TaskStage>({
    resource: "taskStages",
    pagination: {
      mode: "off",
    },
    sorters: [
      {
        field: "createdAt",
        order: "asc",
      },
    ],
    meta: {
      fields: ["id", "title"],
    },
  });

  const { data: users } = useList<User>({
    resource: "users",
    pagination: {
      mode: "off",
    },
    sorters: [
      {
        field: "role",
        order: "asc",
      },
    ],
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    meta: {
      fields: ["id", "title", "name", "enterprise"],
    },
  });

  const { data: automations } = useList<IAutomations>({
    resource: "automations",
    queryOptions: {
      refetchInterval: 1000 * 60 * 5, // 5 minutes,
    },
    sorters: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filters: [
      {
        field: "createdBy",
        operator: "eq",
        value: data?.userData?.uuid,
      },
    ],

    pagination: {
      mode: "off",
    },
    meta: {
      fields: ["*"],
    },
  });

  const { data: wonLost, isLoading: isLoadingWonLost } = useList<DealStage>({
    resource: "dealStages",
    pagination: {
      mode: "off",
    },
    filters: [
      {
        field: "id",
        operator: "in",
        value: [1, 2],
      },
    ],
    meta: {
      fields: [
        "id",
        "title",
        "enterprise",
        {
          dealsAggregate: [
            {
              sum: ["value"],
            },
          ],
        },
      ],
    },
  });

  const mergedStages = useMemo(() => {
    // Check if both datasets are available
    if (!stages?.data || !wonLost?.data) return [];

    // Create a Set to hold unique stage IDs from 'wonLost' to avoid duplicates
    const wonLostIds = new Set(wonLost.data.map((stage) => stage.id));

    // Filter out stages from 'stages' that are already included in 'wonLost'
    const uniqueStages = stages.data.filter(
      (stage) => !wonLostIds.has(stage.id)
    );

    // Return the merged array
    return [...wonLost.data, ...uniqueStages];
  }, [stages, wonLost]);

  const { data: deals } = useList<Deal>({
    resource: "deals",
    sorters: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filters: [
      {
        field: "createdAt",
        operator: "gte",
        value: lastMonth.toISOString(),
      },
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    queryOptions: {
      enabled: !!stages,
    },
    pagination: {
      mode: "off",
    },
    meta: {
      fields: dealsFragment,
    },
  });

  const stageGrouped = useMemo(() => {
    if (!stages?.data)
      return {
        stageAll: [],
      };
    const dealsData = deals?.data;

    const grouped = mergedStages.map((stage) => {
      return {
        ...stage,
        deals: dealsData
          ?.filter((deal) => deal.stageId === stage.id)
          ?.sort((a, b) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          }),
      };
    });

    const stageAll = grouped?.filter(
      (stage) => stage.title !== "WON" && stage.title !== "LOST"
    );

    return {
      stageAll: stageAll as any,
    };
  }, [stages]);

  async function deleteAutomation(id: number) {
    mutate({
      resource: "automations",
      id,
      successNotification: {
        description: "Sucesso!",
        type: "success",
        message: "Automação excluída com sucesso",
      },
    });
  }

  async function handleChangeAutomationStatus(status: boolean, id: number) {
    updateAutomation({
      resource: "automations",
      id,
      values: { active: status },
      successNotification: {
        description: "Sucesso!",
        type: "success",
        message: "Status atualizado com sucesso",
      },
    });

    // implementar aqui a validação
    // ver se ele está ativando uma automação
    // caso sim
    // habilitar o trigger dela no banco
    // caso não
    // desabilitar o trigger dessa automação no banco
  }

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {!automations?.data?.length && (
        <div className="w-full space-y-5 flex flex-col justify-center items-center h-full text-center">
          <Text type="secondary" className="text-xl">
            Nenhuma automação encontrada,{" "}
            <Button
              onClick={() => setIsOpenAddNewAutomationModal(true)}
              type="link"
              style={{ padding: 0 }}
              className="text-xl"
            >
              clique aqui
            </Button>{" "}
            para criar uma nova
          </Text>{" "}
          <div className="flex space-x-5">
            <div className="animate-pulse">
              <SquareStack size={48} color="#545454" />
            </div>
            <div className="animate-pulse">
              <Workflow size={48} color="#545454" />
            </div>
            <div className="animate-pulse">
              <SquareCode size={48} color="#545454" />
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30% 30% 30%",
          gap: "24px",
        }}
      >
        {automations?.data?.map((automation) => (
          <Card
            size="small"
            bordered
            actions={[
              <div
                key="1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Text size="xs" type="secondary">
                    Status: {automation?.active ? "Ativado" : "Desativado"}
                  </Text>
                  <Switch
                    defaultChecked={automation?.active}
                    onChange={(status) =>
                      handleChangeAutomationStatus(status, automation?.id)
                    }
                  />
                </div>
              </div>,
            ]}
          >
            <Card.Meta
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 400,
                      }}
                      type="secondary"
                      ellipsis={{ tooltip: automation?.automationInfo?.title }}
                    >
                      Titulo da automação: {automation?.automationInfo?.title}
                    </Text>
                    <Popconfirm
                      title="Deseja excluir esta automação?"
                      onConfirm={() => deleteAutomation(automation.id)}
                      okText="Sim"
                      cancelText="Não"
                      placement="topRight"
                    >
                      <DeleteOutlined
                        style={{ color: "red", cursor: "pointer" }}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined} // onClick={() => deleteAutomation(automation.id)}
                      />
                    </Popconfirm>
                  </div>
                </div>
              }
              description={
                <Text strong size="md">
                  Objetivo: {automation.automationInfo.objective}
                </Text>
              }
            />
          </Card>
        ))}
      </div>
      <div className="absolute right-0 bottom-0">
        <button
          className="flex items-center gap-2 px-3 py-2 rounded-xl ant-btn-primary text-white hover:opacity-80 disabled:!bg-zinc-500/70 disabled:!border-none disabled:"
          onClick={() => setIsOpenAddNewAutomationModal(true)}
          disabled={!isProfessionalOrElite}
        >
          <div>
            {!isProfessionalOrElite ? (
              <Tooltip title="Disponível apenas nos planos Profissional e Elite">
                <div className="flex items-center space-x-1">
                  <PlusOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                  <span>Automação</span>
                </div>
              </Tooltip>
            ) : (
              <button
                className="flex items-center gap-2  rounded-xl ant-btn-primary text-white hover:opacity-80"
                onClick={() => setIsOpenAddNewAutomationModal(true)}
              >
                <PlusOutlined
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
                Automação
              </button>
            )}
          </div>
        </button>
      </div>
      {isOpenAddNewAutomationModal && isProfessionalOrElite && (
        <AutomationModal
          isOpen={isOpenAddNewAutomationModal}
          onClose={() => setIsOpenAddNewAutomationModal(false)}
          allStages={stageGrouped?.stageAll ?? []}
          taskStages={tasksStages?.data ?? []}
          users={users?.data ?? []}
        />
      )}
    </div>
  );
}
