import {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as XLSX from "xlsx";
import {
  useDelete,
  useGetIdentity,
  useList,
  useNavigation,
  useUpdate,
  useUpdateMany,
} from "@refinedev/core";

import {
  ArrowDownOutlined,
  ClearOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import {
  MenuProps,
  Modal,
  Select,
  Input,
  Switch,
  Tooltip,
  Button,
  Card,
  Spin,
  notification,
} from "antd";

import { Text } from "@/components";
import { Deal, DealStage, TaskStage, User } from "@/interfaces";
import { currencyNumber } from "@/utilities";

import {
  DealKanbanCardMemo,
  DealKanbanCardSkeleton,
  DealKanbanWonLostDrop,
  KanbanAddCardButton,
  KanbanAddStageButton,
  KanbanBoard,
  KanbanBoardSkeleton,
  KanbanColumn,
  KanbanColumnSkeleton,
  KanbanItem,
} from "../components";
import { formatForDisplay } from "./lib/format-display";
import { useSelect } from "@refinedev/antd";
import { AddPipelineForm } from "./components/AddPipelineForm";
import { pipeline } from "stream";
import { supabase } from "@/providers/supabase";
import { now } from "lodash";
import { usePipelineId } from "@/contexts/pipeline-context";

const dealsFragment = [
  "id",
  "title",
  "value",
  "createdAt",
  "stageId",
  "stage",
  "pipelineId",
  "enterprise",
  {
    company: ["id", "name", "avatarUrl"],
  },
  {
    dealOwner: ["id", "name", "avatarUrl", "email", "phone"],
  },
];

const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));

const calculateTotalRevenue = (deals: Deal[]) => {
  return deals?.reduce((total, deal) => total + (deal.value || 0), 0);
};

export const SalesPage: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useGetIdentity<User>();
  const { userData } = data || {};

  const { replace, edit, create } = useNavigation();

  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const downloadModelSheet = () => {
    window.location.href = "/planilha_modelo.xlsx";
  };

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const processAndUploadData = async (data: Array<{ [key: string]: any }>) => {
    // Map the Excel data to your 'deals' table structure.
    const mappedData: Omit<Deal, "id">[] = data.map((item) => ({
      title: item["Title"], // Directly mapping the 'Title' from Excel to 'title' in the database
      value: item["Value"], // Directly mapping the 'Value'
      notes: item["Notes"], // Directly mapping the 'Notes'
      closeDateYear: item["CloseDateYear"], // Directly mapping the 'CloseDateYear'
      closeDateMonth: item["CloseDateMonth"], // Directly mapping the 'CloseDateMonth'
      closeDateDay: item["CloseDateDay"], // Directly mapping the 'CloseDateDay'
      stageId: item["StageId"] || 0, // Using 0 as a default value if not specified
      companyId: item["CompanyId"] || 0, // Using 0 as a default value if not specified
      // Assuming 'DealOwner' and 'DealContact' are IDs, using a default value if not specified
      dealOwner: item["DealOwner"] || 0,
      dealContact: item["DealContact"] || 0,
      // 'CreatedBy', 'DealContactId', 'DealOwnerId', 'UpdatedBy', and 'Enterprise' might need special handling based on your application logic
      createdBy: userData?.uuid as any,
      dealContactId: item["DealContactId"] || 0,
      dealOwnerId: userData?.id as any,
      updatedBy: userData?.uuid as any,
      enterprise: userData?.enterprise,
      company: item["CompanyId"] || 0,
      companyName: item["CompanyName"], // Directly mapping the 'CompanyName'
      companyAvatarUrl: item["CompanyAvatarUrl"], // Directly mapping the 'CompanyAvatarUrl'
      dealOwnerName: item["DealOwnerName"], // Directly mapping the 'DealOwnerName'
      dealOwnerAvatarUrl: item["DealOwnerAvatarUrl"], // Directly mapping the 'DealOwnerAvatarUrl'
      pipelineId: item["PipelineId"] || 0, // Using 0 as a default value if not specified
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }));

    // Attempt to upload data to Supabase
    try {
      const { data: uploadData, error } = await supabase
        .from("deals")
        .insert(mappedData);

      if (error) {
        notification.error({
          message: "Erro ao carregar dados",
          description: error.message,
        });
        throw error;
      }
      notification.success({
        message: "Dados carregados com sucesso!",
        description: "Os dados foram carregados com sucesso",
      });
    } catch (error) {
      console.error("Error uploading data to Supabase:", error);
    }
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files[0]) {
      setFile(files[0]);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        await processAndUploadData(json as any);
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      };
      reader.readAsBinaryString(files[0]);
    }
  };

  const { selectProps, queryResult: pipelinesQueryResult } = useSelect({
    resource: "pipelines",
    optionLabel: "title",
    optionValue: "id",
    defaultValue: 0,
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    meta: {
      fields: ["id", "title", "enterprise"],
    },
    onSearch: (value) =>
      value ? [{ field: "title", operator: "contains", value }] : [],
  });

  const { setPipelineId } = usePipelineId();

  const [selectedPipeline, setSelectedPipeline] = useState<number | undefined>(
    pipelinesQueryResult?.data?.data?.[0]?.id ?? 0
  );

  useEffect(() => {
    if (
      pipelinesQueryResult?.data &&
      pipelinesQueryResult?.data?.data?.length > 0 &&
      selectedPipeline === undefined
    ) {
      setSelectedPipeline(pipelinesQueryResult?.data?.data?.[0]?.id);
    }
  }, [pipelinesQueryResult?.data?.data]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const { data: stages, isLoading: isLoadingStages } = useList<DealStage>({
    resource: "dealStages",
    pagination: {
      mode: "off",
    },
    sorters: [
      {
        field: "createdAt",
        order: "asc",
      },
    ],
    filters: [
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
      {
        field: "pipelineId",
        operator: "eq",
        value: selectedPipeline,
      },
    ],
    meta: {
      fields: [
        "id",
        "title",
        "enterprise",
        {
          dealsAggregate: [
            {
              sum: ["value"],
            },
          ],
        },
      ],
    },
  });

  const { data: wonLost, isLoading: isLoadingWonLost } = useList<DealStage>({
    resource: "dealStages",
    pagination: {
      mode: "off",
    },
    filters: [
      {
        field: "id",
        operator: "in",
        value: [1, 2],
      },
    ],
    meta: {
      fields: [
        "id",
        "title",
        "enterprise",
        {
          dealsAggregate: [
            {
              sum: ["value"],
            },
          ],
        },
      ],
    },
  });

  const mergedStages = useMemo(() => {
    // Check if both datasets are available
    if (!stages?.data || !wonLost?.data) return [];

    // Create a Set to hold unique stage IDs from 'wonLost' to avoid duplicates
    const wonLostIds = new Set(wonLost.data.map((stage) => stage.id));

    // Filter out stages from 'stages' that are already included in 'wonLost'
    const uniqueStages = stages.data.filter(
      (stage) => !wonLostIds.has(stage.id)
    );

    // Return the merged array
    return [...wonLost.data, ...uniqueStages];
  }, [stages, wonLost]);

  const { data: deals, isLoading: isLoadingDeals } = useList<Deal>({
    resource: "deals",
    sorters: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filters: [
      {
        field: "pipelineId",
        operator: "eq",
        value: selectedPipeline,
      },
      {
        field: "createdAt",
        operator: "gte",
        value: lastMonth.toISOString(),
      },
      {
        field: "enterprise",
        operator: "eq",
        value: data?.userData?.enterprise,
      },
    ],
    queryOptions: {
      enabled: !!stages,
    },
    pagination: {
      mode: "off",
    },
    meta: {
      fields: dealsFragment,
    },
  });

  const handlePipelineChange = (pipelineId: number) => {
    localStorage.setItem("selectedPipeline", pipelineId.toString());
    setSelectedPipeline(pipelineId);
    setPipelineId(pipelineId);
  };

  useEffect(() => {
    const storedPipelineId = localStorage.getItem("selectedPipeline");
    const pipelineId = storedPipelineId
      ? parseInt(storedPipelineId, 10)
      : pipelinesQueryResult?.data?.data?.[0]?.id ?? 0;

    if (pipelineId !== selectedPipeline) {
      setSelectedPipeline(pipelineId);
    }
  }, [pipelinesQueryResult?.data?.data, selectedPipeline]);

  // its convert Deal[] to DealStage[] (group by stage) for kanban
  // its also group `won` and `lost` stages
  // uses `stages` and `tasks` from useList hooks

  const stageGrouped = useMemo(() => {
    if (!stages?.data || !deals?.data)
      return {
        stageUnassigned: null,
        stageAll: [],
        stageWon: null,
        stageLost: null,
      };
    const dealsData = deals?.data;

    const stageUnassigned = dealsData.filter((deal) => deal.stageId === null);

    const grouped = mergedStages.map((stage) => {
      return {
        ...stage,
        deals: dealsData
          .filter((deal) => deal.stageId === stage.id)
          .sort((a, b) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          }),
      };
    });

    const totalRevenueByStage = mergedStages.map((stage) => ({
      ...stage,
      totalRevenue: calculateTotalRevenue(stage.deals),
    }));

    const stageWon = grouped.find((stage) => stage.title === "WON");
    const stageLost = grouped.find((stage) => stage.title === "LOST");
    // remove won and lost from grouped
    const stageAll = grouped.filter(
      (stage) => stage.title !== "WON" && stage.title !== "LOST"
    );

    return {
      stageUnassigned,
      stageAll,
      stageWon,
      stageLost,
      totalRevenueByStage,
    };
  }, [stages, deals]);

  const { mutate: updateDeal } = useUpdate();
  const { mutate: updateManyDeal } = useUpdateMany();
  const { mutate: deleteStage } = useDelete();

  const { unassignedStageTotalValue } = useMemo(() => {
    let unassignedStageTotalValue = 0;

    stageGrouped?.stageUnassigned?.forEach((deal) => {
      unassignedStageTotalValue += deal.value || 0;
    });

    return {
      unassignedStageTotalValue,
    };
  }, [stageGrouped.stageUnassigned]);

  const handleOnDragEnd = (event: DragEndEvent) => {
    let stageId = event.over?.id as undefined | string | null;
    const dealId = event.active.id;
    const dealStageId = event.active.data.current?.stageId;

    if (dealStageId === stageId) {
      return;
    }

    if (stageId === "won") {
      stageId = stageGrouped.stageWon?.id;
    }

    if (stageId === "lost") {
      stageId = stageGrouped?.stageLost?.id;
    }

    if (stageId === "unassigned") {
      stageId = null;
    }

    updateDeal(
      {
        resource: "deals",
        id: dealId,
        values: {
          stageId: stageId,
        },
        successNotification: false,
        mutationMode: "optimistic",
      },
      {
        onSuccess: () => {
          const stage = event.over?.id as undefined | string | null;
          if (stage === "won" || stage === "lost") {
            edit("deals", dealId, "replace");
          }
        },
      }
    );
  };

  const handleAddStage = () => {
    create("dealStages", "replace");
  };

  const handleEditStage = (args: { stageId: string }) => {
    edit("dealStages", args.stageId);
  };

  const handleDeleteStage = (args: { stageId: string }) => {
    deleteStage({
      resource: "dealStages",
      id: args.stageId,
      successNotification: () => ({
        key: "delete-stage",
        type: "success",
        message: "Etapa deletada com sucesso",
        description: "Sucesso",
      }),
    });
  };

  const handleAddCard = (args: { stageId: string }) => {
    const path =
      args.stageId === "unassigned"
        ? "create"
        : `create?stageId=${args.stageId}`;
    replace(path);
  };

  const handleClearCards = (args: { dealsIds: string[] }) => {
    updateManyDeal({
      resource: "deals",
      ids: args.dealsIds,
      values: {
        stageId: null,
      },
      successNotification: false,
    });
  };

  const getContextMenuItems = ({ column }: { column: DealStage }) => {
    const hasItems = column.deals.length > 0;

    const items: MenuProps["items"] = [
      {
        label: "Editar etapa",
        key: "1",
        icon: (
          <EditOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        ),
        onClick: () => handleEditStage({ stageId: column.id }),
      },
      {
        label: "Limpar todos os cards",
        key: "2",
        icon: (
          <ClearOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        ),
        disabled: !hasItems,
        onClick: () =>
          handleClearCards({
            dealsIds: column.deals.map((deal) => deal.id),
          }),
      },
      {
        danger: true,
        label: "Deletar etapa",
        key: "3",
        icon: (
          <DeleteOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        ),
        disabled: hasItems,
        onClick: () => handleDeleteStage({ stageId: column.id }),
      },
    ];

    return items;
  };

  const loading = isLoadingStages || isLoadingDeals || isLoadingWonLost;

  if (loading) {
    return <PageSkeleton />;
  }

  const uploadDataToSupabase = async (data: Array<{ [key: string]: any }>) => {
    // Assuming your table's name is 'your_table_name'
    // Adjust the logic below based on how you want to handle the upload and your table schema
    const { data: uploadData, error } = await supabase
      .from("your_table_name")
      .insert(data);

    if (error) {
      console.error("Error uploading data to Supabase", error);
      return;
    }

    console.log("Upload successful", uploadData);
  };

  return (
    <>
      {openModal && <AddPipelineForm />}
      <div className="flex flex-row items-center ">
        <Select
          {...(selectProps as any)}
          className="w-1/6 font-bold"
          showSearch
          value={selectedPipeline}
          onChange={handlePipelineChange}
          loading={pipelinesQueryResult.isLoading}
          placeholder="Selecione uma pipeline"
          notFoundContent={
            pipelinesQueryResult.isLoading ? <Spin size="small" /> : "Not Found"
          }
          onDropdownVisibleChange={(open) => {
            if (!open) {
              // Reset the search
              selectProps?.onSearch?.("");
            }
          }}
        />
        <div className="flex flex-row !container justify-end p-5">
          <div>
            <Button
              className="flex flex-row space-x-2 items-center cursor-pointer"
              onClick={handleOpenModal}
            >
              <PlusOutlined
                className="cursor-pointer"
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <label className="cursor-pointer">Adicionar Pipeline</label>
            </Button>
          </div>
          <Button
            className="ml-5 flex items-center"
            onClick={handleFileInputClick}
          >
            <CloudUploadOutlined
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
            <span>Importar via Planilha</span>
          </Button>
          <Button
            className="ml-1 flex items-center"
            onClick={downloadModelSheet}
          >
            <CloudDownloadOutlined
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
            <span>Baixar planilha modelo</span>
          </Button>
          <input
            type="file"
            accept=".xlsx"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </div>
      </div>

      <KanbanBoard onDragEnd={handleOnDragEnd}>
        <KanbanColumn
          id={"unassigned"}
          title={"backlog"}
          count={stageGrouped.stageUnassigned?.length || 0}
          description={
            <Text size="md" disabled={unassignedStageTotalValue === 0}>
              {formatForDisplay(unassignedStageTotalValue)}
            </Text>
          }
          onAddClick={() => handleAddCard({ stageId: "unassigned" })}
        >
          {stageGrouped.stageUnassigned?.map((deal) => {
            return (
              <KanbanItem
                key={deal.id}
                id={deal.id}
                data={{ ...deal, stageId: "unassigned" }}
              >
                <DealKanbanCardMemo
                  id={deal.id}
                  key={deal.id}
                  title={deal.title}
                  company={{
                    name: deal.companyName,
                    avatarUrl: deal.companyAvatarUrl as string,
                  }}
                  user={{ name: deal.dealOwnerName }}
                  date={deal.createdAt}
                  price={formatForDisplay(deal.value || 0)}
                />
              </KanbanItem>
            );
          })}
          {!stageGrouped.stageUnassigned?.length && (
            <KanbanAddCardButton
              onClick={() => handleAddCard({ stageId: "unassigned" })}
            />
          )}
        </KanbanColumn>
        {stageGrouped.stageAll.map((column) => {
          const totalRevenue = column.deals.reduce(
            (total, deal) => total + (deal.value || 0),
            0
          );
          const formattedTotalRevenue = formatForDisplay(totalRevenue);

          const contextMenuItems = getContextMenuItems({ column });

          return (
            <KanbanColumn
              key={column.id}
              id={column.id}
              title={column.title}
              description={
                <Text size="md" disabled={totalRevenue === 0}>
                  {formattedTotalRevenue}
                </Text>
              }
              count={column.deals.length}
              contextMenuItems={contextMenuItems}
              onAddClick={() => handleAddCard({ stageId: column.id })}
            >
              {column.deals.map((deal) => {
                return (
                  <KanbanItem
                    key={deal.id}
                    id={deal.id}
                    data={{ ...deal, stageId: column.id }}
                  >
                    <DealKanbanCardMemo
                      id={deal.id}
                      key={deal.id}
                      title={deal.title}
                      company={{
                        name: deal.companyName,
                        avatarUrl: deal.companyAvatarUrl as string,
                      }}
                      user={{
                        name: deal.dealOwnerName,
                        avatarUrl: deal.dealOwner.avatarUrl,
                      }}
                      date={deal.createdAt}
                      price={formatForDisplay(deal.value || 0)}
                    />
                  </KanbanItem>
                );
              })}
              {!column.deals.length && (
                <KanbanAddCardButton
                  onClick={() => handleAddCard({ stageId: column.id })}
                />
              )}
            </KanbanColumn>
          );
        })}
        <KanbanAddStageButton onClick={handleAddStage} />
        {stageGrouped.stageWon && (
          <KanbanColumn
            key={stageGrouped.stageWon.id}
            id={stageGrouped.stageWon.id}
            shouldShowHeaderAddButton={false}
            title={"GANHO"}
            description={
              <Text
                size="md"
                disabled={
                  stageGrouped.stageWon.deals.reduce(
                    (total, deal) => total + (deal.value || 0),
                    0
                  ) === 0
                }
              >
                {formatForDisplay(
                  stageGrouped.stageWon.deals.reduce(
                    (total, deal) => total + (deal.value || 0),
                    0
                  )
                )}
              </Text>
            }
            count={stageGrouped.stageWon.deals.length}
            variant="solid"
          >
            {stageGrouped.stageWon.deals.map((deal) => {
              return (
                <KanbanItem
                  key={deal.id}
                  id={deal.id}
                  data={{
                    ...deal,
                    stageId: stageGrouped.stageWon?.id,
                  }}
                >
                  <DealKanbanCardMemo
                    id={deal.id}
                    key={deal.id}
                    title={deal.title}
                    company={{
                      name: deal.companyName,
                      avatarUrl: deal.companyAvatarUrl as string,
                    }}
                    user={{
                      name: deal.dealOwnerName,
                      avatarUrl: deal.dealOwnerName,
                    }}
                    date={deal.createdAt}
                    price={formatForDisplay(deal.value || 0)}
                    variant="won"
                  />
                </KanbanItem>
              );
            })}
          </KanbanColumn>
        )}
        {stageGrouped.stageLost && (
          <KanbanColumn
            key={stageGrouped.stageLost.id}
            id={stageGrouped.stageLost.id}
            shouldShowHeaderAddButton={false}
            title={"PERDIDO"}
            description={
              <Text
                size="md"
                disabled={
                  stageGrouped.stageLost.deals.reduce(
                    (total, deal) => total + (deal.value || 0),
                    0
                  ) === 0
                }
              >
                {formatForDisplay(
                  stageGrouped.stageLost.deals.reduce(
                    (total, deal) => total + (deal.value || 0),
                    0
                  )
                )}
              </Text>
            }
            count={stageGrouped.stageLost.deals.length}
            variant="solid"
          >
            {stageGrouped.stageLost.deals.map((deal) => {
              return (
                <KanbanItem
                  key={deal.id}
                  id={deal.id}
                  data={{
                    ...deal,
                    stageId: stageGrouped.stageLost?.id,
                  }}
                >
                  <DealKanbanCardMemo
                    id={deal.id}
                    key={deal.id}
                    title={deal.title}
                    company={{
                      name: deal.companyName,
                      avatarUrl: deal.companyAvatarUrl as string,
                    }}
                    user={{
                      name: deal.dealOwnerName,
                      avatarUrl: deal.dealOwnerName,
                    }}
                    date={deal.createdAt}
                    price={formatForDisplay(deal.value || 0)}
                    variant="lost"
                  />
                </KanbanItem>
              );
            })}
          </KanbanColumn>
        )}
        <DealKanbanWonLostDrop />
      </KanbanBoard>
      {children}
    </>
  );
};

const PageSkeleton = () => {
  const columnCount = 5;
  const itemCount = 4;

  return (
    <KanbanBoardSkeleton>
      {Array.from({ length: columnCount }).map((_, index) => {
        return (
          <KanbanColumnSkeleton key={index} type="deal">
            {Array.from({ length: itemCount }).map((_, index) => {
              return <DealKanbanCardSkeleton key={index} />;
            })}
          </KanbanColumnSkeleton>
        );
      })}
      <KanbanAddStageButton disabled />
      <KanbanColumnSkeleton type="deal" variant="solid">
        {Array.from({ length: itemCount }).map((_, index) => {
          return <DealKanbanCardSkeleton key={index} />;
        })}
      </KanbanColumnSkeleton>
      <KanbanColumnSkeleton type="deal" variant="solid">
        {Array.from({ length: itemCount }).map((_, index) => {
          return <DealKanbanCardSkeleton key={index} />;
        })}
      </KanbanColumnSkeleton>
    </KanbanBoardSkeleton>
  );
};
