import { Space, Typography } from "antd";

import { UserTag } from "@/components";
import { Task } from "@/interfaces";

type Props = {
  users?: {
    data?: Task["users"] | [];
  };
};

export const UsersHeader = ({ users }: Props) => {
  if (users?.data && users?.data?.length > 0) {
    return (
      <Space size={[0, 8]} wrap>
        {users.data &&
          users.data.map((user) => {
            return <UserTag key={user.id} user={user} />;
          })}
      </Space>
    );
  }

  return <Typography.Link>Atribuir aos usuários</Typography.Link>;
};
